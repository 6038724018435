import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as XLSX from "xlsx";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {
  useToaster,
  Notification,
  TagPicker,
  DateRangePicker,
  MaskedInput,
  Message,
  Stack,
  Dropdown,
} from "rsuite";
import { DatePicker, Table as TableNew } from "rsuite";
import { SelectPicker } from "rsuite";
import moment from "moment";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Radio, RadioGroup, Form, Button } from "rsuite";
import PageIcon from "@rsuite/icons/Page";
import DetailIcon from "@rsuite/icons/Detail";
//import { Table } from "rsuite";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { toAmnt } from "functions";
import { useMaterialUIController } from "context";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const { Column, HeaderCell, Cell } = TableNew;

const CompactCell = (props) => <Cell {...props} style={{ padding: 4 }} />;
const CompactHeaderCell = (props) => <HeaderCell {...props} style={{ padding: 4 }} />;

const defaultColumns = [
  {
    key: "SrNo",
    label: "Id",

    width: 130,
  },
  {
    key: "FullName",
    label: "Full Name",
    flexGrow: 1,
  },

  {
    key: "date",
    label: "JoiningDate",
  },
  {
    key: "Address",
    label: "Address",
    flexGrow: 1,
  },
  {
    key: "caste",
    label: "Caste",
    flexGrow: 1,
  },
  {
    key: "village",
    label: "Village",
    flexGrow: 1,
  },
  {
    key: "lic",
    label: "lic",
    flexGrow: 1,
  },
  {
    key: "licstatus",
    label: "licstatus",
    flexGrow: 1,
  },
  {
    key: "work",
    label: "work",
    flexGrow: 1,
  },
  {
    key: "dob",
    label: "dob",
    flexGrow: 1,
  },
  {
    key: "anniversary",
    label: "anniversary",
    flexGrow: 1,
  },
];
const CustomerpolicyColumns = [
  {
    key: "PolicyNo",
    label: "Policy No",
    width: 130,
  },
  {
    key: "PolicyType",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "Company",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "Model",
    label: "Model",
    width: 100,
  },

  {
    key: "RegNo",
    label: "RegNo",
    width: 120,
  },
  {
    key: "StartDate",
    label: "StartDate",
    flexGrow: 1,
  },
  {
    key: "PolicyDate",
    label: "Policydate",
    flexGrow: 1,
  },
  {
    key: "EndDate",
    label: "EndDate",
    flexGrow: 1,
  },
  {
    key: "GrossPremium",
    label: "GrossPremium",
    flexGrow: 1,
  },
];
const predefinedBottomRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [startOfWeek(addDays(new Date(), 6)), endOfWeek(addDays(new Date(), 6))],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [startOfMonth(addMonths(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))],
  },
];
/* const PolicyColumns = [
  {
    key: "policyno",
    label: "Policyno",
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 200,
  },
  {
    key: "insurancecompany",
    label: "Company",
    width: 200,
  },

  {
    key: "Customer",
    label: "Customer",
    width: 200,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 200,
  },

  {
    key: "vehicleregno",
    label: "RegNo",
    width: 120,
  },
  {
    key: "startDate",
    label: "StartDate",
    width: 120,
  },
  {
    key: "policyDate",
    label: "PolicyDate",
    width: 120,
  },
  {
    key: "policyenddate",
    label: "EndDate",
    width: 120,
  },
  {
    key: "policycategory",
    label: "policycategory",
    width: 120,
  },

  {
    key: "tppremium",
    label: "TPPremium",
    width: 120,
  },
  {
    key: "odpremium",
    label: "ODPremium",
    width: 120,
  },
  {
    key: "netpremium",
    label: "NetPremium",
    width: 120,
  },
  {
    key: "grosspremium",
    label: "GrossPremium",
    width: 120,
  },
  {
    label: "Comm TP %",
    key: "commpertp",
    width: 120,
  },
  {
    label: "Comm OD %",
    key: "commperod",
    width: 120,
  },
  {
    label: "Comm Net %",
    key: "commpernet",
    width: 120,
  },
  {
    label: "Comm TP",
    key: "commtp",
    width: 120,
  },
  {
    label: "Comm OD",
    key: "commod",
    width: 120,
  },
  {
    label: "Comm Net",
    key: "commnetamount",
    width: 120,
  },
  {
    label: "TotalComm",
    key: "totalcomm",
    width: 120,
  },
  {
    key: "DR",
    label: "DR",
    width: 120,
  },
  {
    key: "CR1",
    label: "CR1",
    width: 120,
  },
  {
    key: "CR2",
    label: "CR2",
    width: 120,
  },
  {
    key: "Balance",
    label: "Balance",
    width: 120,
  },
]; */

const PolicyColumns = [
  {
    key: "policyno",
    label: "Policyno",
    width: 130,
  },
  {
    key: "vehicleregno",
    label: "RegNo",
    width: 120,
  },
  {
    key: "Customer",
    label: "Customer",
    width: 200,
  },
  {
    key: "policytype",
    label: "Type",
    width: 150,
  },
  {
    key: "insurancecompany",
    label: "Company",
    width: 200,
  },

  {
    key: "vehiclemodel",
    label: "Model",
    width: 200,
  },

  {
    key: "startDate",
    label: "StartDate",
    width: 120,
  },
  {
    key: "policyDate",
    label: "PolicyDate",
    width: 120,
  },
  {
    key: "policyenddate",
    label: "EndDate",
    width: 120,
  },
  {
    key: "policycategory",
    label: "policycategory",
    width: 120,
  },

  {
    key: "grosspremium",
    label: "GrossPremium",
    width: 120,
  },
];
const PolicyColumnsSelf = [
  {
    key: "policyno",
    label: "policyno",
    width: 130,
  },
  {
    key: "CustomerName",
    label: "CustomerName",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "insurancecompany",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 100,
  },

  {
    key: "vehicleregno",
    label: "RegNo",
    width: 120,
  },
  {
    key: "StartDate",
    label: "Start Date",
    flexGrow: 1,
  },
  {
    key: "policyDate",
    label: "Policy Date",
    flexGrow: 1,
  },
  {
    key: "policyenddate",
    label: "End Date",
    flexGrow: 1,
  },

  {
    key: "GrossPremium",
    label: "GrossPremium",
    flexGrow: 1,
  },
];

const PolicyColumnsDSO = [
  {
    key: "policyno",
    label: "policyno",
    width: 130,
  },
  {
    key: "DahiyaMitra",
    label: "DahiyaMitra",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "insurancecompany",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 100,
  },

  {
    key: "vehicleregno",
    label: "RegNo",
    width: 120,
  },

  {
    key: "policyDate",
    label: "Policy Date",
    flexGrow: 1,
  },
  {
    key: "policyenddate",
    label: "End Date",
    flexGrow: 1,
  },

  {
    key: "GrossPremium",
    label: "Gross",
    flexGrow: 1,
  },
];

const PolicyColumnsBroker = [
  {
    key: "policyno",
    label: "PolicyNo",
    width: 130,
  },
  {
    key: "Broker",
    label: "Broker",
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "insurancecompany",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 100,
  },

  {
    key: "vehicleregno",
    label: "RegNo",
    width: 120,
  },

  {
    key: "policyDate",
    label: "Policy Date",
    flexGrow: 1,
  },
  {
    key: "policyenddate",
    label: "End Date",
    flexGrow: 1,
  },

  /*   {
    key: "TPPremium",
    label: "TP Prem",
    flexGrow: 1,
  },
  {
    key: "ODPremium",
    label: "OD Prem",
    flexGrow: 1,
  },
  {
    key: "NetPremium",
    label: "Net Prem",
    flexGrow: 1,
  }, */
  {
    key: "GrossPremium",
    label: "Gross Prem",
    flexGrow: 1,
  },
  /*   {
    key: "Comm TP %",
    label: "TP%",
    flexGrow: 1,
  },
  {
    key: "Comm OD %",
    label: "OD%",
    flexGrow: 1,
  },
  {
    key: "Comm Net %",
    label: "Net %",
    flexGrow: 1,
  },
  {
    key: "Comm TP",
    label: "TP Comm",
    flexGrow: 1,
  },
  {
    key: "Comm OD",
    label: "OD Comm",
    flexGrow: 1,
  },
  {
    key: "Comm Net",
    label: "Net Comm",
    flexGrow: 1,
  },
  {
    key: "totalcomm",
    label: "Comm",
    flexGrow: 1,
  }, */
];

const PolicyColumns1 = [
  {
    key: "policyno",
    label: "Policy No",
    width: 130,
  },
  {
    key: "Customer",
    label: "Customer",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "insurancecompany",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 100,
  },

  {
    key: "vehicleregno",
    label: "Reg No",
    width: 120,
  },

  {
    key: "policyDate",
    label: "Policy Date",
    flexGrow: 1,
  },
  {
    key: "policyenddate",
    label: "End Date",
    flexGrow: 1,
  },

  {
    key: "GrossPremium",
    label: "Gross Premium",
    flexGrow: 1,
  },
];
const PolicyColumns2 = [
  {
    key: "policyno",
    label: "Policy No",
    width: 130,
  },
  {
    key: "DMitra/Self",
    label: "DMitra/Self",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "Customer",
    label: "Customer",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "policytype",
    label: "Type",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "insurancecompany",
    label: "Company",
    flexGrow: 1,
  },
  {
    key: "vehiclemodel",
    label: "Model",
    width: 100,
  },

  {
    key: "vehicleregno",
    label: "Reg No",
    width: 120,
  },

  {
    key: "policyDate",
    label: "Policy Date",
    flexGrow: 1,
  },
  {
    key: "policyenddate",
    label: "End Date",
    flexGrow: 1,
  },

  {
    key: "GrossPremium",
    label: "Gross Premium",
    flexGrow: 1,
  },
];

const OtherServicesColumns = [
  {
    key: "Services",
    label: "Services",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "Date",
    label: "Date",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "DR",
    label: "DR",
    flexGrow: 1,
  },
  {
    key: "CR",
    label: "CR",
    width: 100,
  },

  {
    key: "CR2",
    label: "CR2",
    width: 120,
  },
  {
    key: "Balance",
    label: "Balance",
    flexGrow: 1,
  },
  {
    key: "Remark",
    label: "Remark",
    flexGrow: 1,
  },
];

const VehicleColumn = [
  {
    key: "Customer",
    label: "Customer",
    width: 130,
  },
  {
    key: "RegNo",
    label: "Reg No",
    flexGrow: 1,
    width: 130,
  },
  {
    key: "Name",
    label: "Name",
    flexGrow: 1,
  },
  {
    key: "PUCRenewal",
    label: "PUC",
    width: 100,
  },

  {
    key: "InsuranceRenewal",
    label: "Insurance",
    width: 120,
  },
  {
    key: "PermitRenewal",
    label: "Permit",
    flexGrow: 1,
  },
  {
    key: "RCRenewal",
    label: "RC Renewal",
    flexGrow: 1,
  },
  {
    key: "Fitness",
    label: "Fitness",
    flexGrow: 1,
  },
];

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { exportjson } from "functions";
import { EXPORT_VEHICLE } from "Permissions";
import { VIEW_VEHICLE } from "Permissions";
import { EXPORT_POLICY } from "Permissions";
import { READ_POLICY } from "Permissions";
import { READ_CUSTOMER } from "Permissions";
import { READ_BROKER } from "Permissions";
import { VIEW_MITRA } from "Permissions";
import { EXPORT_CUSTOMER } from "Permissions";

function Report() {
  const searchdata = useRef(null);
  const exportinpdf = useRef(null);
  const [value, setValue] = React.useState([new Date(), new Date()]);
  const [type, setType] = React.useState("Customer");
  const [customerType, setCustomerType] = React.useState("All");
  const [brokerType, setBrokerType] = React.useState("All");
  const [DSOType, setDSOType] = React.useState("All");
  const [policyType, setPolicyType] = React.useState("All");
  const [searchType, setSearchType] = React.useState("Policy");
  const [vehicleType, setVehicleType] = useState("All");
  const toaster = useToaster();
  const [RegNo, setRegNo] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = React.useState(false);
  const [compact, setCompact] = React.useState(false);
  const [bordered, setBordered] = React.useState(true);
  const [noData, setNoData] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [policydata, setPolicyData] = React.useState([]);
  const [showHeader, setShowHeader] = React.useState(true);
  const [autoHeight, setAutoHeight] = React.useState(true);
  const [fillHeight, setFillHeight] = React.useState(false);
  const [hover, setHover] = React.useState(true);

  const predefinedBottomRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last month",
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
    {
      label: "Tomorrow",
      value: [addDays(new Date(), 1), addDays(new Date(), 1)],
    },
    {
      label: "Next week",
      value: [startOfWeek(addDays(new Date(), 6)), endOfWeek(addDays(new Date(), 6))],
    },
    {
      label: "Next 7 days",
      value: [new Date(), addDays(new Date(), 6)],
    },
    {
      label: "Next 30 days",
      value: [new Date(), addDays(new Date(), 29)],
    },
    {
      label: "Next month",
      value: [startOfMonth(addMonths(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))],
    },
  ];
  const [controller, dispatch] = useMaterialUIController();
  const { auth } = controller;

  const [brokerList, setBrokerList] = React.useState([]);
  const [DSOList, setDSOList] = React.useState([]);
  const [customersList, setCustomersList] = React.useState([]);
  const [selectedMonth, setSelectedMonth] = React.useState(new Date());
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedDSO, setSelectedDSO] = useState("");
  const [searchInCustomer, setSearchInCustomer] = useState("");
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [searchInPolicy, setSearchInPolicy] = useState("");
  const [policySearchText, setPolicySearchText] = useState("");
  const [columnKeys, setColumnKeys] = React.useState(defaultColumns.map((column) => column.key));

  const columns = defaultColumns.filter((column) => columnKeys.some((key) => key === column.key));

  const [policyColumnKeys, setPolicyColumnKeys] = React.useState(
    PolicyColumns.map((column) => column.key)
  );

  const policyColumns = PolicyColumns.filter((column) =>
    policyColumnKeys.some((key) => key === column.key)
  );

  const CustomCell = compact ? CompactCell : Cell;
  const CustomHeaderCell = compact ? CompactHeaderCell : HeaderCell;

  const getCustomers = () => {
    axiosPrivate
      .post("/LoadCustomersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: `${item.Fullname} (${item.SrNo})`,
              value: item.UniqueID,
            }));
            // console.log(data);
            setCustomersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomersList([]);
        }
      })
      .catch((e) => {
        setCustomersList([]);
      });
  };

  const getUsersByType = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "DahiyaMitra" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            setDSOList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setDSOList([]);
        }
      })
      .catch((e) => {
        setDSOList([]);
      });
  };
  const getUsersBroker = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "Broker" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            // console.log(data);
            setBrokerList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setBrokerList([]);
        }
      })
      .catch((e) => {
        setBrokerList([]);
      });
  };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getUsersBroker();
      getUsersByType();
      getCustomers();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);
  function handleExportAllCustomer() {
    setLoading(true);
    /* var csvExporter = new ExportToCsv({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      filename: `Customer_${new Date().toISOString()}`,
      useBom: true,
      }); */

    axiosPrivate
      .post("/ExportAllCustomer.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);

          XLSX.utils.book_append_sheet(wb, ws, "CustomersData");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
          ]; // Set column widths (adjust as needed)

          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `CustomersList-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  function handleReportAllCustomer() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllCustomer.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
          setNoData(false);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }
  function handleLoadCustomersPolicy() {
    setLoading(true);
    if (!selectedCustomer) {
      toaster.push(
        <Notification type="error" header="Error">
          Please enter Customers name
        </Notification>,
        { placement: "topEnd", duration: 1000 }
      );

      return;
    }

    axiosPrivate
      .post("/ExportCustomersAllPolicy.php", { CustomerID: selectedCustomer })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
          setNoData(false);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }
  function handleExportAllCustomerPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllCustomerPolicy.php", { CustomerID: selectedCustomer })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("CustomerPolicyList", data1.Data);
          //  setData(data1.Data);
          // setNoData(false);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportAllPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllPolicy.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("AllPolicyList", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleLoadAllPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllPolicy.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportAllDuePolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllDuePolicies.php", { Month: moment(selectedMonth).format("YYYY-MM-DD") })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("AllDuePolicyList", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportLoadAllDuePolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllDuePolicy.php", { Month: moment(selectedMonth).format("YYYY-MM-DD") })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportAllBroker() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllBrokerPolicies.php", { Broker: selectedBroker })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("BrokerPolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportLoadAllBroker() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllBrokerPolicies.php", { Broker: selectedBroker })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setData([]);
      });
  }
  function handleExportBrokerPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllBrokerDuePolicies.php", {
        Broker: selectedBroker,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("BrokersPolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportLoadBrokerPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllBrokerDuePolicies.php", {
        Broker: selectedBroker,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportMonthwiseBrokerPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllBrokerMonthWise.php", {
        Broker: selectedBroker,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("BrokerMonthWisePolicy", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  function handleExportLoadMonthwiseBrokerPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllBrokerMonthWise.php", {
        Broker: selectedBroker,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }
  function handleExportAllDSO() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllDSOPolicies.php", {
        DSO: selectedDSO,
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("AllDahiyaMitra", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  function handleExportLoadAllDSO() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllDSOPolicies.php", {
        DSO: selectedDSO,
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportDSOPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllDSODuePolicies.php", {
        DSO: selectedDSO,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("DSOPolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportLoadDSOPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllDSODuePolicies.php", {
        DSO: selectedDSO,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportDSOMonthwise() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllDSOMonthwise.php", {
        DSO: selectedDSO,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("AllPolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportLoadDSOMonthwise() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllDSOMonthWise.php", {
        DSO: selectedDSO,
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setData([]);
      });
  }

  /* ==============================Self======================================= */
  function handleLoadAllSelf() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllSelfPolicies.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  function handleExportAllSelf() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllSelfPolicies.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("Policies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleLoadSelfPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllSelfDuePolicies.php", {
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setData([]);
        setLoading(false);
      });
  }

  function handleExportSelfPolicy() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllSelfDuePolicies.php", {
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("SelfDuePolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleLoadSelfMonthwise() {
    setLoading(true);

    axiosPrivate
      .post("/ExportLoadAllSelfMonthwise.php", {
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setData([]);
        setLoading(false);
      });
  }

  function handleExportSelfMonthwise() {
    setLoading(true);

    axiosPrivate
      .post("/ExportAllSelfMonthwise.php", {
        Month: moment(selectedMonth).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("SelfPolicies", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);

        setLoading(false);
      });
  }

  /* =================================Vehicle======================================== */
  function handleLoadAllVehicle() {
    setLoading(true);

    axiosPrivate
      .post("/ReportLoadAllVehicle.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          setData([]);
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        setData([]);
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportAllVehicle() {
    setLoading(true);

    axiosPrivate
      .post("/ReportExportAllVehicle.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("Vehicle", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleLoadVehicleByRegNo() {
    setLoading(true);
    if (!RegNo || RegNo === "") {
      toaster.push(<Message type="error">Please enter search criteria</Message>);
      return;
    }

    axiosPrivate
      .post("/ReportLoadVehicleByRegNo.php", { RegNo })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportVehicleByRegNo() {
    setLoading(true);
    if (!RegNo || RegNo === "") {
      toaster.push(<Message type="error">Please enter search criteria</Message>);
      return;
    }

    axiosPrivate
      .post("/ReportExportVehicleByRegNo.php", { RegNo })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("Vehicle", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleLoadVehicleByDueDates() {
    setLoading(true);

    axiosPrivate
      .post("/ReportLoadVehicleByDueDates.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleExportVehicleByDueDates() {
    setLoading(true);

    axiosPrivate
      .post("/ReportExportVehicleByDueDates.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          exportjson("Vehicle", data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  /* ================================================================================ */
  /* ==============================Search======================================= */

  function handleCustomerSearch() {
    setLoading(true);
    if (searchInCustomer === "" || customerSearchText === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter search details.
        </Notification>,
        { placement: "topEnd" }
      );
      return;
    }

    axiosPrivate
      .post("/GetSearchCustomer.php", { field: searchInCustomer, value: customerSearchText })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setData(data1.Data);
          setNoData(false);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handlePolicySearch() {
    setLoading(true);
    console.log(policyColumnKeys);
    if (searchInPolicy === "" || policySearchText === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter search details.
        </Notification>,
        { placement: "topEnd" }
      );
      setLoading(false);
      return;
    }

    axiosPrivate
      .post("/GetSearchPolicy.php", { field: searchInPolicy, value: policySearchText })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setPolicyData(data1.Data);
          setNoData(false);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  function handleCustomerSearchExportPDF() {
    axiosPrivate
      .post(
        "/GetSearchCustomerInPDF.php",
        {
          field: searchInCustomer,
          value: customerSearchText,
          fields: columnKeys,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `CustomerSearchReport-${moment().format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function handlePolicySearchExportPDF() {
    axiosPrivate
      .post(
        "/GetSearchPolicyInPDF.php",
        {
          field: searchInPolicy,
          value: policySearchText,
          fields: policyColumnKeys,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `PolicySearchReport-${moment().format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handlePolicySearchExportXLS() {
    axiosPrivate
      .post("/GetSearchPolicyInXLS.php", {
        field: searchInPolicy,
        value: policySearchText,
        fields: policyColumnKeys,
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 3 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 6 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `PolicySearchReport-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function handleCustomerSearchExportXLS() {
    axiosPrivate
      .post("/GetSearchCustomerInXLS.php", {
        field: searchInCustomer,
        value: customerSearchText,
        fields: columnKeys,
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `CustomersSearchReport-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function getinPDF() {
    toaster.push(<Message type="info">Work in Progress.</Message>);
    return;
    const content = exportinpdf.current;

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      pdf.text(`Generated at : ${moment().format("DD-MM-YYYY h:mm:ss")}`, 10, 10);
      pdf.addImage(imgData, "PNG", 10, 15, 190, 0);
      pdf.save("exported.pdf");
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1} mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={1} mt={1} py={1} px={1} borderRadius="lg" coloredShadow="secondary">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Group controlId="radioList">
                      <RadioGroup
                        name="reportType"
                        inline
                        appearance="picker"
                        defaultValue="Customer"
                        value={type}
                        onChange={(e) => {
                          setData([]);
                          setNoData(true);
                          setType(e);
                        }}
                      >
                        <Radio
                          value="Customer"
                          disabled={
                            !(
                              auth.permissions.includes(READ_CUSTOMER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Customer
                        </Radio>
                        &nbsp;
                        <Radio
                          value="Policy"
                          disabled={
                            !(
                              auth.permissions.includes(READ_POLICY) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Policy
                        </Radio>
                        &nbsp;
                        <Radio
                          value="PolicyDue"
                          disabled={
                            !(
                              auth.permissions.includes(READ_POLICY) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          PolicyDue
                        </Radio>
                        &nbsp;
                        <Radio
                          value="Broker"
                          disabled={
                            !(
                              auth.permissions.includes(READ_BROKER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Broker
                        </Radio>
                        &nbsp;
                        <Radio
                          value="DahiyaMitra"
                          disabled={
                            !(
                              auth.permissions.includes(VIEW_MITRA) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Dahiya Mitra
                        </Radio>
                        &nbsp;
                        <Radio
                          value="Vehicle"
                          disabled={
                            !(
                              auth.permissions.includes(VIEW_VEHICLE) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Vehicle
                        </Radio>
                        &nbsp;
                        <Radio value="Self">Self</Radio>
                        &nbsp;
                        <Radio
                          value="Search"
                          disabled={
                            !(
                              auth.permissions.includes(READ_CUSTOMER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Search Customer/Policy
                        </Radio>
                        {/* &nbsp;
                        <Radio value="Test" disabled={true}>
                          Test
                        </Radio> */}
                      </RadioGroup>
                    </Form.Group>
                    <hr />
                    {type == "Customer" && (
                      <Grid item xs={12}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={customerType}
                            onChange={(e) => {
                              setCustomerType(e);
                              setData([]);
                            }}
                          >
                            <Radio
                              value="All"
                              disabled={
                                !(
                                  auth.permissions.includes(READ_CUSTOMER) ||
                                  auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              All
                            </Radio>{" "}
                            &nbsp;
                            <Radio
                              value="CustomerPolicy"
                              disabled={
                                !(
                                  auth.permissions.includes(EXPORT_POLICY) ||
                                  auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Policy
                            </Radio>
                          </RadioGroup>
                          &nbsp;
                          {customerType === "All" && (
                            <Stack spacing={4}>
                              <Button
                                onClick={handleReportAllCustomer}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_CUSTOMER) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportAllCustomer}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_CUSTOMER) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export All
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1800) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {customerType === "CustomerPolicy" && (
                            <Stack spacing={4}>
                              {" "}
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedCustomer}
                                onChange={(e, _) => setSelectedCustomer(e)}
                                data={customersList}
                                width={300}
                                style={{ flex: 1, width: 300 }}
                                cleanable={false}
                              />
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleLoadCustomersPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportAllCustomerPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export All
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/*  <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "Policy" && (
                      <Stack spacing={4}>
                        <Button
                          disabled={
                            !(auth.permissions.includes(1603) || auth.user.Type === "SuperAdmin")
                          }
                          onClick={handleLoadAllPolicy}
                          appearance="primary"
                        >
                          Load
                        </Button>
                        <Button
                          onClick={handleExportAllPolicy}
                          appearance="primary"
                          disabled={
                            !(auth.permissions.includes(1803) || auth.user.Type === "SuperAdmin")
                          }
                        >
                          Export
                        </Button>
                        &nbsp;&nbsp;{" "}
                        {/* <Button
                          disabled={
                            !(auth.permissions.includes(1803) || auth.user.Type === "SuperAdmin")
                          }
                          onClick={getinPDF}
                          appearance="primary"
                        >
                          PDF
                        </Button> */}
                      </Stack>
                    )}
                    {type == "PolicyDue" && (
                      <Stack spacing={4}>
                        <DatePicker
                          oneTap
                          cleanable={false}
                          format="MMM-yyyy"
                          value={selectedMonth}
                          onChange={setSelectedMonth}
                          style={{ width: 150 }}
                        />
                        <Button
                          disabled={
                            !(
                              auth.permissions.includes(READ_POLICY) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                          onClick={handleExportLoadAllDuePolicy}
                          appearance="primary"
                        >
                          Load
                        </Button>
                        <Button
                          disabled={
                            !(
                              auth.permissions.includes(EXPORT_POLICY) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                          onClick={handleExportAllDuePolicy}
                          appearance="primary"
                        >
                          Export
                        </Button>
                        &nbsp;&nbsp;{" "}
                        {/* <Button
                          disabled={
                            !(auth.permissions.includes(1803) || auth.user.Type === "SuperAdmin")
                          }
                          onClick={getinPDF}
                          appearance="primary"
                        >
                          PDF
                        </Button> */}
                      </Stack>
                    )}
                    {type == "Broker" && (
                      <Grid item xs={12}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={brokerType}
                            onChange={setBrokerType}
                          >
                            <Radio
                              value="All"
                              disabled={
                                !(
                                  auth.permissions.includes(2001) || auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              All
                            </Radio>{" "}
                            &nbsp;
                            <Radio
                              value="BrokerPolicy"
                              disabled={
                                !(
                                  auth.permissions.includes(2001) || auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Due Policy
                            </Radio>
                            <Radio value="MonthwisePolicy">Monthwise</Radio>
                          </RadioGroup>
                          &nbsp;
                          {brokerType === "All" && (
                            <Stack spacing={2}>
                              <Button
                                onClick={handleExportLoadAllBroker}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportAllBroker}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {brokerType === "BrokerPolicy" && (
                            <Stack spacing={2}>
                              {" "}
                              <DatePicker
                                cleanable={false}
                                format="MMM-yyyy"
                                value={selectedMonth}
                                onChange={setSelectedMonth}
                                style={{ width: 150 }}
                              />
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedBroker}
                                onChange={(e, _) => setSelectedBroker(e)}
                                data={brokerList}
                                width={300}
                                style={{ flex: 1, width: 300 }}
                                cleanable={false}
                              />
                              <Button
                                onClick={handleExportLoadBrokerPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportBrokerPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {brokerType === "MonthwisePolicy" && (
                            <Stack spacing={2}>
                              {" "}
                              <DatePicker
                                cleanable={false}
                                format="MMM-yyyy"
                                value={selectedMonth}
                                onChange={setSelectedMonth}
                                style={{ width: 150 }}
                              />
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedBroker}
                                onChange={(e, _) => setSelectedBroker(e)}
                                data={brokerList}
                                width={300}
                                style={{ flex: 1, width: 300 }}
                                cleanable={false}
                              />
                              &nbsp;&nbsp;{" "}
                              <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={handleExportLoadMonthwiseBrokerPolicy}
                                appearance="primary"
                              >
                                Load
                              </Button>
                              &nbsp;&nbsp;{" "}
                              <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={handleExportMonthwiseBrokerPolicy}
                                appearance="primary"
                              >
                                Export
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1801) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "DahiyaMitra" && (
                      <Grid item xs={12}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={DSOType}
                            onChange={setDSOType}
                          >
                            <Radio value="All">All</Radio> &nbsp;
                            <Radio value="DSOPolicy">Due Policy</Radio>
                            <Radio value="DSOMonthwisePolicy">Monthwise</Radio>
                          </RadioGroup>
                          &nbsp;
                          {DSOType === "All" && (
                            <Stack spacing={2}>
                              <Button
                                onClick={handleExportLoadAllDSO}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(2201) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              &nbsp;
                              <Button
                                onClick={handleExportAllDSO}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export All
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1802) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {DSOType === "DSOPolicy" && (
                            <Stack spacing={2}>
                              {" "}
                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 220 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedDSO}
                                onChange={(e, _) => setSelectedDSO(e)}
                                data={DSOList}
                                width={300}
                                style={{ flex: 1, width: 300 }}
                                cleanable={false}
                              />
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleExportLoadDSOPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(2201) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleExportDSOPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1802) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1802) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {DSOType === "DSOMonthwisePolicy" && (
                            <Stack spacing={2}>
                              {" "}
                              <DatePicker
                                cleanable={false}
                                format="MMM-yyyy"
                                value={selectedMonth}
                                onChange={setSelectedMonth}
                                style={{ width: 150 }}
                              />
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedDSO}
                                onChange={(e, _) => setSelectedDSO(e)}
                                data={DSOList}
                                width={300}
                                style={{ flex: 1, width: 300 }}
                                cleanable={false}
                              />
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleExportLoadDSOMonthwise}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(2201) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleExportDSOMonthwise}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1802) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              &nbsp;&nbsp;{" "}
                              {/* <Button
                                disabled={
                                  !(
                                    auth.permissions.includes(1802) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                                onClick={getinPDF}
                                appearance="primary"
                              >
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "Vehicle" && (
                      <Grid item xs={12}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={vehicleType}
                            onChange={setVehicleType}
                          >
                            <Radio value="All">All</Radio> &nbsp;
                            <Radio value="RegNo">By Reg No</Radio> &nbsp;
                            <Radio value="RenewalDates">Renewal Dates</Radio>
                          </RadioGroup>
                          &nbsp;
                          {vehicleType === "All" && (
                            <Stack spacing={2}>
                              <Button
                                onClick={handleLoadAllVehicle}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(VIEW_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>

                              <Button
                                onClick={handleExportAllVehicle}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {vehicleType === "RegNo" && (
                            <Stack spacing={2}>
                              {/*  <MaskedInput
                                value={RegNo}
                                mask={[
                                  /[A-Z]/,
                                  /[A-Z]/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /[A-Z]/,
                                  /[A-Z]/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                guide={true}
                                keepCharPositions={true}
                                placeholderChar="_"
                                placeholder="vehicle registration no"
                                onChange={setRegNo}
                                style={{ width: 200 }}
                              /> */}
                              <TextField
                                value={RegNo}
                                fullWidth
                                name="RegNo"
                                placeholder="vehicle registration no"
                                style={{ textTransform: "uppercase" }}
                                id="vehicleregno"
                                onChange={(e) => {
                                  setRegNo(e.target.value);
                                }}
                              />
                              <Button
                                onClick={handleLoadVehicleByRegNo}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(VIEW_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>

                              <Button
                                onClick={handleExportVehicleByRegNo}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {vehicleType === "RenewalDates" && (
                            <Stack spacing={2}>
                              {" "}
                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 300 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />
                              <Button
                                onClick={handleLoadVehicleByDueDates}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(VIEW_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportVehicleByDueDates}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_VEHICLE) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "Self" && (
                      <Grid item xs={9}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={policyType}
                            onChange={setPolicyType}
                          >
                            <Radio value="All">All</Radio> &nbsp;
                            <Radio value="Due">Due Policy</Radio>
                            <Radio value="MonthwisePolicy">Monthwise Policy</Radio>
                          </RadioGroup>
                          &nbsp;
                          {policyType === "All" && (
                            <Stack spacing={2}>
                              <Button
                                onClick={handleLoadAllSelf}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportAllSelf}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          {policyType === "Due" && (
                            <Stack spacing={2}>
                              <DatePicker
                                cleanable={false}
                                format="MMM-yyyy"
                                value={selectedMonth}
                                onChange={setSelectedMonth}
                                style={{ width: 150 }}
                              />
                              <Button
                                onClick={handleLoadSelfPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportSelfPolicy}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                          &nbsp;
                          {policyType === "MonthwisePolicy" && (
                            <Stack spacing={2}>
                              {" "}
                              <DatePicker
                                cleanable={false}
                                format="MMM-yyyy"
                                value={selectedMonth}
                                onChange={setSelectedMonth}
                                style={{ width: 150 }}
                              />
                              <Button
                                onClick={handleLoadSelfMonthwise}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Load
                              </Button>
                              <Button
                                onClick={handleExportSelfMonthwise}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export
                              </Button>
                              {/* <Button onClick={getinPDF} appearance="primary">
                                PDF
                              </Button> */}
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "Search" && (
                      <Grid item xs={9}>
                        <div
                          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={searchType}
                            onChange={setSearchType}
                          >
                            <Radio
                              value="Customers"
                              disabled={
                                !(
                                  auth.permissions.includes(READ_CUSTOMER) ||
                                  auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Customers
                            </Radio>{" "}
                            &nbsp;
                            <Radio
                              value="Policy"
                              disabled={
                                !(
                                  auth.permissions.includes(READ_POLICY) ||
                                  auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Policy
                            </Radio>
                          </RadioGroup>
                          {searchType === "Customers" && (
                            <Stack spacing={2}>
                              <SelectPicker
                                placeholder="Search in?"
                                block={true}
                                appearance="default"
                                value={searchInCustomer}
                                onSelect={(e, _) => setSearchInCustomer(e)}
                                data={[
                                  { value: "Any", label: "Any" },
                                  { value: "FullName", label: "Name" },
                                  { value: "Address", label: "Address" },
                                  { value: "Mobile2", label: "Mobile" },
                                  { value: "RefBy", label: "Ref By" },
                                  { value: "caste", label: "Caste" },
                                  { value: "village", label: "Village" },
                                  { value: "district", label: "District" },
                                  { value: "lic", label: "Lic" },
                                  { value: "licstatus", label: "LICstatus" },
                                  { value: "pin", label: "PIN" },
                                  { value: "remark", label: "Remark" },
                                  { value: "state", label: "State" },
                                  { value: "tehsil", label: "Tehsil" },
                                  { value: "work", label: "Work" },
                                  { value: "dob", label: "DOB" },
                                  { value: "joiningdate", label: "First Visit Date" },
                                  { value: "anniversary", label: "Anniversary" },
                                ]}
                                cleanable={false}
                                searchable={false}
                              />
                              <TextField
                                value={customerSearchText}
                                name="customersearchtext"
                                placeholder="Type search text"
                                id="customersearchtext"
                                autoComplete="off"
                                onChange={(e) => {
                                  setCustomerSearchText(e.target.value);
                                }}
                                style={{ width: 300 }}
                              />
                              <Button
                                onClick={handleCustomerSearch}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(READ_CUSTOMER) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Search
                              </Button>
                              <Dropdown
                                title="Export"
                                icon={<PageIcon />}
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_CUSTOMER) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                <Dropdown.Item
                                  onClick={handleCustomerSearchExportPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={handleCustomerSearchExportXLS}
                                  icon={<PageIcon />}
                                >
                                  Export Excel
                                </Dropdown.Item>
                              </Dropdown>
                            </Stack>
                          )}
                          &nbsp;
                          {searchType === "Policy" && (
                            <Stack spacing={2}>
                              <SelectPicker
                                placeholder="Search in?"
                                block={true}
                                appearance="default"
                                value={searchInPolicy}
                                onSelect={(e, _) => setSearchInPolicy(e)}
                                data={[
                                  { value: "insurancecompany", label: "Insurance Company" },
                                  { value: "policyno", label: "PolicyNo" },
                                  { value: "policytype", label: "PolicyType" },
                                  { value: "vehiclemodel", label: "VehicleModel" },
                                  { value: "modelyear", label: "ModelYear" },
                                  { value: "vehicleregno", label: "RegNo" },
                                  { value: "Customer", label: "Customer" },
                                  { value: "policycategory", label: "PolicyCategory" },

                                  { value: "startDate", label: "startDate" },
                                  { value: "policyDate", label: "policyDate" },
                                  { value: "policyenddate", label: "PolicyEndDate" },
                                ]}
                                fullwidth
                                cleanable={false}
                                searchable={false}
                              />

                              <TextField
                                value={policySearchText}
                                name="policysearchtext"
                                placeholder="Type search text"
                                id="policysearchtext"
                                autoComplete="off"
                                onChange={(e) => {
                                  setPolicySearchText(e.target.value);
                                }}
                                style={{ width: 300 }}
                              />
                              <Button
                                onClick={handlePolicySearch}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Search
                              </Button>

                              <Dropdown
                                title="Export"
                                icon={<PageIcon />}
                                disabled={
                                  !(
                                    auth.permissions.includes(EXPORT_POLICY) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                <Dropdown.Item
                                  onClick={handlePolicySearchExportPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={handlePolicySearchExportXLS}
                                  icon={<PageIcon />}
                                >
                                  Export Excel
                                </Dropdown.Item>
                              </Dropdown>
                            </Stack>
                          )}
                        </div>
                      </Grid>
                    )}
                    {type == "Test" && (
                      <Grid item xs={9}>
                        <div
                          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                          <RadioGroup
                            inline
                            name="radio-name"
                            value={searchType}
                            onChange={setSearchType}
                          >
                            <Radio
                              value="Customers"
                              disabled={
                                !(
                                  auth.permissions.includes(1503) || auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Customers
                            </Radio>{" "}
                            &nbsp;
                            <Radio
                              value="Policy"
                              disabled={
                                !(
                                  auth.permissions.includes(1603) || auth.user.Type === "SuperAdmin"
                                )
                              }
                            >
                              Policy
                            </Radio>
                          </RadioGroup>
                          {searchType === "Customers" && (
                            <>
                              &nbsp;{" "}
                              <SelectPicker
                                placeholder="Search in?"
                                block={true}
                                appearance="default"
                                value={searchInCustomer}
                                onSelect={(e, _) => setSearchInCustomer(e)}
                                data={[
                                  { value: "Any", label: "Any" },
                                  { value: "FullName", label: "Name" },
                                  { value: "Address", label: "Address" },
                                  { value: "Mobile2", label: "Mobile" },
                                  { value: "RefBy", label: "Ref By" },
                                  { value: "caste", label: "Caste" },
                                  { value: "village", label: "Village" },
                                  { value: "district", label: "District" },
                                  { value: "lic", label: "Lic" },
                                  { value: "licstatus", label: "LICstatus" },
                                  { value: "pin", label: "PIN" },
                                  { value: "remark", label: "Remark" },
                                  { value: "state", label: "State" },
                                  { value: "tehsil", label: "Tehsil" },
                                  { value: "work", label: "Work" },
                                  { value: "dob", label: "DOB" },
                                  { value: "joiningdate", label: "Joining Date" },
                                  { value: "anniversary", label: "Anniversary" },
                                  { value: "Deleted", label: "Deleted" },
                                ]}
                                cleanable={false}
                                searchable={false}
                              />
                              &nbsp;&nbsp;{" "}
                              <TextField
                                value={customerSearchText}
                                name="customersearchtext"
                                placeholder="Type search text"
                                id="customersearchtext"
                                autoComplete="off"
                                onChange={(e) => {
                                  setCustomerSearchText(e.target.value);
                                }}
                                style={{ width: 300 }}
                              />
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleCustomerSearch}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Search
                              </Button>
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handleCustomerSearchExportPDF}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export in PDF
                              </Button>
                            </>
                          )}
                          &nbsp;
                          {searchType === "Policy" && (
                            <>
                              &nbsp;{" "}
                              <SelectPicker
                                placeholder="Search in?"
                                block={true}
                                appearance="default"
                                value={searchInPolicy}
                                onSelect={(e, _) => setSearchInPolicy(e)}
                                data={[
                                  { value: "insurancecompany", label: "Insurance Company" },
                                  { value: "policyno", label: "PolicyNo" },
                                  { value: "policytype", label: "PolicyType" },
                                  { value: "vehiclemodel", label: "VehicleModel" },
                                  { value: "modelyear", label: "ModelYear" },
                                  { value: "vehicleregno", label: "RegNo" },
                                  { value: "paymentDate", label: "paymentDate" },
                                  { value: "startDate", label: "startDate" },
                                  { value: "policyDate", label: "policyDate" },
                                  { value: "policyenddate", label: "policyenddate" },
                                ]}
                                fullwidth
                                cleanable={false}
                                searchable={false}
                              />
                              {/* `insurancecompany`, `policyno`, `policytype`, `vehiclemodel`, `modelyear`, `vehicleregno`,  
                              `paymentDate`, `startDate`, `policyDate`,`policyenddate` */}
                              &nbsp;&nbsp;{" "}
                              <TextField
                                value={policySearchText}
                                name="policysearchtext"
                                placeholder="Type search text"
                                id="policysearchtext"
                                autoComplete="off"
                                onChange={(e) => {
                                  setPolicySearchText(e.target.value);
                                }}
                                style={{ width: 300 }}
                              />
                              <Button
                                onClick={handlePolicySearch}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Search
                              </Button>
                              &nbsp;&nbsp;{" "}
                              <Button
                                onClick={handlePolicySearchExportPDF}
                                appearance="primary"
                                disabled={
                                  !(
                                    auth.permissions.includes(1803) ||
                                    auth.user.Type === "SuperAdmin"
                                  )
                                }
                              >
                                Export in PDF
                              </Button>
                            </>
                          )}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
              {(data.length > 0 || policydata.length) > 0 && (
                <MDBox pt={1} px={1}>
                  {type === "Customer" &&
                    (customerType === "All" ? (
                      <div
                        style={{
                          maxHeight: 500,
                          maxwidth: 1800,
                          fontSize: "1rem",
                          fontFamily: "poppins",
                          overflow: "auto",
                        }}
                      >
                        <table
                          style={{
                            position: "relative",
                            maxHeight: 400,
                          }}
                          id="tabledata"
                        >
                          <thead
                            style={{
                              borderBottom: "1px solid #333",
                              marginBottom: 5,
                              fontFamily: "poppins",
                              height: 50,
                            }}
                          >
                            <th
                              width={300}
                              style={{
                                fontSize: 13,
                                textTransform: "capitalize",
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              CustomerID
                            </th>
                            <th
                              width={350}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              First Visit Date
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              RefBy
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                textTransform: "capitalize",
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Fullname
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Caste
                            </th>

                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Address
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Village
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Tehsil
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              District
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              State
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Mobile
                            </th>
                            <th
                              width={300}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              DOB
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              Anniversary
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              work
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              LIC
                            </th>
                            <th
                              width={200}
                              style={{
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              LICStatus
                            </th>
                          </thead>
                          <tbody>
                            {data.map((data) => (
                              <tr
                                key={data.SrNo}
                                style={{
                                  marginTop: 10,
                                  borderBottom: "dotted",
                                  borderWidth: 1,
                                  fontSize: 13,
                                }}
                              >
                                <td width={300}>
                                  <Link to={`/customers/view/${data.UniqueID}`}>{data.SrNo}</Link>
                                </td>
                                <td width={300} style={{ fontSize: 12 }}>
                                  {data.joiningdate}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.RefBy}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.FullName}
                                </td>

                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.caste}
                                </td>
                                <td width={300} style={{ fontSize: 12 }}>
                                  {data.Address}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.village}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.tehsil}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.district}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.state}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.Mobile2}
                                </td>

                                <td width={300} style={{ fontSize: 12 }}>
                                  {data.dob === "0000-00-00" ||
                                  data.dob === undefined ||
                                  data.dob === null
                                    ? ""
                                    : moment(data.dob).format("DD-MM-YYYY")}
                                </td>
                                <td width={300} style={{ fontSize: 12 }}>
                                  {data.anniversary === "0000-00-00" ||
                                  data.anniversary === undefined ||
                                  data.anniversary === null
                                    ? ""
                                    : moment(data.anniversary).format("DD-MM-YYYY")}
                                </td>
                                <td width={300} style={{ fontSize: 12 }}>
                                  {data.work}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.lic}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.licstatus}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : customerType === "CustomerPolicy" ? (
                      <div
                        style={{
                          maxHeight: 500,
                          width: 1200,
                          fontSize: "1rem",
                          fontFamily: "poppins",
                          overflow: "auto",
                        }}
                      >
                        <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                          <thead
                            style={{
                              borderBottom: "1px solid #333",
                              marginBottom: 5,
                              fontFamily: "poppins",
                              height: 50,
                            }}
                          >
                            {CustomerpolicyColumns.map((key) => (
                              <th
                                key={key.key}
                                style={{
                                  width: 200,
                                  background: "white",
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 13,
                                }}
                              >
                                {key.key}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {data.map((data) => (
                              <tr
                                key={data.SrNo}
                                style={{
                                  marginTop: 10,
                                  borderBottom: "dotted",
                                  borderWidth: 1,
                                  fontSize: 13,
                                }}
                              >
                                <td width={300}>
                                  <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                                </td>

                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {data.policytype}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {data.insurancecompany}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {data.vehiclemodel}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {data.vehicleregno}
                                </td>

                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {moment(data.startdate).format("DD-MM-YYYY")}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {moment(data.policydate).format("DD-MM-YYYY")}
                                </td>
                                <td width={300} style={{ fontSize: 12, width: 200 }}>
                                  {moment(data.policyenddate).format("DD-MM-YYYY")}
                                </td>
                                <td width={200} style={{ fontSize: 12 }}>
                                  {toAmnt(data.grosspremium)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        style={{
                          maxHeight: 500,
                          width: 1200,
                          fontSize: "1rem",
                          fontFamily: "poppins",
                          overflow: "auto",
                        }}
                      >
                        <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                          <thead
                            style={{
                              borderBottom: "1px solid #333",
                              marginBottom: 5,
                              fontFamily: "poppins",
                              height: 50,
                            }}
                          >
                            {OtherServicesColumns.map((key) => (
                              <th
                                key={key.key}
                                style={{
                                  width: 200,
                                  fontSize: 13,
                                  background: "white",
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 13,
                                }}
                              >
                                {key.key}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {data.map((data) => (
                              <tr
                                key={data.Service - data.Date}
                                style={{
                                  marginTop: 10,
                                  borderBottom: "dotted",
                                  borderWidth: 1,
                                  fontSize: 13,
                                  height: 30,
                                }}
                              >
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {data.Service}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {moment(data.Date).format("DD-MM-YYYY")}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {toAmnt(data.DR)}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {toAmnt(data.CR)}
                                </td>

                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {toAmnt(data.CR2)}
                                </td>
                                <td width={200} style={{ fontSize: 12, width: 200 }}>
                                  {toAmnt(data.Balance)}
                                </td>

                                <td width={200} style={{ fontSize: 12 }}>
                                  {data.Remark}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}

                  {/* =================================All Policy================================= */}
                  {type === "Policy" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {PolicyColumns2.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 200,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300}>
                                <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.DSOName}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.Customer}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.policytype}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.insurancecompany}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehiclemodel}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehicleregno}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policydate).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {data.policyenddate &&
                                  moment(data.policyenddate).format("DD-MM-YYYY")}
                              </td>

                              <td width={200} style={{ fontSize: 12 }}>
                                {toAmnt(data.grosspremium)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}

                  {/* =================================All Policy================================= */}
                  {type === "PolicyDue" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {PolicyColumns1.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 200,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300}>
                                <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.Customer}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.policytype}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.insurancecompany}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehiclemodel}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehicleregno}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policydate).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policyenddate).format("DD-MM-YYYY")}
                              </td>

                              <td width={200} style={{ fontSize: 12 }}>
                                {toAmnt(data.grosspremium)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}

                  {/* =================================Broker================================= */}
                  {type === "Broker" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {PolicyColumnsBroker.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 200,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300}>
                                <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.Broker}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.policytype}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.insurancecompany}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehiclemodel}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehicleregno}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policydate).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policyenddate).format("DD-MM-YYYY")}
                              </td>
                              <td width={200} style={{ fontSize: 12 }}>
                                {toAmnt(data.grosspremium)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}
                  {/* =================================Dahiya Mitra================================= */}
                  {type === "DahiyaMitra" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {PolicyColumnsDSO.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 200,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody style={{ paddingBottom: 10 }}>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300}>
                                <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                              </td>

                              <td
                                width={200}
                                style={{ fontSize: 12, width: 200, textTransform: "capitalize" }}
                              >
                                {data.DahiyaMitra}
                              </td>
                              <td
                                width={200}
                                style={{ fontSize: 12, width: 200, textTransform: "capitalize" }}
                              >
                                {data.policytype}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.insurancecompany}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehiclemodel}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehicleregno}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policydate).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policyenddate).format("DD-MM-YYYY")}
                              </td>

                              <td width={200} style={{ fontSize: 12 }}>
                                {toAmnt(data.grosspremium)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}

                  {/* =================================Vehicle================================= */}
                  {type === "Vehicle" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {VehicleColumn.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 250,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300} style={{ textTransform: "capitalize" }}>
                                <Link to={`/customers/view/${data.Customer}`}>
                                  {data.CustomerName}
                                </Link>
                              </td>

                              <td width={250} style={{ fontSize: 12, width: 200 }}>
                                <Link to={`/vehicle/edit/${data.UniqueID}`}>{data.RegNo}</Link>
                              </td>
                              <td
                                width={200}
                                style={{ fontSize: 12, width: 200, textTransform: "capitalize" }}
                              >
                                {data.Name}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.PUC === "0000-00-00" || data.PUC === undefined
                                  ? null
                                  : moment(data.PUC).format("DD-MM-YYYY")}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.Insurance === "0000-00-00" || data.Insurance === undefined
                                  ? null
                                  : moment(data.Insurance).format("DD-MM-YYYY")}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.Permit === "0000-00-00" || data.Permit === undefined
                                  ? null
                                  : moment(data.Permit).format("DD-MM-YYYY")}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.RCRenewal === "0000-00-00" || data.RCRenewal === undefined
                                  ? null
                                  : moment(data.RCRenewal).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {data.Fitness === "0000-00-00" || data.Fitness === undefined
                                  ? null
                                  : moment(data.Fitness).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}

                  {/* =================================Self================================= */}
                  {type === "Self" && (
                    <div
                      style={{
                        maxHeight: 500,
                        width: 1200,
                        fontSize: "1rem",
                        fontFamily: "poppins",
                        overflow: "auto",
                      }}
                    >
                      <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                        <thead
                          style={{
                            borderBottom: "1px solid #333",
                            marginBottom: 5,
                            fontFamily: "poppins",
                            height: 50,
                          }}
                        >
                          {PolicyColumnsSelf.map((key) => (
                            <th
                              key={key.key}
                              style={{
                                width: 200,
                                fontSize: 13,
                                background: "white",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              {key.label}
                            </th>
                          ))}
                        </thead>

                        <tbody>
                          {data.map((data) => (
                            <tr
                              key={data.SrNo}
                              style={{
                                marginTop: 10,
                                borderBottom: "dotted",
                                borderWidth: 1,
                                fontSize: 13,
                              }}
                            >
                              <td width={300}>
                                <Link to={`/policy/${data.UniqueID}`}>{data.policyno}</Link>
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.CustomerName}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.policytype}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.insurancecompany}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehiclemodel}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {data.vehicleregno}
                              </td>

                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.startdate).format("DD-MM-YYYY")}
                              </td>
                              <td width={200} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policydate).format("DD-MM-YYYY")}
                              </td>
                              <td width={300} style={{ fontSize: 12, width: 200 }}>
                                {moment(data.policyenddate).format("DD-MM-YYYY")}
                              </td>

                              <td width={200} style={{ fontSize: 12 }}>
                                {toAmnt(data.grosspremium)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* ====================================================================== */}

                  {type === "Search" &&
                    (searchType === "Customers" ? (
                      <div style={{ height: autoHeight ? "auto" : 400 }} id="searchdata">
                        Columns：
                        <TagPicker
                          data={defaultColumns}
                          labelKey="label"
                          valueKey="key"
                          value={columnKeys}
                          onChange={setColumnKeys}
                          cleanable={false}
                        />
                        <hr />
                        <TableNew
                          ref={searchdata}
                          id="tabledata"
                          loading={loading}
                          height={400}
                          hover={hover}
                          fillHeight={fillHeight}
                          showHeader={showHeader}
                          autoHeight={autoHeight}
                          data={noData ? [] : data}
                          bordered={bordered}
                          cellBordered={bordered}
                          headerHeight={compact ? 30 : 40}
                          rowHeight={compact ? 30 : 46}
                        >
                          {columns.map((column) => {
                            const { key, label, ...rest } = column;
                            return (
                              <>
                                <Column {...rest} key={key}>
                                  <CustomHeaderCell>{label}</CustomHeaderCell>
                                  <CustomCell style={{ fontSize: 13 }} dataKey={key} />
                                </Column>
                              </>
                            );
                          })}
                        </TableNew>
                      </div>
                    ) : (
                      <div style={{ height: autoHeight ? "auto" : 400 }}>
                        Columns：
                        <TagPicker
                          data={PolicyColumns}
                          labelKey="label"
                          valueKey="key"
                          value={policyColumnKeys}
                          onChange={setPolicyColumnKeys}
                          cleanable={false}
                        />
                        <hr />
                        <div id="searchdata">
                          <TableNew
                            ref={searchdata}
                            loading={loading}
                            height={400}
                            hover={hover}
                            fillHeight={fillHeight}
                            showHeader={showHeader}
                            autoHeight={autoHeight}
                            data={noData ? [] : policydata}
                            bordered={bordered}
                            cellBordered={bordered}
                            headerHeight={compact ? 30 : 40}
                            rowHeight={compact ? 30 : 46}
                          >
                            {policyColumns.map((column) => {
                              const { key, label, ...rest } = column;
                              return (
                                <Column {...rest} key={key}>
                                  <CustomHeaderCell>{label}</CustomHeaderCell>
                                  <CustomCell style={{ fontSize: 13 }} dataKey={key} />
                                </Column>
                              );
                            })}
                          </TableNew>
                        </div>
                      </div>
                    ))}
                  {type === "Test" &&
                    (searchType === "Customers" ? (
                      <div style={{ height: autoHeight ? "auto" : 400 }} id="searchdata">
                        Columns：
                        <TagPicker
                          data={defaultColumns}
                          labelKey="label"
                          valueKey="key"
                          value={columnKeys}
                          onChange={setColumnKeys}
                          cleanable={false}
                        />
                        <hr />
                        <TableNew
                          ref={searchdata}
                          id="tabledata"
                          loading={loading}
                          height={400}
                          hover={hover}
                          fillHeight={fillHeight}
                          showHeader={showHeader}
                          autoHeight={autoHeight}
                          data={noData ? [] : data}
                          bordered={bordered}
                          cellBordered={bordered}
                          headerHeight={compact ? 30 : 40}
                          rowHeight={compact ? 30 : 46}
                        >
                          {columns.map((column) => {
                            const { key, label, ...rest } = column;
                            return (
                              <>
                                <Column {...rest} key={key}>
                                  <CustomHeaderCell>{label}</CustomHeaderCell>
                                  <CustomCell style={{ fontSize: 13 }} dataKey={key} />
                                </Column>
                              </>
                            );
                          })}
                        </TableNew>
                      </div>
                    ) : (
                      <div style={{ height: autoHeight ? "auto" : 400 }}>
                        Columns：
                        <TagPicker
                          data={PolicyColumns}
                          labelKey="label"
                          valueKey="key"
                          value={policyColumnKeys}
                          onChange={setPolicyColumnKeys}
                          cleanable={false}
                        />
                        <hr />
                        <div id="searchdata">
                          <TableNew
                            onSortColumn={(sortColumn, sortType) => {
                              console.log(sortColumn, sortType);
                            }}
                            ref={searchdata}
                            loading={loading}
                            height={400}
                            hover={hover}
                            fillHeight={fillHeight}
                            showHeader={showHeader}
                            autoHeight={autoHeight}
                            data={noData ? [] : policydata}
                            bordered={bordered}
                            cellBordered={bordered}
                            headerHeight={compact ? 30 : 40}
                            rowHeight={compact ? 30 : 46}
                            affixHeader
                            affixHorizontalScrollbar
                          >
                            {policyColumns.map((column) => {
                              const { key, label, ...rest } = column;
                              return (
                                <Column {...rest} key={key} fixed fullText wordWrap="break-word">
                                  <CustomHeaderCell>{label}</CustomHeaderCell>
                                  <CustomCell style={{ fontSize: 13 }} dataKey={key} />
                                </Column>
                              );
                            })}
                          </TableNew>
                        </div>
                      </div>
                    ))}
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Report;
