import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as XLSX from "xlsx";
import ImageIcon from "@mui/icons-material/Image";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReloadIcon from "@rsuite/icons/Reload";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import Slide from "@mui/material/Slide";
import { DateRangePicker, Dropdown, Input, Stack } from "rsuite";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { useToaster, Notification } from "rsuite";
import { DatePicker } from "rsuite";
import { SelectPicker } from "rsuite";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Radio, RadioGroup, Form, Button, Uploader, Message, Loader, IconButton } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { toAmnt } from "functions";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useMaterialUIController } from "context";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PageIcon from "@rsuite/icons/Page";
import DetailIcon from "@rsuite/icons/Detail";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { Icon } from "@mui/material";
import Logo from "Logo";
import { balanceAmnt } from "functions";
import { compare } from "functions";
import { balanceAmntService } from "functions";
import { Table2XLSX } from "functions";
import { DeleteForever, FileDownload } from "@mui/icons-material";
import { AC_SELF } from "Permissions";
import { AC_BROKER } from "Permissions";
import { AC_MITRA } from "Permissions";
import { AC_CUSTOMER } from "Permissions";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
function Accounting() {
  const predefinedBottomRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last month",
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
    {
      label: "Tomorrow",
      value: [addDays(new Date(), 1), addDays(new Date(), 1)],
    },
    {
      label: "Next week",
      value: [startOfWeek(addDays(new Date(), 6)), endOfWeek(addDays(new Date(), 6))],
    },
    {
      label: "Next 7 days",
      value: [new Date(), addDays(new Date(), 6)],
    },
    {
      label: "Next 30 days",
      value: [new Date(), addDays(new Date(), 29)],
    },
    {
      label: "Next month",
      value: [startOfMonth(addMonths(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))],
    },
  ];
  const [value, setValue] = React.useState([new Date(), new Date()]);
  const [controller] = useMaterialUIController();
  const { auth } = controller;
  const [type, setType] = React.useState("Customer");

  const [paymentID, setPaymentID] = useState();
  const [payerPaymentID, setPayerPaymentID] = useState();
  const [expenseID, setExpenseID] = useState();
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [showHeader, setshowHeader] = useState(false);
  const toaster = useToaster();

  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = React.useState(false);
  const [brokerList, setBrokerList] = React.useState([]);
  const [serviceProviderList, setServiceProviderList] = React.useState([]);
  const [DSOList, setDSOList] = React.useState([]);
  const [customersList, setCustomersList] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedDSO, setSelectedDSO] = useState("");

  const [customerData, setCustomerData] = useState([]);
  const [serviceProviderData, setServiceProviderData] = useState([]);
  const [dsoPolicyData, setDSOPolicyData] = useState([]);
  const [brokerPolicyData, setBrokerPolicyData] = useState([]);
  const [brokerPrevious, setBrokerPrevious] = useState({});
  const [brokerBalance, setBrokerBalance] = useState({});
  const [profitBalance, setProfitBalance] = useState({});

  const [dsoPrevious, setDSOPrevious] = useState();
  const [serviceProviderPrevious, setServiceProviderPrevious] = useState();
  const [dsoBalance, setDSOBalance] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  const [payersList, setPayersList] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [payerPaymentData, setPayerPaymentData] = useState({});
  const [otherServiceData, setOtherServiceData] = useState({});
  const [brokerServiceData, setBrokerServiceData] = useState({});
  const [otherOpen, setOtherOpen] = useState(false);
  const [brokerOpen, setBrokerOpen] = useState(false);
  const [openImageView, setOpenImageView] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPayer, setOpenPayer] = useState(false);

  const [customerTotal, setCustomerTotal] = useState({});
  const [customerPrevious, setCustomerPrevious] = useState({});
  const [customerBalance, setCustomerBalance] = useState({});
  const [serviceProviderTotal, setServiceProviderTotal] = useState({});
  const [DSOTotal, setDSOTotal] = useState({});
  const [payeeTotal, setPayeeTotal] = useState();

  const [profit, setProfit] = useState("");
  const [expenseData, setExpenseData] = useState({});
  const [openExpenses, setOpenExpenses] = useState(false);
  const [incomeData, setIncomeData] = useState({});
  const [openIncome, setOpenIncome] = useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [allData, setAllData] = useState([]);
  const [gross, setGross] = useState(0);
  const [comm, setComm] = useState(0);
  const [CR2, setCR2] = useState(0);
  const [recieved, setRecieved] = useState(0);
  const [spent, setSpent] = useState(0);
  const [paid, setPaid] = useState(0);
  const [imageLink, setimageLink] = useState(null);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const [paymentFileInfo, setPaymentFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const [brokerTotal, setbrokerTotal] = useState({});
  function handleAddPaymentChange(evt) {
    const value = evt.target.value;
    setPaymentData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  function handleAddPayerPaymentChange(evt) {
    const value = evt.target.value;
    setPayerPaymentData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  const handleClose = () => {
    setOpen(false);
    setPaymentData((a) => {
      return { ...a, amount: "", mode: "" };
    });
    setDescription("");
  };

  const handleOpen = () => {
    if (paymentData?.Name) {
      setOpen(true);
    } else {
      toaster.push(<Message type="error">{`Please select payee`}</Message>);
    }
  };
  const handlePayerClose = () => {
    setOpenPayer(false);
    setPayerPaymentID(null);

    setPayerPaymentData({ Date: null });
  };

  const handlePayerOpen = () => {
    /*     if (payerPaymentData?.Name) {
    } else {
      toaster.push(<Message type="error">{`Please select payee`}</Message>);
    } */
    setOpenPayer(true);
  };

  const handleOtherClose = () => {
    setOtherOpen(false);
    setOtherServiceData({});
    setPaymentID(null);
  };

  const handleOtherOpen = () => {
    setOtherOpen(true);
    setOtherServiceData({ CustomerID: selectedCustomer });
  };
  const handleBrokerClose = () => {
    setBrokerOpen(false);
  };

  const handleBrokerOpen = () => {
    setBrokerOpen(true);
  };
  const handleExpenseClose = () => {
    setOpenExpenses(false);
  };

  const handleExpenseOpen = () => {
    setOpenExpenses(true);
    setExpenseData({});
    setExpenseID(null);
    setDateTime(null);
    setFileInfo(null);
  };
  const handleIncomeClose = () => {
    setOpenIncome(false);
  };

  const handleIncomeOpen = () => {
    setOpenIncome(true);
  };

  const handleCloseImageView = () => {
    setOpenImageView(false);
  };

  const handleOpenImageView = () => {
    setOpenImageView(true);
  };

  const getPayers = () => {
    axiosPrivate
      .post("/LoadPayers.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              type: item.Type,
              ID: item.UniqueID,
            }));
            // console.log(data);
            setPayersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPayersList([]);
        }
      })
      .catch((e) => {
        setPayersList([]);
      });
  };

  const getServiceProviderData = () => {
    if (selectedBroker === undefined || selectedBroker === null || selectedBroker === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Service Provider`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    axiosPrivate
      .post("/GetServiceProviderAccount.php", {
        ID: selectedBroker,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setServiceProviderData(result.AllData);
            setServiceProviderTotal({
              DR: result.DR,
              CR: result.CR,
              Balance: result.Balance,
              Paid: result.Paid,
            });
            setServiceProviderPrevious(result.Previous);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setServiceProviderData([]);
          setServiceProviderPrevious();
        }
      })
      .catch((e) => {
        setServiceProviderData([]);
        setServiceProviderPrevious();
      });
  };

  function getServiceProviderDatainXls() {
    axiosPrivate
      .post("/GetServiceProviderAccountInXLS.php", {
        ServiceProvider: selectedBroker,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 3 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 6 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(
            wb,
            `ServiceProviderAccount - ${paymentData.Name} -${moment(value[0]).format(
              "YYYY-MM-DD"
            )}-${moment(value[1]).format("YYYY-MM-DD")}.xlsx`
          );
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  function getServiceProviderDatainPDF() {
    // toaster.push(<Message type="info">Work in Progress.</Message>);
    // return;
    axiosPrivate
      .post(
        "/GetServiceProviderAccountInPDF.php",
        {
          ServiceProvider: selectedBroker,
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `ServiceProviderAccount - ${paymentData.Name}-${moment(value[0]).format(
          "YYYY-MM-DD"
        )}-${moment(value[1]).format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getSelfData = () => {
    axiosPrivate
      .post("/GetSelfAccountBalance.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            /*             setBrokerPolicyData(result.Data);
            setBrokerPayments(result.Payments);
            setCustomerServices(result.Other);
            setExpensePayments(result.Expense);
            setIncomePayments(result.Income); */
            setCR2(result.CR2);
            setGross(result.Gross);
            setComm(result.Comm);
            setRecieved(result.Recieved);
            setSpent(result.Spent);
            setAllData(result.AllData);
            setProfit(result.Profit);
            setPaid(result.Paid);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setGross();
          setComm();
          setRecieved();
          setSpent();
          setAllData([]);
          setProfit();
          setPaid();
        }
      })
      .catch((e) => {
        setGross();
        setComm();
        setRecieved();
        setSpent();
        setAllData([]);
        setProfit();
        setPaid();
      });
  };

  const getProfitData = () => {
    axiosPrivate
      .post("/GetProfitAccountBalance.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setAllData(result.AllData);
            setProfitBalance(result.Total);
            /*             setBrokerPolicyData(result.Data);
            setBrokerPayments(result.Payments);
            setCustomerServices(result.Other);
            setExpensePayments(result.Expense);
            setIncomePayments(result.Income); */
            /*             setCR2(result.CR2);
            setGross(result.Gross);
            setComm(result.Comm);
            setRecieved(result.Recieved);
            setSpent(result.Spent);
            setProfit(result.Profit);
            setPaid(result.Paid); */
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);

          setAllData([]);
        }
      })
      .catch((e) => {
        setAllData([]);
      });
  };

  const getProfitDataInxls = () => {
    axiosPrivate
      .post("/GetProfitAccountBalanceInXLS.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.AllData, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 55 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 3 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 12 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `Report-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  function getSelfDatainXLS() {
    axiosPrivate
      .post("/GetSelfAccountBalanceInXLS.php", {
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 3 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 12 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `Report-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const getPayerData = () => {
    console.log(payerPaymentData);
    if (
      payerPaymentData?.ID === undefined ||
      payerPaymentData?.ID === null ||
      payerPaymentData?.ID === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Select payee`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    axiosPrivate
      .post("/GetPayersData.php", {
        ID: payerPaymentData?.ID,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setPayeeTotal(result.Total);
            setAllData(result.AllData);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          setPayeeTotal(0);
          setAllData([]);
        }
      })
      .catch((e) => {
        setPayeeTotal(0);
        setAllData([]);
      });
  };

  const getUsersByType = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "DahiyaMitra" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            setDSOList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setDSOList([]);
        }
      })
      .catch((e) => {
        setDSOList([]);
      });
  };
  const getUsersBroker = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "Broker" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            // console.log(data);
            setBrokerList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setBrokerList([]);
        }
      })
      .catch((e) => {
        setBrokerList([]);
      });
  };
  const getUsersServiceProvider = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "ServiceProvider" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            // console.log(data);
            setServiceProviderList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setServiceProviderList([]);
        }
      })
      .catch((e) => {
        setServiceProviderList([]);
      });
  };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getUsersBroker();
      getUsersByType();
      getCustomers();
      getUsersServiceProvider();
      getPayers();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  const AddPayment = async () => {
    if (type === undefined || type === null || type === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`Error occured`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "Customer" &&
      (selectedCustomer === undefined || selectedCustomer === null || selectedCustomer === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select customer`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "Broker" &&
      (selectedBroker === undefined || selectedBroker === null || selectedBroker === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Broker`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "DahiyaMitra" &&
      (selectedDSO === undefined || selectedDSO === null || selectedDSO === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Dahiya Mitra`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "ServiceProvider" &&
      (selectedBroker === undefined || selectedBroker === null || selectedBroker === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Service Provider`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      paymentData.amount === undefined ||
      paymentData.amount === null ||
      paymentData.amount === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter amount`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        Name: paymentData.Name,
        NameID: paymentData.ID,
        amount: paymentData.amount,
        mode: paymentData.mode,
        date: moment(dateTime).format("YYYY-MM-DD"),
        note: description,
        Type: type,
      };
      const response = await axiosPrivate.post("/AddPayment.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Payment updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPaymentID(result.ID);
        //handleClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddPayerPayment = async () => {
    console.log(payerPaymentData);
    if (
      payerPaymentData?.Name === undefined ||
      payerPaymentData?.Name === null ||
      payerPaymentData?.Name === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Select payee`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (
      payerPaymentData?.amount === undefined ||
      payerPaymentData?.amount === null ||
      payerPaymentData?.amount === 0 ||
      payerPaymentData?.amount === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter amount`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        Name: payerPaymentData.Name,
        NameID: payerPaymentData.ID,
        amount: payerPaymentData.amount,
        mode: payerPaymentData.mode,
        date: moment(payerPaymentData.Date).format("YYYY-MM-DD"),
        note: payerPaymentData.description,
        Type: payerPaymentData.Type,
      };
      const response = await axiosPrivate.post("/AddPayerPayment.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Payment updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPayerPaymentID(result.ID);

        setPayerPaymentData({ Date: null });
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const RetrievePayment = async (ID) => {
    try {
      const response = await axiosPrivate.post("/GetPaymentData.php", { ID });
      const result = response.data;
      if (result.status === 1) {
        /* Name: paymentData.Name,
        NameID: paymentData.ID,
        amount: paymentData.amount,
        mode: paymentData.mode,
        date: moment(dateTime).format("YYYY-MM-DD"),
        note: description,
        Type: type,
        ID: paymentID, */
        setPaymentData({
          ...paymentData,
          Name: result.Data[0].Name,
          ID: result.Data[0].NameID,
          amount: result.Data[0].Amount,
          mode: result.Data[0].Method,
          Doc: result.Data[0].Doc || "",
          PaymentID: ID,
        });
        setDateTime(new Date(result.Data[0].Date));
        setDescription(result.Data[0].Note);
        setType(result.Data[0].Type);
        setPaymentID(ID);
        setPaymentFileInfo(
          result.Data[0]?.Doc
            ? `https://Downloads.dahiyainsurance.com/assets/${result.Data[0].Doc}`
            : null
        );
        handleOpen();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const UpdatePayment = async () => {
    if (type === undefined || type === null || type === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`Error occured`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "Customer" &&
      (selectedCustomer === undefined || selectedCustomer === null || selectedCustomer === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select customer`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "Broker" &&
      (selectedBroker === undefined || selectedBroker === null || selectedBroker === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Broker`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      type === "DahiyaMitra" &&
      (selectedDSO === undefined || selectedDSO === null || selectedDSO === "")
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select Dahiya Mitra`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (
      paymentData.amount === undefined ||
      paymentData.amount === null ||
      paymentData.amount === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter amount`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        Name: paymentData.Name,
        NameID: paymentData.ID,
        amount: paymentData.amount,
        mode: paymentData.mode,
        date: moment(dateTime).format("YYYY-MM-DD"),
        note: description,
        Type: type,
        ID: paymentData.PaymentID,
      };
      const response = await axiosPrivate.post("/EditPayment.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Payment updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    const { cr, dr, cr2 } = otherServiceData;

    if (!isNaN(cr) || !isNaN(dr) || !isNaN(cr2)) {
      setOtherServiceData((a) => {
        return { ...a, balance: dr - cr - cr2 || 0 };
      });
    }
    console.log(dr - cr - cr2);
    return () => {};
  }, [otherServiceData.cr, otherServiceData.dr, otherServiceData.cr2]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const { cr, dr } = brokerServiceData;

      if (!isNaN(cr) || !isNaN(dr)) {
        setBrokerServiceData((a) => {
          return { ...a, balance: cr - dr };
        });
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [brokerServiceData.cr, brokerServiceData.dr]);

  const AddOtherServices = async () => {
    console.log(otherServiceData);
    const { CustomerID, agentid, agent, balance, cr, cr2, dr, remark, service, date } =
      otherServiceData;
    if (CustomerID === undefined || CustomerID === null || CustomerID === 0) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select customer first`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (
      agentid === undefined ||
      agentid === null ||
      agentid === 0 ||
      balance === undefined ||
      balance === null ||
      balance === 0 ||
      cr === undefined ||
      cr === null ||
      cr === 0 ||
      cr2 === undefined ||
      cr2 === null ||
      cr2 === 0 ||
      dr === undefined ||
      dr === null ||
      dr === 0 ||
      service === undefined ||
      service === null ||
      service === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter all details.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        CustomerID,
        agentid,
        agent,
        balance,
        cr,
        cr2,
        dr,
        remark,
        service,
        date: moment(dateTime).format("YYYY-MM-DD"),
      };
      const response = await axiosPrivate.post("/AddOtherServices.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleOtherClose();
        getCustomerData();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const RetrieveOtherServices = async (ID) => {
    try {
      const response = await axiosPrivate.post("/GetOtherServicesData.php", { ID });
      const result = response.data;
      if (result.status === 1) {
        // setOtherServiceData(result.Data[0]);
        handleOtherOpen();
        setOtherServiceData({ ...result.Data[0], ID });
        setDateTime(
          result.Data[0].date === undefined || result.Data[0].date === "0000-00-00"
            ? null
            : new Date(result.Data[0].date)
        );
      }

      if (result.status === 0) {
        toaster.push(<Message type="error">{result.Error}</Message>);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const UpdateOtherServices = async () => {
    console.log(otherServiceData);
    const { CustomerID, agentid, agent, balance, cr, cr2, dr, remark, service, date, ID } =
      otherServiceData;
    if (CustomerID === undefined || CustomerID === null || CustomerID === 0) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select customer first`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (
      agentid === undefined ||
      agentid === null ||
      agentid === 0 ||
      balance === undefined ||
      balance === null ||
      balance === 0 ||
      cr === undefined ||
      cr === null ||
      cr === 0 ||
      cr2 === undefined ||
      cr2 === null ||
      cr2 === 0 ||
      dr === undefined ||
      dr === null ||
      dr === 0 ||
      service === undefined ||
      service === null ||
      service === 0
    ) {
      toaster.push(<Message type="error">{`Please enter all details.`}</Message>);
      return;
    }

    try {
      const datatosend = {
        CustomerID,
        agentid,
        agent,
        balance,
        cr,
        cr2,
        dr,
        remark,
        service,
        date: moment(dateTime).format("YYYY-MM-DD"),
        ID,
      };
      const response = await axiosPrivate.post("/UpdateOtherServices.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(<Message type="success">{`Update successfully`}</Message>);
        handleOtherClose();
        getCustomerData();
      }

      if (result.status === 0) {
        toaster.push(<Message type="error">{result.Error}</Message>);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddBrokerServices = async () => {
    console.log(otherServiceData);
    const { agentid, agent, balance, cr, cr2, dr, remark, service, date } = brokerServiceData;
    if (agentid === undefined || agentid === null || agentid === 0) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please select broker first`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (
      agentid === undefined ||
      agentid === null ||
      agentid === 0 ||
      balance === undefined ||
      balance === null ||
      balance === 0 ||
      cr === undefined ||
      cr === null ||
      cr === 0 ||
      dr === undefined ||
      dr === null ||
      dr === 0 ||
      service === undefined ||
      service === null ||
      service === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter all details.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        agentid,
        agent,
        balance,
        cr,
        cr2,
        dr,
        remark,
        service,
        date: moment(dateTime).format("YYYY-MM-DD"),
      };
      const response = await axiosPrivate.post("/AddBrokerServices.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleBrokerClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddExpense = async () => {
    const { amount, remark, service } = expenseData;

    if (
      amount === undefined ||
      amount === null ||
      amount === 0 ||
      service === undefined ||
      service === null ||
      service === 0 ||
      dateTime === undefined ||
      dateTime === null ||
      service === undefined ||
      service === null ||
      service === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter all details.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        amount,
        remark,
        service,
        date: moment(dateTime).format("YYYY-MM-DD"),
      };
      const response = await axiosPrivate.post("/AddExpense.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        setExpenseID(result.ID);
        toaster.push(<Message type="success">Added successfully. Please upload bill copy</Message>);
      }

      if (result.status === 0) {
        toaster.push(<Message type="error">{result.Error}</Message>);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const AddIncome = async () => {
    console.log(otherServiceData);
    const { amount, remark, service } = incomeData;

    if (
      amount === undefined ||
      amount === null ||
      amount === 0 ||
      service === undefined ||
      service === null ||
      service === 0 ||
      dateTime === undefined ||
      dateTime === null ||
      service === undefined ||
      service === null ||
      service === 0
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter all details.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        amount,
        remark,
        service,
        date: moment(dateTime).format("YYYY-MM-DD"),
      };
      const response = await axiosPrivate.post("/AddIncome.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleIncomeClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemove = async (type, id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this record?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                    type,
                  };
                  const response = await axiosPrivate.post("/Remove.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    getSelfData();
                    toaster.push(<Message type="success">{result.Success}</Message>);
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  function getStatementinPDF() {
    toaster.push(<Message type="info">Work in Progress.</Message>);
    return;
    setshowHeader(true);
    const content = contentRef.current;
    // var logo = new Image();
    // logo.src = "http://logo.dahiyainsurance.com/Logo.png";
    html2canvas(content)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        // const Logo = canvas.toDataURL("https://www.dahiyainsurance.com/Logo.png");

        const pdf = new jsPDF();
        pdf.text(`Generated at : ${moment().format("DD-MM-YYYY h:mm:ss")}`, 10, 10);
        /*    pdf.addImage(logo, "PNG", 10, 10, 30, 0);
        pdf.text("Dahiya Insurance", 70, 30);
        pdf.text("Address: Rajashthan", 70, 40);
        pdf.text("Phone: +91 9930626171", 70, 50); */
        pdf.addImage(imgData, "PNG", 10, 15, 190, 0);
        pdf.save("exported.pdf");
        setshowHeader(false);
      })
      .catch((e) => {
        setshowHeader(false);
        toaster.push(<Message type="error">Error Occured</Message>);
      });
  }

  function getStatementinXLS() {
    toaster.push(<Message type="info">Work in Progress.</Message>);
    return;
    axiosPrivate
      .post("/GetDSOAccountInXLS.php", {
        DSO: selectedDSO,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 25 },
            { wch: 20 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 9 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 21 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `Report-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  /*   async function getNewStatementinPDF() {
    try {
      const response = await axiosPrivate.post("/GetSelfAccountBalanceInPDF.php");
      const result = response.data;

      const content = result;
      console.log(content);
      htmlStringToPdf(content);
      //const pdf = new jsPDF();
      //pdf.html(content);

      // pdf.save("exported.pdf");

            html2canvas(content)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const pdf = new jsPDF();
          pdf.html()
          pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
          pdf.save("exported.pdf");
        })
        .catch((e) => {
          toaster.push(<Message type="error">Error Occured</Message>);
          console.error(e);
        }); 
    } catch (err) {
      console.error(err);
    }
  }
  const htmlStringToPdf = async (htmlString) => {
    toaster.push(<Message type="info">Work in Progress.</Message>);
    return;
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;

    let canvas = await html2canvas(iframedoc.body, {});

    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/png");

    // Create a PDF document and add the image as a page.
    const doc = new jsPDF({ unit: "pt" });
    doc.addImage(imgData, "PNG", 10, 10, 190, 0);

    // Get the file as blob output.
    //let blob = doc.output("blob");
    doc.save("exported.pdf");

    // Remove the iframe from the document when the file is generated.
    document.body.removeChild(iframe);
  }; */
  /* ==============================DSO============================================ */

  const getDSOData = () => {
    axiosPrivate
      .post("/GetDSOAccount.php", {
        DSO: selectedDSO,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            // console.log(result.Data);
            setDSOPolicyData(result.AllData);
            setDSOTotal(result.Total);
            setDSOPrevious(result.Previous);
            setDSOBalance(result.Balance);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setDSOPolicyData([]);
          setDSOTotal({});
          setDSOPrevious(0);
          setDSOBalance(0);
        }
      })
      .catch((e) => {
        setDSOPolicyData([]);
        setDSOTotal({});
        setDSOPrevious(0);
        setDSOBalance(0);
      });
  };
  function getDSOStatementinPDF() {
    axiosPrivate
      .post(
        "GetDSOAccountInPDF.php",
        {
          DSO: selectedDSO,
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `DahiyaMitraAccount - ${paymentData.Name} -${moment(value[0]).format(
          "YYYY-MM-DD"
        )}-${moment(value[1]).format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getDSOStatementinXLS() {
    axiosPrivate
      .post("/GetDSOAccountInXLS.php", {
        DSO: selectedDSO,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 25 },
            { wch: 20 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 9 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 21 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          /*           `CustomerAccount - ${paymentData.Name} -${moment(value[0]).format(
            "YYYY-MM-DD"
          )}-${moment(value[1]).format("YYYY-MM-DD")}.pdf`; */
          XLSX.writeFile(
            wb,
            `DahiyaMitraAccount - ${paymentData.Name} -${moment(value[0]).format(
              "YYYY-MM-DD"
            )}-${moment(value[1]).format("YYYY-MM-DD")}.xlsx`
          );
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  /* ==============================Broker============================================ */
  const getBrokerData = () => {
    axiosPrivate
      .post("/GetBrokerAccount.php", {
        Broker: selectedBroker,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setBrokerBalance(result.Balance);
            setBrokerPolicyData(result.AllData);
            setBrokerPrevious(result.Previous);
            setbrokerTotal({
              Gross: result.Gross,
              Comm: result.Comm,
              Recieved: result.Recieved,
            });
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setBrokerPrevious({});
          setBrokerPolicyData([]);
          setbrokerTotal({});
        }
      })
      .catch((e) => {
        setBrokerPolicyData([]);
        setBrokerPrevious({});
        setbrokerTotal({});
      });
  };

  function getBrokerStatementinPDF() {
    axiosPrivate
      .post(
        "GetBrokerAccountInPDF.php",
        {
          Broker: selectedBroker,
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `BrokerAccount - ${paymentData.Name} -${moment(value[0]).format(
          "YYYY-MM-DD"
        )}-${moment(value[1]).format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getBrokerStatementinXLS() {
    axiosPrivate
      .post("/GetBrokerAccountInXLS.php", {
        Broker: selectedBroker,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Brokers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 25 },
            { wch: 10 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 9 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 21 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(
            wb,
            `BrokerAccount - ${paymentData.Name} -${moment(value[0]).format("YYYY-MM-DD")}-${moment(
              value[1]
            ).format("YYYY-MM-DD")}.xlsx`
          );
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No policy Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  /* ==============================Broker============================================ */

  /* ==============================Customer============================================ */
  const getCustomers = () => {
    axiosPrivate
      .post("/LoadCustomersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Fullname,
              value: item.UniqueID,
            }));
            // console.log(data);
            setCustomersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomersList([]);
        }
      })
      .catch((e) => {
        setCustomersList([]);
      });
  };

  const getCustomerData = () => {
    axiosPrivate
      .post("/GetCustomerAccount.php", {
        CustomerID: selectedCustomer,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setCustomerData(result.AllData);
            setCustomerTotal(result.CurrentBalance);
            setCustomerBalance(result.Balance);
            setCustomerPrevious(result.Previous);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomerData([]);
          setCustomerTotal({});
          setCustomerBalance({});
          setCustomerPrevious({});
        }
      })
      .catch((e) => {
        setCustomerData([]);
        setCustomerTotal({});
        setCustomerBalance({});
        setCustomerPrevious({});
      });
  };
  function getCustomerStatementinPDF() {
    // toaster.push(<Message type="info">Work in Progress.</Message>);
    // return;
    axiosPrivate
      .post(
        "GetCustomerAccountInPDF.php",
        {
          CustomerID: selectedCustomer,
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
        },
        { responseType: "blob" }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `CustomerAccount - ${paymentData.Name} -${moment(value[0]).format(
          "YYYY-MM-DD"
        )}-${moment(value[1]).format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function getCustomerStatementinXls() {
    axiosPrivate
      .post("/GetCustomerAccountInXLS.php", {
        CustomerID: selectedCustomer,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data, { raw: true });
          // Create a bold style
          const boldFirstRow = { bold: true };
          wb["!rows"] = [{}, boldFirstRow];
          XLSX.utils.book_append_sheet(wb, ws, "Customers");
          // Bold the first row (header row)
          ws["!cols"] = [
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
          ];

          const startCell = { r: 1, c: 3 }; // Replace with the starting row and column indices
          const endCell = { r: 100, c: 6 }; // Replace with the ending row and column indices

          // Iterate through the range and set the format for each cell
          for (let rowNum = startCell.r; rowNum <= endCell.r; rowNum++) {
            for (let colNum = startCell.c; colNum <= endCell.c; colNum++) {
              const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

              // Set the desired format for the cell
              if (ws[cellAddress]) {
                ws[cellAddress].z = "₹ #,##0.00;[Red]₹ -#,##0.00"; // Replace with your desired format, e.g., '0.00' for two decimal places
                ws[cellAddress].s = { font: { bold: true } };
              }
            }
          }

          XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });
          XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

          // Package and Release Data (`writeFile` tries to write and save an XLSB file)
          XLSX.writeFile(wb, `Report-${moment().format("DDMMYYYYhmmssa")}.xlsx`);
          XLSX.write(wb, { Props: { Author: "WebCRMPro" } });
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  /* ==============================/Customer============================================ */
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1} mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={1} mt={1} py={1} px={1} borderRadius="lg" coloredShadow="secondary">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Group controlId="radioList">
                      <RadioGroup
                        name="reportType"
                        inline
                        appearance="picker"
                        defaultValue="Customer"
                        value={type}
                        onChange={(e) => {
                          setType(e);
                          setPaymentData({});
                          setDescription("");
                          setSelectedBroker("");
                          setSelectedCustomer("");
                          setSelectedDSO("");
                          setCustomerData([]);
                          setBrokerPolicyData([]);
                          setDSOPolicyData([]);
                          setServiceProviderData([]);
                        }}
                      >
                        <Radio
                          value="Customer"
                          disabled={
                            !(
                              auth.permissions.includes(AC_CUSTOMER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Customer
                        </Radio>
                        &nbsp; &nbsp;
                        <Radio
                          value="Broker"
                          disabled={
                            !(
                              auth.permissions.includes(AC_BROKER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Broker
                        </Radio>
                        &nbsp; &nbsp;
                        <Radio
                          value="DahiyaMitra"
                          disabled={
                            !(
                              auth.permissions.includes(AC_MITRA) || auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          Dahiya Mitra
                        </Radio>
                        &nbsp;
                        <Radio
                          value="ServiceProvider"
                          disabled={
                            !(
                              auth.permissions.includes(AC_BROKER) ||
                              auth.user.Type === "SuperAdmin"
                            )
                          }
                        >
                          ServiceProvider
                        </Radio>
                        &nbsp;
                        <Radio
                          value="Self"
                          disabled={
                            !(auth.permissions.includes(AC_SELF) || auth.user.Type === "SuperAdmin")
                          }
                        >
                          Dahiya Insurance
                        </Radio>
                        &nbsp;
                        <Radio
                          value="Profit"
                          disabled={
                            !(auth.permissions.includes(AC_SELF) || auth.user.Type === "SuperAdmin")
                          }
                        >
                          Profit/Loss
                        </Radio>
                        &nbsp;
                      </RadioGroup>
                    </Form.Group>
                    <hr />
                    {type == "Customer" && (
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Stack spacing={4}>
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedCustomer}
                                onSelect={(e, Item) => {
                                  setSelectedCustomer(e);
                                  setOtherServiceData((a) => {
                                    return { ...a, CustomerID: e };
                                  });
                                  setPaymentData((a) => {
                                    return { ...a, Name: Item.label, ID: e };
                                  });
                                  console.log(Item);
                                }}
                                data={customersList}
                                width={300}
                                style={{ width: 300 }}
                                cleanable={false}
                              />

                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 220 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />

                              <IconButton
                                icon={<ReloadIcon />}
                                onClick={getCustomerData}
                                appearance="primary"
                              />

                              <Dropdown title="Export" icon={<PageIcon />}>
                                <Dropdown.Item
                                  onClick={getCustomerStatementinPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  onClick={getCustomerStatementinXls}
                                  icon={<PageIcon />}
                                >
                                  Export Excel
                                </Dropdown.Item> */}
                              </Dropdown>
                              {/* <Table2XLSX /> */}
                            </Stack>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Button onClick={handleOtherOpen} appearance="primary">
                            Services
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            onClick={() => {
                              setPaymentData((a) => {
                                return { ...a, PaymentID: null };
                              });
                              setDescription("");
                              setDateTime(new Date());
                              handleOpen();
                              setPaymentFileInfo(null);
                            }}
                            appearance="primary"
                          >
                            Payment
                          </Button>
                          &nbsp; &nbsp;
                          <b>Balance :</b>{" "}
                          <span>
                            {balanceAmnt(
                              customerBalance.DR - customerBalance.CR1 - customerBalance.CR2
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    )}
                    {type == "Broker" && (
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Stack spacing={4}>
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedBroker}
                                onSelect={(e, Item) => {
                                  setSelectedBroker(e);
                                  setPaymentData((a) => {
                                    return { ...a, Name: Item.label, ID: e };
                                  });
                                }}
                                data={brokerList}
                                width={300}
                                style={{ width: 300 }}
                                cleanable={false}
                              />

                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 220 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />

                              <IconButton
                                icon={<ReloadIcon />}
                                onClick={getBrokerData}
                                appearance="primary"
                              />

                              <Dropdown title="Export" icon={<PageIcon />}>
                                <Dropdown.Item
                                  onClick={getBrokerStatementinPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={getBrokerStatementinXLS}
                                  icon={<PageIcon />}
                                >
                                  Export Excel
                                </Dropdown.Item>
                              </Dropdown>
                            </Stack>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack>
                            <Button
                              onClick={() => {
                                setPaymentData((a) => {
                                  return { ...a, PaymentID: null };
                                });
                                setDescription("");
                                setDateTime(new Date());
                                handleOpen();
                                setPaymentFileInfo(null);
                              }}
                              appearance="primary"
                              color="red"
                            >
                              Payment
                            </Button>
                            &nbsp; &nbsp;
                            <b>{`Balance : `}</b> {balanceAmnt(brokerBalance)}
                            {/* {balanceAmnt(
                              brokerTotal.Comm -
                                brokerTotal.Recieved +
                                (brokerPrevious.Comm - brokerPrevious.Paid)
                            )} */}
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    {type == "ServiceProvider" && (
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            &nbsp;
                            <Stack spacing={4}>
                              <SelectPicker
                                block={true}
                                appearance="default"
                                value={selectedBroker}
                                onSelect={(e, Item) => {
                                  setSelectedBroker(e);
                                  setPaymentData((a) => {
                                    return { ...a, Name: Item.label, ID: e };
                                  });
                                }}
                                data={serviceProviderList}
                                width={250}
                                style={{ width: 250 }}
                                cleanable={false}
                              />

                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 250 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />

                              <Button onClick={getServiceProviderData} appearance="primary">
                                Load
                              </Button>
                              <Dropdown title="Export" icon={<PageIcon />}>
                                <Dropdown.Item
                                  onClick={getServiceProviderDatainPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={getServiceProviderDatainXls}
                                  icon={<PageIcon />}
                                >
                                  Export Excel
                                </Dropdown.Item>
                              </Dropdown>
                            </Stack>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() => {
                              setPaymentData((a) => {
                                return { ...a, PaymentID: null };
                              });
                              setDescription("");
                              setDateTime(new Date());
                              handleOpen();
                              setPaymentFileInfo(null);
                            }}
                            appearance="success"
                          >
                            Payment
                          </Button>
                          &nbsp; &nbsp;
                          <Button onClick={handleBrokerOpen} appearance="primary">
                            Services
                          </Button>
                          &nbsp; &nbsp;
                          <b>Balance :</b> {balanceAmntService(serviceProviderTotal?.Balance)}
                        </Grid>
                      </Grid>
                    )}
                    {type == "DahiyaMitra" && (
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Stack spacing={4}>
                            <SelectPicker
                              block={true}
                              appearance="default"
                              value={selectedDSO}
                              onSelect={(e, Item) => {
                                setSelectedDSO(e);
                                setPaymentData((a) => {
                                  return { ...a, Name: Item.label, ID: e };
                                });
                              }}
                              data={DSOList}
                              width={300}
                              style={{ width: 300 }}
                              cleanable={false}
                            />
                            <DateRangePicker
                              cleanable={false}
                              ranges={predefinedBottomRanges}
                              placeholder="Select date range"
                              style={{ width: 220 }}
                              value={value}
                              onChange={setValue}
                              showMeridian
                              format="dd-MM-yyyy"
                              defaultCalendarValue={[new Date(), new Date()]}
                            />
                            <IconButton
                              icon={<ReloadIcon />}
                              onClick={getDSOData}
                              appearance="primary"
                            />
                            <Dropdown title="Export" icon={<PageIcon />}>
                              <Dropdown.Item onClick={getDSOStatementinPDF} icon={<DetailIcon />}>
                                Export PDF
                              </Dropdown.Item>
                              <Dropdown.Item onClick={getDSOStatementinXLS} icon={<PageIcon />}>
                                Export Excel
                              </Dropdown.Item>
                            </Dropdown>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() => {
                              setPaymentData((a) => {
                                return { ...a, PaymentID: null };
                              });
                              setDescription("");
                              setDateTime(new Date());
                              handleOpen();
                              setPaymentFileInfo(null);
                            }}
                            appearance="primary"
                            color="red"
                          >
                            Payment
                          </Button>
                          &nbsp; &nbsp;
                          <b>Balance :</b> {balanceAmnt(parseFloat(dsoBalance) || 0)}
                        </Grid>
                      </Grid>
                    )}
                    {type == "Self" && (
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Stack spacing={4}>
                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 220 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />

                              <IconButton
                                icon={<ReloadIcon />}
                                onClick={getSelfData}
                                appearance="primary"
                              />
                              <Dropdown title="Export" icon={<PageIcon />}>
                                <Dropdown.Item
                                  disabled={true}
                                  onClick={getStatementinPDF}
                                  icon={<DetailIcon />}
                                >
                                  Export PDF
                                </Dropdown.Item>
                                <Dropdown.Item onClick={getSelfDatainXLS} icon={<PageIcon />}>
                                  Export Excel
                                </Dropdown.Item>
                              </Dropdown>
                              <Button onClick={handleIncomeOpen} appearance="primary" color="green">
                                Commissions
                              </Button>
                              <Button onClick={handleExpenseOpen} appearance="primary" color="red">
                                Add Expense
                              </Button>
                            </Stack>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          &nbsp; &nbsp;
                          <b>Profit :</b>{" "}
                          <span style={{ color: profit < 0 ? "red" : "green" }}>
                            {balanceAmnt(profit)}
                          </span>
                        </Grid>
                      </Grid>
                    )}
                    {type == "Profit" && (
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Stack spacing={4}>
                              <DateRangePicker
                                cleanable={false}
                                ranges={predefinedBottomRanges}
                                placeholder="Select date range"
                                style={{ width: 220 }}
                                value={value}
                                onChange={setValue}
                                showMeridian
                                format="dd-MM-yyyy"
                                defaultCalendarValue={[new Date(), new Date()]}
                              />

                              <IconButton
                                icon={<ReloadIcon />}
                                onClick={getProfitData}
                                appearance="primary"
                              />
                              <IconButton
                                icon={<FileDownload />}
                                onClick={getProfitDataInxls}
                                appearance="primary"
                              />
                            </Stack>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          &nbsp; &nbsp;
                          <b>Profit/Loss :</b>{" "}
                          <span style={{ color: profit < 0 ? "red" : "green" }}>
                            {balanceAmntService(
                              profitBalance.Comm - profitBalance.Spent - profitBalance.CR2
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} px={1}>
                {type === "Customer" && (
                  <div
                    style={{
                      height: 400,
                      flex: 1,
                      width: "100%",
                      overflowY: "scroll",
                      fontSize: "1rem",
                    }}
                  >
                    <table style={{ flex: 1 }} id="tabledata">
                      <thead>
                        <th width={300}>Particulars</th>
                        <th width={300}>Date</th>
                        <th width={200}>DR</th>
                        <th width={200}>CR1</th>
                        <th width={200}>CR2</th>
                        <th width={200}>Balance</th>
                        <th width={200}>Description</th>
                      </thead>

                      <tbody>
                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderBottom: "1px dotted black",
                            borderTop: "1px dotted black",
                          }}
                        >
                          <td width={200} colSpan={2}>
                            {`Balance as of ${moment(value[0])
                              .add(-1, "days")
                              .format("DD-MM-YYYY")}`}
                          </td>
                          <td colSpan={4} style={{ textAlign: "end" }}>
                            {balanceAmnt(
                              customerPrevious.DR - customerPrevious.CR1 - customerPrevious.CR2
                            )}
                          </td>
                        </tr>

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        {customerData.map((data) => (
                          <tr
                            key={data.UniqueID}
                            style={{ marginTop: 10, borderBottom: "dotted", borderWidth: 1 }}
                          >
                            <td width={300}>
                              {data.Type === "Policy" ? (
                                <Link to={`/policy/${data.ID}`}>{data.Particular}</Link>
                              ) : data.Type === "OtherServices" ? (
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    RetrieveOtherServices(data.ID);
                                  }}
                                >
                                  {data.Particular}
                                </a>
                              ) : data.Type === "Payments" ? (
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    RetrievePayment(data.ID);
                                  }}
                                >
                                  {data.Particular}
                                </a>
                              ) : (
                                data.Particular
                              )}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>{`${moment(data.Date).format(
                              "DD-MM-YYYY"
                            )}`}</td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.DR)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.CR1)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.CR2)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Balance)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {data.Note}
                            </td>
                            <td
                              width={50}
                              style={{ fontSize: 15, textAlign: "end", paddingRight: 10 }}
                            >
                              {data.Type !== "Policy" && !showHeader && (
                                <a
                                  href="#"
                                  onClick={() => handleRemove(data.Type, data.ID)}
                                  style={{ cursor: "pointer", color: "red", marginLeft: 5 }}
                                >
                                  {`X`}
                                </a>
                              )}
                            </td>
                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && (
                                <a
                                  href="#"
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (data?.Doc) {
                                      setimageLink(data.Doc);
                                      handleOpenImageView();
                                    } else setimageLink(null);
                                  }}
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderTop: "1px solid black",
                          }}
                        >
                          <td width={300} style={{ fontSize: 14, textAlign: "center" }} colSpan={2}>
                            <b>Total</b>
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(customerTotal?.DR)}
                          </td>
                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(customerTotal?.CR)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(customerTotal?.CR2)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {balanceAmnt(
                              customerPrevious.DR -
                                customerPrevious.CR1 -
                                customerPrevious.CR2 +
                                customerTotal.DR -
                                customerTotal.CR -
                                customerTotal.CR2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {type === "Broker" && (
                  <div
                    style={{
                      maxHeight: 500,
                      width: 1200,
                      fontSize: "1rem",
                      fontFamily: "poppins",
                      overflow: "auto",
                    }}
                  >
                    <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                      <thead>
                        <th width={300} style={{ background: "white", position: "sticky", top: 0 }}>
                          Policy
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Date
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Gross
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Commission
                        </th>
                        {/* <th width={200}>Paid</th> */}
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Received
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Description
                        </th>
                      </thead>
                      <tbody>
                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderBottom: "1px dotted black",
                            borderTop: "1px dotted black",
                          }}
                        >
                          <td width={200} colSpan={2}>
                            {`Balance as of ${moment(value[0])
                              .add(-1, "days")
                              .format("DD-MM-YYYY")}`}
                          </td>
                          <td colSpan={4} style={{ textAlign: "end" }}>
                            {balanceAmnt(brokerPrevious.Comm - brokerPrevious.Recieved)}
                          </td>
                        </tr>

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        {brokerPolicyData.map((data) => (
                          <tr
                            key={data.UniquID}
                            style={{ marginTop: 10, borderBottom: "dotted", borderWidth: 1 }}
                          >
                            <td width={300}>
                              {data.Type === "Policy" ? (
                                <Link to={`/policy/${data.ID}/BrokerAccount`}>
                                  {data.Particular}
                                </Link>
                              ) : data.Type === "Payments" ? (
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    RetrievePayment(data.ID);
                                  }}
                                >
                                  {data.Particular}
                                </a>
                              ) : (
                                data.Particular
                              )}
                            </td>
                            <td width={200}>{moment(data.Date).format("DD-MM-YYYY")}</td>
                            <td width={200}>{toAmnt(data.Gross)}</td>
                            <td width={200}>{toAmnt(data.Comm)}</td>
                            <td width={200}>{toAmnt(data.Recieved)}</td>
                            {/* <td width={200}>{toAmnt(data.Paid)}</td> */}
                            <td width={200}>{data.Note}</td>
                            <td
                              width={50}
                              style={{ fontSize: 15, textAlign: "end", paddingRight: 10 }}
                            >
                              {data.Type !== "Policy" && !showHeader && (
                                <a
                                  href="#"
                                  onClick={() => handleRemove(data.Type, data.ID)}
                                  style={{ cursor: "pointer", color: "red", marginLeft: 5 }}
                                >
                                  <DeleteForever />
                                </a>
                              )}
                            </td>
                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && data?.Doc && (
                                <a
                                  /* onClick={() => {
                                    if (data?.Doc) {
                                      setimageLink(data.Doc);
                                      handleOpenImageView();
                                    } else setimageLink(null);
                                  }} */
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Doc}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderTop: "1px solid black",
                          }}
                        >
                          <td width={200} colSpan={2} style={{ textAlign: "center" }}>
                            Total
                          </td>
                          <td width={200}>{toAmnt(brokerTotal.Gross)}</td>
                          <td width={200}>{toAmnt(brokerTotal.Comm)}</td>
                          <td width={200}>{toAmnt(brokerTotal.Recieved)}</td>
                          <td width={200}>
                            {balanceAmnt(
                              brokerTotal.Comm -
                                brokerTotal.Recieved +
                                (brokerPrevious.Comm - brokerPrevious.Recieved)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {type === "DahiyaMitra" && (
                  <div
                    style={{
                      maxHeight: 500,
                      width: 1200,
                      fontSize: "1rem",
                      fontFamily: "poppins",
                      overflow: "auto",
                    }}
                  >
                    <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                      <thead>
                        <th width={300} style={{ background: "white", position: "sticky", top: 0 }}>
                          Particulars
                        </th>
                        <th width={300} style={{ background: "white", position: "sticky", top: 0 }}>
                          Date
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          DR
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Received
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          CR2
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Balance
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Note
                        </th>
                      </thead>
                      <tr height={20}>
                        <td colSpan={5}></td>
                      </tr>
                      <tr
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          borderBottom: "1px dotted black",
                          borderTop: "1px dotted black",
                        }}
                      >
                        <td width={200} colSpan={2}>
                          {`Balance as of ${moment(value[0]).add(-1, "days").format("DD-MM-YYYY")}`}
                        </td>
                        <td colSpan={4} style={{ textAlign: "end" }}>
                          {balanceAmnt(dsoPrevious)}
                        </td>
                      </tr>

                      <tr height={20}>
                        <td colSpan={5}></td>
                      </tr>
                      <tbody>
                        {dsoPolicyData.map((data) => (
                          <tr
                            key={data.ID}
                            style={{ marginTop: 10, borderBottom: "dotted", borderWidth: 1 }}
                          >
                            <td width={300}>
                              {data.Type === "Policy" ? (
                                <Link to={`/policy/${data.ID}/DahiyaAccount`}>
                                  {data.Particular}
                                </Link>
                              ) : data.Type === "Payments" ? (
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    RetrievePayment(data.ID);
                                  }}
                                >
                                  {data.Particular}
                                </a>
                              ) : (
                                data.Particular
                              )}
                            </td>
                            <td width={200}>{moment(data.Date).format("DD-MM-YYYY")}</td>
                            <td width={200}>{toAmnt(data.DR)}</td>
                            <td width={200}>{toAmnt(data.CR1)}</td>
                            <td width={200}>{toAmnt(data.CR2)}</td>
                            <td width={200}>{toAmnt(data.Balance)}</td>
                            <td width={200}>{data.Note}</td>
                            <td
                              width={50}
                              style={{ fontSize: 15, textAlign: "end", paddingRight: 10 }}
                            >
                              {data.Type !== "Policy" && !showHeader && (
                                <a
                                  href="#"
                                  onClick={() => handleRemove(data.Type, data.ID)}
                                  style={{ cursor: "pointer", color: "red", marginLeft: 5 }}
                                >
                                  <DeleteForever />
                                </a>
                              )}
                            </td>
                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && data?.Doc && (
                                <a
                                  /* onClick={() => {
                                    if (data?.Doc) {
                                      setimageLink(data.Doc);
                                      handleOpenImageView();
                                    } else setimageLink(null);
                                  }} */
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Doc}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}

                        {/*                         {
                          <tr
                            style={{
                              paddingTop: 10,
                              borderTop: "1px solid grey",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <td width={200} colSpan={2}>
                              Total
                            </td>
                            <td width={200}>{toAmnt(DSOTotal.DR)}</td>
                            <td width={200}>{toAmnt(DSOTotal.CR1)}</td>
                            <td width={200}>{toAmnt(DSOTotal.CR2)}</td>
                            <td width={200}>{toAmnt(DSOTotal.Balance)}</td>
                          </tr>
                        } */}

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderTop: "1px solid black",
                          }}
                        >
                          <td width={200} colSpan={2}>
                            Total
                          </td>
                          <td width={200}>{toAmnt(DSOTotal.DR)}</td>
                          <td width={200}>{toAmnt(DSOTotal.CR1)}</td>
                          <td width={200}>{toAmnt(DSOTotal.CR2)}</td>
                          <td width={200}>
                            {balanceAmnt(
                              parseFloat(dsoPrevious) +
                                parseFloat(DSOTotal.DR - DSOTotal.CR1 - DSOTotal.CR2)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {type === "ServiceProvider" && (
                  <div
                    style={{
                      maxHeight: 500,
                      width: 1200,
                      fontSize: "1rem",
                      fontFamily: "poppins",
                      overflow: "auto",
                    }}
                  >
                    <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                      <thead>
                        <th width={300} style={{ background: "white", position: "sticky", top: 0 }}>
                          Particular
                        </th>
                        <th width={200}>Date</th>
                        <th width={200}>CR</th>
                        <th width={200}>DR</th>

                        <th width={200}>Description</th>
                      </thead>

                      <tbody>
                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderBottom: "1px dotted black",
                            borderTop: "1px dotted black",
                          }}
                        >
                          <td width={200} colSpan={2}>
                            {`Balance as of ${moment(value[0])
                              .add(-1, "days")
                              .format("DD-MM-YYYY")}`}
                          </td>
                          <td colSpan={4} style={{ textAlign: "end" }}>
                            {balanceAmntService(serviceProviderPrevious)}
                          </td>
                        </tr>

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        {serviceProviderData.map((data) => (
                          <tr
                            key={data.ID - data.Particular}
                            style={{ marginTop: 10, borderBottom: "dotted", borderWidth: 1 }}
                          >
                            <td width={300}>
                              {data.Type === "Payments" ? (
                                <a
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    RetrievePayment(data.ID);
                                  }}
                                >
                                  {data.Particular}
                                </a>
                              ) : (
                                data.Particular
                              )}
                            </td>
                            <td width={200}>{moment(data.Date).format("DD-MM-YYYY")}</td>
                            <td width={200}>{toAmnt(data.CR)}</td>
                            <td width={200}>{toAmnt(data.DR)}</td>

                            <td width={200}>{data.Note}</td>
                            <td
                              width={40}
                              style={{ fontSize: 15, textAlign: "end", paddingRight: 10 }}
                            >
                              <a
                                href="#"
                                onClick={() => handleRemove(data.Type, data.ID)}
                                style={{ cursor: "pointer", color: "red", marginLeft: 5 }}
                              >
                                <DeleteForever />
                              </a>
                            </td>
                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && data?.Doc && (
                                <a
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Doc}`}
                                  style={{ cursor: "pointer" }}
                                  rel="noreferrer"
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}

                        <tr height={20}>
                          <td colSpan={5}></td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            borderTop: "1px solid black",
                          }}
                        >
                          <td width={200} colSpan={2}>
                            Total
                          </td>
                          <td width={200}>{toAmnt(serviceProviderTotal.CR)}</td>
                          <td width={200}>{toAmnt(serviceProviderTotal.DR)}</td>

                          <td width={200}>
                            {balanceAmntService(
                              serviceProviderPrevious +
                                serviceProviderTotal.CR -
                                serviceProviderTotal.DR
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {type === "Self" && (
                  <div
                    style={{
                      maxHeight: 500,
                      width: 1200,
                      fontSize: "1rem",
                      fontFamily: "poppins",
                      overflow: "auto",
                    }}
                    id="data"
                  >
                    <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                      <thead
                        style={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <th width={350} style={{ background: "white", position: "sticky", top: 0 }}>
                          Particulars
                        </th>
                        <th width={250} style={{ background: "white", position: "sticky", top: 0 }}>
                          Date
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Gross
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Commission
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Received
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          CR2
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Expenses
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Paid
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Description
                        </th>
                      </thead>

                      <tbody style={{ overflowY: "auto", maxHeight: 400, flex: 1 }}>
                        {allData.map((data) => (
                          <tr
                            key={data.ID}
                            style={{
                              marginTop: 10,
                              borderBottom: "dotted",
                              borderWidth: 1,
                              height: 30,
                            }}
                          >
                            <td width={350} style={{ fontSize: 14 }}>
                              {data.Type === "PolicyData" ? (
                                <Link to={`/policy/${data.ID}`}>{data.Particulars}</Link>
                              ) : (
                                data.Particulars
                              )}
                            </td>
                            <td width={150} style={{ fontSize: 15 }}>{`${moment(data.Date).format(
                              "DD-MM-YYYY"
                            )}`}</td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Gross)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Comm)}
                            </td>

                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Recieved)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.CR2)}
                            </td>

                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Spent)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Paid)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {data.Note}
                            </td>
                            <td
                              width={200}
                              style={{ fontSize: 15, textAlign: "end", paddingRight: 10 }}
                            >
                              {data.Type !== "PolicyData" && !showHeader && (
                                <a
                                  href="#"
                                  onClick={() => handleRemove(data.Type, data.ID)}
                                  style={{ cursor: "pointer", color: "red", marginLeft: 5 }}
                                >
                                  <DeleteForever />
                                </a>
                              )}
                            </td>
                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && data?.Doc && (
                                <a
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Doc}`}
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                  rel="noreferrer"
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}

                        <tr style={{ marginTop: 15, borderTop: "solid", borderWidth: 1 }}>
                          <td width={300} style={{ fontSize: 14 }}>
                            <b>Total</b>
                          </td>
                          <td width={200} style={{ fontSize: 15 }}></td>
                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(gross)}
                          </td>
                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(comm)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(recieved)}
                          </td>
                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(CR2)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(spent)}
                          </td>
                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(paid)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {type === "Profit" && (
                  <div
                    style={{
                      maxHeight: 500,
                      width: 1200,
                      fontSize: "1rem",
                      fontFamily: "poppins",
                      overflow: "auto",
                    }}
                    id="data"
                  >
                    <table style={{ position: "relative", maxHeight: 400 }} id="tabledata">
                      <thead
                        style={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <th width={350} style={{ background: "white", position: "sticky", top: 0 }}>
                          Particulars
                        </th>
                        <th width={250} style={{ background: "white", position: "sticky", top: 0 }}>
                          Date
                        </th>

                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Commission
                        </th>

                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          Expenses
                        </th>
                        <th width={200} style={{ background: "white", position: "sticky", top: 0 }}>
                          CR2
                        </th>
                      </thead>

                      <tbody style={{ overflowY: "auto", maxHeight: 400, flex: 1 }}>
                        {allData.map((data) => (
                          <tr
                            key={data.ID}
                            style={{
                              marginTop: 10,
                              borderBottom: "dotted",
                              borderWidth: 1,
                              height: 30,
                            }}
                          >
                            <td width={350} style={{ fontSize: 14 }}>
                              {data.Type === "PolicyData" ? (
                                <Link to={`/policy/${data.ID}`}>{data.Particulars}</Link>
                              ) : (
                                data.Particulars
                              )}
                            </td>
                            <td width={150} style={{ fontSize: 15 }}>{`${moment(data.Date).format(
                              "DD-MM-YYYY"
                            )}`}</td>

                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Comm)}
                            </td>

                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.Spent)}
                            </td>
                            <td width={200} style={{ fontSize: 15 }}>
                              {toAmnt(data.CR2)}
                            </td>

                            <td width={40} style={{ alignItems: "center" }}>
                              {data.Type == "Payments" && data?.Doc && (
                                <a
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Doc}`}
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                  rel="noreferrer"
                                >
                                  <Icon fontSize="small">image</Icon>
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}

                        <tr style={{ marginTop: 15, borderTop: "solid", borderWidth: 1 }}>
                          <td width={300} style={{ fontSize: 14 }}>
                            <b>Total</b>
                          </td>
                          <td width={200} style={{ fontSize: 15 }}></td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(profitBalance.Comm)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(profitBalance.Spent)}
                          </td>

                          <td width={200} style={{ fontSize: 15 }}>
                            {toAmnt(profitBalance.CR2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ========================Add Payment=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontSize: 14 }} id="draggable-dialog-title">
          {`Update Payment for ${paymentData.Name}`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Payment Mode :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={paymentData.mode || ""}
                    data={[
                      { label: "Cash", value: "Cash" },
                      { label: "Bank", value: "Bank" },
                    ]}
                    onSelect={(e, Item) => {
                      setPaymentData((a) => {
                        return { ...a, mode: e };
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              {/*              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={paymentData?.Name || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    disabled
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid> */}
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={paymentData?.amount || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={handleAddPaymentChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Payment Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    oneTap
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="dd-MM-yyyy"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>

              <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                <Uploader
                  fileListVisible={false}
                  accept=".jpg,.png,.jpeg"
                  multiple={false}
                  listType="picture"
                  disabled={!paymentID}
                  action="https://Downloads.dahiyainsurance.com/AddPaymentPic.php"
                  withCredentials={true}
                  headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                  data={{ ID: paymentID }}
                  onUpload={(File) => {
                    setUploading(true);
                    previewFile(File.blobFile, (value) => {
                      setPaymentFileInfo(value);
                    });
                  }}
                  onSuccess={(response, file) => {
                    console.log(response);
                    setUploading(false);
                    toaster.push(<Message type="success">Uploaded successfully</Message>);

                    handleClose();
                  }}
                  onError={(e) => {
                    console.log(e);
                    setPaymentFileInfo(null);
                    setUploading(false);
                    toaster.push(<Message type="error">Upload failed</Message>);
                  }}
                  onRemove={(file) => {
                    console.log(file);
                  }}
                >
                  <button style={{ width: 200, height: 200 }}>
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                      <img
                        src={paymentFileInfo}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AvatarIcon style={{ fontSize: 80 }} />
                    )}
                  </button>
                </Uploader>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {paymentData.PaymentID ? (
            <Button onClick={UpdatePayment}>Update</Button>
          ) : (
            <Button onClick={AddPayment}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Other Services=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={otherOpen}
        onClose={handleOtherClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {`Other Services`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Service Provider :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={otherServiceData.agentid || ""}
                    data={serviceProviderList}
                    onSelect={(e, Item) => {
                      setOtherServiceData((a) => {
                        return { ...a, agentid: e, agent: Item.label };
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Services :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.service || ""}
                    name="service"
                    placeholder="Type service name"
                    id="service"
                    onChange={(e) => {
                      setOtherServiceData((a) => {
                        return { ...a, service: e.target.value };
                      });
                    }}
                    autoComplete="off"
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="dd-MM-yyyy"
                    value={dateTime}
                    oneTap
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Amount (DR) :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.dr || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setOtherServiceData((a) => {
                        return { ...a, dr: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Amount (CR) :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.cr || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setOtherServiceData((a) => {
                        return { ...a, cr: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Amount (CR2) :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.cr2 || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setOtherServiceData((a) => {
                        return { ...a, cr2: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Balance :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.balance || 0}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setOtherServiceData((a) => {
                        return { ...a, balance: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", display: "flex", paddingRight: 13 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={otherServiceData?.remark || ""}
                    name="remark"
                    placeholder="Type remark"
                    id="remark"
                    autoComplete="off"
                    onChange={(e) =>
                      setOtherServiceData((a) => {
                        return { ...a, remark: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleOtherClose}>
            Cancel
          </Button>
          {otherServiceData.ID ? (
            <Button onClick={UpdateOtherServices}>Update</Button>
          ) : (
            <Button onClick={AddOtherServices}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Expenses=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openExpenses}
        onClose={handleExpenseClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {`Add Expenses`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Service/Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={expenseData?.service || ""}
                    name="service"
                    disabled={expenseID}
                    placeholder="Type service name"
                    id="service"
                    onChange={(e) => {
                      setExpenseData((a) => {
                        return { ...a, service: e.target.value };
                      });
                    }}
                    autoComplete="off"
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={expenseID}
                      cleanable={false}
                      style={{ zIndex: 1000000 }}
                      format="dd-MM-yyyy"
                      value={dateTime}
                      oneTap
                      onChange={setDateTime}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={expenseData?.amount || ""}
                    disabled={expenseID}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setExpenseData((a) => {
                        return { ...a, amount: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={expenseData?.remark || ""}
                    name="remark"
                    disabled={expenseID}
                    placeholder="Type remark"
                    id="remark"
                    autoComplete="off"
                    onChange={(e) =>
                      setExpenseData((a) => {
                        return { ...a, remark: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                <Uploader
                  fileListVisible={false}
                  accept=".jpg,.png,.jpeg"
                  multiple={false}
                  listType="picture"
                  disabled={!expenseID}
                  action="https://Downloads.dahiyainsurance.com/AddBillPic.php"
                  withCredentials={true}
                  headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                  data={{ ID: expenseID }}
                  onUpload={(File) => {
                    setUploading(true);
                    previewFile(File.blobFile, (value) => {
                      setFileInfo(value);
                    });
                  }}
                  onSuccess={(response, file) => {
                    console.log(response);
                    setUploading(false);
                    toaster.push(<Message type="success">Uploaded successfully</Message>);

                    handleExpenseClose();
                  }}
                  onError={(e) => {
                    console.log(e);
                    setFileInfo(null);
                    setUploading(false);
                    toaster.push(<Message type="error">Upload failed</Message>);
                  }}
                  onRemove={(file) => {
                    console.log(file);
                  }}
                >
                  <button style={{ width: 200, height: 200 }}>
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                      <img
                        src={fileInfo}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AvatarIcon style={{ fontSize: 80 }} />
                    )}
                  </button>
                </Uploader>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleExpenseClose}>
            Cancel
          </Button>
          <Button onClick={AddExpense}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Incomes=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openIncome}
        onClose={handleIncomeClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {`Add Incomes`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Service/Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={incomeData?.service || ""}
                    name="service"
                    placeholder="Type service name"
                    id="service"
                    onChange={(e) => {
                      setIncomeData((a) => {
                        return { ...a, service: e.target.value };
                      });
                    }}
                    autoComplete="off"
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      cleanable={false}
                      style={{ zIndex: 1000000 }}
                      format="dd-MM-yyyy"
                      value={dateTime}
                      oneTap
                      onChange={setDateTime}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={incomeData?.amount || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setIncomeData((a) => {
                        return { ...a, amount: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={incomeData?.remark || ""}
                    name="remark"
                    placeholder="Type remark"
                    id="remark"
                    autoComplete="off"
                    onChange={(e) =>
                      setIncomeData((a) => {
                        return { ...a, remark: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleIncomeClose}>
            Cancel
          </Button>
          <Button onClick={AddIncome}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Broker Services=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={brokerOpen}
        onClose={handleBrokerClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {`Add Broker Services`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Broker :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={brokerServiceData.agentid || ""}
                    data={serviceProviderList}
                    onSelect={(e, Item) => {
                      setBrokerServiceData((a) => {
                        return { ...a, agentid: e, agent: Item.label };
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Services :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={brokerServiceData?.service || ""}
                    name="service"
                    placeholder="Type service name"
                    id="service"
                    onChange={(e) => {
                      setBrokerServiceData((a) => {
                        return { ...a, service: e.target.value };
                      });
                    }}
                    autoComplete="off"
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      cleanable={false}
                      style={{ zIndex: 1000000 }}
                      format="dd-MM-yyyy"
                      value={dateTime}
                      oneTap
                      onChange={setDateTime}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={brokerServiceData?.cr || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setBrokerServiceData((a) => {
                        return { ...a, cr: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Paid :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={brokerServiceData?.dr || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setBrokerServiceData((a) => {
                        return { ...a, dr: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Balance :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    disabled={true}
                    value={brokerServiceData?.balance || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={(e) =>
                      setBrokerServiceData((a) => {
                        return { ...a, balance: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={brokerServiceData?.remark || ""}
                    name="remark"
                    placeholder="Type remark"
                    id="remark"
                    autoComplete="off"
                    onChange={(e) =>
                      setBrokerServiceData((a) => {
                        return { ...a, remark: e.target.value };
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleBrokerClose}>
            Cancel
          </Button>
          <Button onClick={AddBrokerServices}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================View Image=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openImageView}
        onClose={handleCloseImageView}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        {/* <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {`Add Broker Services`}
        </DialogTitle> */}
        <DialogContent>
          <div style={{ width: "100%" }}>
            <img
              src={`https://Downloads.dahiyainsurance.com/assets/${imageLink}`}
              style={{ objectFit: "contain" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseImageView}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Payer Payment=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openPayer}
        onClose={handlePayerClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontSize: 14 }} id="draggable-dialog-title">
          {`Update Payment for ${payerPaymentData.Name}`}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    block={true}
                    appearance="default"
                    groupBy="type"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          return compare(a.groupTitle, b.groupTitle);
                        };
                      }

                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    value={payerPaymentData?.ID}
                    onSelect={(e, Item) => {
                      setPayerPaymentData((a) => {
                        return { ...a, Name: Item.label, ID: e, Type: Item.type };
                      });
                    }}
                    data={payersList}
                    width={400}
                    style={{ width: 400 }}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid
                  xs={5}
                  style={{ justifyContent: "flex-end", paddingRight: 20, display: "flex" }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Payment Mode :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    value={payerPaymentData.mode || ""}
                    data={[
                      { label: "Cash", value: "Cash" },
                      { label: "Bank", value: "Bank" },
                    ]}
                    onSelect={(e, Item) => {
                      setPayerPaymentData((a) => {
                        return { ...a, mode: e };
                      });
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={payerPaymentData?.amount || ""}
                    name="amount"
                    placeholder="Type amount"
                    id="amount"
                    autoComplete="off"
                    onChange={handleAddPayerPaymentChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Payment Date :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    oneTap
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="dd-MM-yyyy"
                    value={payerPaymentData.Date}
                    onChange={(e) =>
                      setPayerPaymentData((a) => {
                        return { ...a, Date: e };
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}
                >
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={payerPaymentData.description}
                    onChange={(e) => {
                      setPayerPaymentData((a) => {
                        return { ...a, description: e };
                      });
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>

              <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                <Uploader
                  fileListVisible={false}
                  accept=".jpg,.png,.jpeg"
                  multiple={false}
                  listType="picture"
                  disabled={!payerPaymentID}
                  /* autoUpload={false} */
                  action="https://Downloads.dahiyainsurance.com/AddPaymentPic.php"
                  withCredentials={true}
                  headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                  data={{ ID: payerPaymentID }}
                  onUpload={(File) => {
                    setUploading(true);
                    previewFile(File.blobFile, (value) => {
                      setPaymentFileInfo(value);
                    });
                  }}
                  onSuccess={(response, file) => {
                    console.log(response);
                    setUploading(false);
                    toaster.push(<Message type="success">Uploaded successfully</Message>);

                    handlePayerClose();
                  }}
                  onError={(e) => {
                    console.log(e);
                    setPaymentFileInfo(null);
                    setUploading(false);
                    toaster.push(<Message type="error">Upload failed</Message>);
                  }}
                  onRemove={(file) => {
                    console.log(file);
                  }}
                >
                  <button style={{ width: 200, height: 200 }}>
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                      <img
                        src={paymentFileInfo}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AvatarIcon style={{ fontSize: 80 }} />
                    )}
                  </button>
                </Uploader>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlePayerClose}>
            Cancel
          </Button>

          <Button onClick={AddPayerPayment}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default Accounting;
