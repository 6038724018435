import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { useToaster, Notification } from "rsuite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";

const data = ["Admin", "Staff", "DSO"].map((item) => ({ label: item, value: item }));
function Tables() {
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [usertype, setUsertype] = React.useState("");
  const axiosPrivate = useAxiosPrivate();
  const [addUserData, setAddUserData] = React.useState({});
  function handleAddUserChange(evt) {
    const value = evt.target.value;
    setAddUserData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.post("/ListUsers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setUsers(result.Data);
        }
        if (result.status === 0) {
          setUsers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListUsers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setUsers(result.Data);
      }
      if (result.status === 0) {
        setUsers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddUser = async () => {
    if (
      addUserData.firstname === "" ||
      addUserData.lastname === "" ||
      addUserData.username === "" ||
      addUserData.email === "" ||
      addUserData.mobile === "" ||
      usertype === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        firstname: addUserData.firstname,
        lastname: addUserData.lastname,
        username: addUserData.username,
        email: addUserData.email,
        mobile: addUserData.mobile,
        usertype: usertype,
      };
      const response = await axiosPrivate.post("/AddUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`User created successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
        setAddUserData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleChange = (event) => {
    setUsertype(event.target.value);
  };

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const DisableUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/EnableUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const ResetPassword = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/ResetPassword.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const UnlockUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/UnlockUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const EditUser = () => {};

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );

  const RemoveUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/RemoveUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const columns = React.useMemo(
    () => [
      { field: "Username", type: "string" },
      { field: "Name", type: "string", width: 200 },
      { field: "Email", type: "string", width: 200 },
      { field: "Mobile", type: "string", width: 180 },
      { field: "LastLogin", type: "string", width: 200 },
      { field: "UserType", type: "string", width: 100 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<SecurityIcon />}
            label="Enable/Disable"
            onClick={() => DisableUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockResetIcon />}
            label="Reset Password"
            onClick={() => ResetPassword(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockOpenIcon />}
            label="Unlock User"
            onClick={() => UnlockUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => EditUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove user"
            onClick={() => RemoveUser(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteUser, toggleAdmin, duplicateUser]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      Manage Users
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={handleOpen}
                        startIcon={<PersonAddIcon />}
                      >
                        Add User
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={handleRefresh}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        disabled
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid columns={columns} rows={users} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* ========================Add User=========================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add User
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addUserData?.firstname || ""}
                    name="firstname"
                    placeholder="Type first name"
                    id="firstname"
                    autoComplete="off"
                    onChange={handleAddUserChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Last Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addUserData?.lastname || ""}
                    name="lastname"
                    placeholder="Type last name"
                    id="lastname"
                    autoComplete="off"
                    onChange={handleAddUserChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Username :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addUserData?.username || ""}
                    name="username"
                    placeholder="Type user name"
                    id="username"
                    autoComplete="off"
                    onChange={handleAddUserChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addUserData?.email || ""}
                    name="email"
                    placeholder="Type email address"
                    id="email"
                    autoComplete="off"
                    onChange={handleAddUserChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addUserData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile number"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddUserChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    value={usertype}
                    onChange={handleChange}
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select User type</em>
                    </MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Staff">Staff</MenuItem>
                    <MenuItem value="DSO">DSO</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddUser}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default Tables;
