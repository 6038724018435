import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";

import { SelectPicker } from "rsuite";
import { useToaster, Notification } from "rsuite";
import { Uploader, Message, Loader, Drawer, Placeholder } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { useMaterialUIController } from "context";
import { Checkbox, CheckboxGroup } from "rsuite";
import { useParams } from "react-router-dom";
import { Settings } from "@mui/icons-material";

function EditUser() {
  const [size, setSize] = React.useState();

  const [placement, setPlacement] = React.useState("right");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState([]);
  const [controller] = useMaterialUIController();
  const { auth } = controller;

  const [editable, setEditable] = useState(true);

  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);

  const [editUserData, setEditUserData] = React.useState({});

  const axiosPrivate = useAxiosPrivate();

  const [rawdata, setrawdata] = useState({});

  const [userID, setUserID] = useState("");

  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const { ID } = useParams();
  function handleEditUserChange(evt) {
    const value = evt.target.value;
    setEditUserData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  React.useEffect(() => {
    let isMounted = true;
    setUserID(ID);
    const getUsersData = async () => {
      try {
        const response = await axiosPrivate.post("/GetUserData.php", { ID });
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setrawdata({ ID });
          setEditUserData(result.Data[0]);
          setValue(result.Role);
          if (result.Data[0].Picture)
            setFileInfo(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].Picture}`);
        }
        if (result.status === 0) {
          setEditUserData({});
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getUsersData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const EditSaveUser = async () => {
    // console.log(editCustomerData);

    const { FirstName, LastName, Mobile, Email, UserType, Enabled, userid } = editUserData;
    if (!FirstName || !LastName || !Mobile || !Email || !UserType || !Enabled || !userid) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        FirstName,
        LastName,
        Mobile,
        Email,
        UserType,
        Enabled,
        userid,
        Role: value,
        UniqueID: userID,
      };
      const response = await axiosPrivate.post("/EditUser.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`User updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    sm={6}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editUserData?.FirstName || ""}
                            name="FirstName"
                            placeholder="Type first name"
                            id="FirstName"
                            autoComplete="off"
                            onChange={handleEditUserChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>Last name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editUserData?.LastName || ""}
                            name="LastName"
                            placeholder="Type last name"
                            id="LastName"
                            autoComplete="off"
                            onChange={handleEditUserChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>User ID :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editUserData?.userid || ""}
                            name="userid"
                            placeholder="Enter user id"
                            id="userid"
                            autoComplete="off"
                            onChange={handleEditUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editUserData?.Mobile || ""}
                            name="Mobile"
                            placeholder="Type mobile"
                            id="Mobile"
                            autoComplete="off"
                            onChange={handleEditUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editUserData?.Email || ""}
                            name="Email"
                            placeholder="Type email"
                            id="Email"
                            autoComplete="off"
                            onChange={handleEditUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>Login Allowed? :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editUserData?.Enabled || ""}
                            onChange={(e, item) => {
                              setEditUserData((c) => {
                                return { ...c, Enabled: e };
                              });
                            }}
                            data={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid
                          xs={5}
                          style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}
                        >
                          <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editUserData?.UserType || ""}
                            onChange={(e, item) => {
                              setEditUserData((c) => {
                                return { ...c, UserType: e };
                              });
                            }}
                            data={[
                              { label: "Admin", value: "Admin" },
                              { label: "Chief Manager", value: "Chief Manager" },
                              { label: "Head Cashier", value: "Head Cashier" },
                              { label: "Field Manager", value: "Field Manager" },
                              { label: "Sales Manager", value: "Sales Manager" },
                              { label: "Account Manager", value: "Account Manager" },
                            ]}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {editable && (
                          <>
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={EditSaveUser}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={handleOpen}
                              startIcon={<Settings />}
                            >
                              Permissions
                            </Button>
                          </>
                        )}
                        &nbsp; &nbsp;
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={value}
                            onChange={(value) => {
                              setValue(value);
                            }}
                          >
                            <table>
                              <thead>
                                <tr>
                                  <th width={150}>Section</th>
                                  <th width={100}>Add</th>
                                  <th width={100}>Edit</th>
                                  <th width={100}>View</th>
                                  <th width={100}>Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Customer</td>
                                  <td>
                                    <Checkbox value="1502"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1504"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1503"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1505"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Policy</td>
                                  <td>
                                    <Checkbox value="1602"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1604"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1603"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="1605"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Event</td>
                                  <td>
                                    <Checkbox value="2100"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2102"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2101"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2103"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Broker</td>
                                  <td>
                                    <Checkbox value="2500"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2502"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2501"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2503"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>D Mitra</td>
                                  <td>
                                    <Checkbox value="2200"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2202"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2201"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2203"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Vehicle</td>
                                  <td>
                                    <Checkbox value="2300"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2302"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2301"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2303"></Checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Followup</td>
                                  <td>
                                    <Checkbox value="2100"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2102"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2101"></Checkbox>
                                  </td>
                                  <td>
                                    <Checkbox value="2103"></Checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </CheckboxGroup>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          Export:
                          <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={value}
                            onChange={(value) => {
                              setValue(value);
                            }}
                          >
                            <Checkbox value="1800">Customer</Checkbox>
                            <Checkbox value="1801">Broker</Checkbox>
                            <Checkbox value="1802">Agent</Checkbox>
                          </CheckboxGroup>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "flex-start",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "flex-start", display: "flex" }}>
                          <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={value}
                            onChange={(value) => {
                              setValue(value);
                            }}
                          >
                            <Checkbox value="1803">Policy</Checkbox>
                            <Checkbox value="1804">Event</Checkbox>
                            <Checkbox value="1805">Vehicle</Checkbox>
                          </CheckboxGroup>
                        </Grid>
                      </Grid>
                      

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          Account:
                          <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={value}
                            onChange={(value) => {
                              setValue(value);
                            }}
                          >
                            <Checkbox value="2401">Customer</Checkbox>
                            <Checkbox value="2402">Broker</Checkbox>
                            <Checkbox value="2403">Agent</Checkbox>
                            <Checkbox value="2404">Self</Checkbox>
                          </CheckboxGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  <Grid
                    sm={6}
                    md={6}
                    lg={6}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={!userID}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/AddAgentProfilePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={rawdata}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Drawer size="sm" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Manage Permissions</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={() => setOpen(false)} appearance="primary">
              Confirm
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Grid
            xs={12}
            container
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 10,
            }}
          >
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <CheckboxGroup
                    inline
                    name="checkboxList"
                    value={value}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  >
                    <table>
                      <thead>
                        <tr height={50}>
                          <th width={150}>Section</th>
                          <th width={100}>Add</th>
                          <th width={100}>Edit</th>
                          <th width={100}>View</th>
                          <th width={100}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr height={60}>
                          <td>Customer</td>
                          <td>
                            <Checkbox value="1502"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1504"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1503"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1505"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Policy</td>
                          <td>
                            <Checkbox value="1602"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1604"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1603"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="1605"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Event</td>
                          <td>
                            <Checkbox value="2100"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2102"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2101"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2103"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Broker</td>
                          <td>
                            <Checkbox value="2500"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2502"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2501"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2503"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>D Mitra</td>
                          <td>
                            <Checkbox value="2200"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2202"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2201"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2203"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Vehicle</td>
                          <td>
                            <Checkbox value="2300"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2302"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2301"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2303"></Checkbox>
                          </td>
                        </tr>
                        <tr height={60}>
                          <td>Followup</td>
                          <td>
                            <Checkbox value="2100"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2102"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2101"></Checkbox>
                          </td>
                          <td>
                            <Checkbox value="2103"></Checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CheckboxGroup>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <CheckboxGroup
                    style={{ width: "100%" }}
                    inline
                    name="checkboxList"
                    value={value}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>Export : </td>
                        <td>
                          <Checkbox value="1800">Customer</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1801">Broker</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1802">Agent</Checkbox>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Checkbox value="2505">AC_PAYOUT</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1803">Policy</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1804">Event</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="1805">Vehicle</Checkbox>
                        </td>
                      </tr>
                    </table>
                  </CheckboxGroup>
                </Grid>
              </Grid>

              {/* ==================================================================== */}

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <CheckboxGroup
                    style={{ width: "100%" }}
                    inline
                    name="checkboxList"
                    value={value}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>Account :</td>
                        <td>
                          <Checkbox value="2401">Customer</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="2402">Broker</Checkbox>
                        </td>
                        <td>
                          <Checkbox value="2403">Agent</Checkbox>
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td>
                          <Checkbox value="2404">Self</Checkbox>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </table>
                  </CheckboxGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default EditUser;
