import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import * as XLSX from "xlsx";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button } from "@mui/material";

import LockResetIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";

import { useToaster, Notification } from "rsuite";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import moment from "moment";
import { Outlet, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import {
  BackHand,
  Backspace,
  DeleteForever,
  ForkLeftSharp,
  PeopleOutline,
  SwipeLeftAltRounded,
} from "@mui/icons-material";
import { DELETE_CUSTOMER } from "Permissions";
import MatxSuspense from "components/MXSuspense";
import { ArrowLeftIcon } from "@mui/x-date-pickers";

function MainCustomer() {
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;

  const axiosPrivate = useAxiosPrivate();
  const [customers, setCustomers] = React.useState([]);

  const navigate = useNavigate();

  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={1}>
                    <Grid item xs={3} style={{ alignItems: "center" }}>
                      Manage Customers
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "end" }}>
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate(-1)}
                        startIcon={<ArrowLeftIcon />}
                      >
                        Back
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        disabled={
                          !(
                            auth.permissions.includes(DELETE_CUSTOMER) ||
                            auth.user.Type === "SuperAdmin"
                          )
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("DeletedCustomer")}
                        startIcon={<DeleteForever />}
                      >
                        Deleted Customer
                      </Button>{" "}
                      &nbsp; &nbsp;
                      <Button
                        disabled={
                          !(auth.permissions.includes(1502) || auth.user.Type === "SuperAdmin")
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("add")}
                        startIcon={<PersonAddIcon />}
                      >
                        Add
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("list")}
                        startIcon={<PeopleOutline />}
                      >
                        List
                      </Button>
                      &nbsp; &nbsp;
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MatxSuspense>
                  <Outlet />
                </MatxSuspense>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MainCustomer;
