import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { Uploader, Input, Stack, Notification } from "rsuite";

import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import { Delete, EditOffRounded, SkipNext, SkipPrevious } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UPDATE_CUSTOMER } from "Permissions";
import { DELETE_CUSTOMER } from "Permissions";

function ViewCustomer() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;

  const [customerdata, setcustomerdata] = React.useState({});
  const [fileInfo, setFileInfo] = React.useState(null);
  const [docs, setDocs] = React.useState([]);
  const { ID } = useParams();
  console.log(ID);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      getCustomersDataBySrNo();
    }
  };
  const getCustomersData = async () => {
    try {
      const response = await axiosPrivate.post("/GetCustomerData.php", { ID });
      const result = response.data;
      if (result.status === 1) {
        setcustomerdata(result.Data[0]);
        setDocs(result.Docs);
        if (result.Data[0].ProfilePic)
          setFileInfo(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].ProfilePic}`);
      }
      if (result.status === 0) {
        setcustomerdata([]);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getCustomersDataBySrNo = async () => {
    const matches = /\d+$/.exec(customerdata.SrNo);
    const numericValue = matches ? parseInt(matches[0]) : null;
    try {
      const response = await axiosPrivate.post("/GetCustomerDataBySrNo.php", { ID: numericValue });
      const result = response.data;
      if (result.status === 1) {
        setcustomerdata(result.Data[0]);
        setDocs(result.Docs);
        if (result.Data[0].ProfilePic)
          setFileInfo(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].ProfilePic}`);
      }
      if (result.status === 0) {
        setcustomerdata([]);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // Get Customer Data on component mount
  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCustomersData();
    }

    return () => {
      isMounted = false;
    };
  }, [ID]);

  const RemoveCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this customer's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveCustomer.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const GridElement = ({ label, value }) => {
    return (
      /*       <Grid xs={12} container style={myStyle}>
        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
          <InputLabel style={{ fontWeight: "bold" }}>{`${label} :`}</InputLabel>
        </Grid>
        <Grid xs={7}>
          <InputLabel style={{ fontWeight: "bold" }}>{`${value}`}</InputLabel>
        </Grid>
      </Grid> */
      <>
        <td
          width={150}
          style={{
            paddingLeft: 15,
            backgroundColor: "#e1f1ff",
            paddingRight: 10,
            justifyContent: "flex-end",
          }}
        >
          <InputLabel style={{ fontWeight: "bold", color: "#000" }}>{`${label}`}</InputLabel>
        </td>
        <td
          width={250}
          style={{ backgroundColor: "#f3faff", paddingLeft: 10, wordWrap: "break-word" }}
        >
          <InputLabel
            style={{
              fontSize: 14,
              fontFamily: "Poppins",
              textTransform: "capitalize",
              color: "#111",
            }}
          >{`${value}`}</InputLabel>
        </td>
      </>
    );
  };
  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} style={{ padding: 25 }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <table
                    style={{
                      padding: 10,
                      border: "1px solid",
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    <thead height={50}>
                      <th
                        style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}
                        colSpan={6}
                      >
                        <Stack spacing={6} style={{ justifyContent: "center" }}>
                          <h3
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat",
                              textTransform: "capitalize",
                            }}
                          >
                            {`${customerdata.FullName} - `}

                            {/* <span style={{ fontSize: 15 }}>{`${customerdata.SrNo}`}</span>{" "} */}
                          </h3>{" "}
                          <Input
                            size="md"
                            value={customerdata.SrNo}
                            style={{
                              fontSize: 15,
                              backgroundColor: "#f3faff",
                              outline: "none",
                              outlineStyle: "0.5px solid #444",
                              border: "0.5px solid #444",
                              textAlign: "center",
                            }}
                            onChange={(a) => {
                              setcustomerdata((b) => {
                                return { ...b, SrNo: a };
                              });
                            }}
                            onKeyDown={handleEnterKeyPress}
                          />
                        </Stack>
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={4}></tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement
                          label={`Added By / On`}
                          value={
                            `${customerdata.AddedBy}/${moment(customerdata.AddedOn).format(
                              "DD-MM-YY HH:mm"
                            )}` || ""
                          }
                        />
                        <GridElement label={`Address`} value={customerdata.Address} />
                        <td
                          colSpan={2}
                          rowSpan={8}
                          style={{
                            paddingLeft: 20,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button style={{ width: 300, height: 300 }}>
                            {fileInfo ? (
                              <img
                                src={fileInfo}
                                width="100%"
                                height="100%"
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <AvatarIcon style={{ fontSize: 80 }} />
                            )}
                          </button>
                          &nbsp; &nbsp;
                          <Uploader
                            listType="picture-text"
                            /* fileList={fileList}
                          onChange={setfileList} */
                            multiple={true}
                            data={{ CustomerID: ID }}
                            withCredentials={true}
                            onSuccess={() => {
                              getCustomersData();
                            }}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            action="https://Downloads.dahiyainsurance.com/UploadDocs.php"
                            renderFileInfo={(file, _) => (
                              <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                            )}
                          />
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement label={`Ref By`} value={customerdata.RefBy} />
                        <GridElement label={`Village/City`} value={customerdata.village || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement
                          label={`Date of Birth`}
                          value={
                            customerdata.dob === "0000-00-00"
                              ? ""
                              : moment(customerdata.dob).format("DD-MM-YYYY")
                          }
                        />
                        <GridElement label={`Tehsil`} value={customerdata.tehsil || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        {/* <GridElement label={`Full name`} value={customerdata.FullName} /> */}
                        <GridElement label={`Caste`} value={customerdata.caste || ""} />
                        <GridElement label={`District`} value={customerdata.district || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement label={`Whats App No`} value={customerdata.Mobile2} />
                        <GridElement label={`State`} value={customerdata.state || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement label={`Profession`} value={customerdata.work || ""} />
                        <GridElement label={`PIN`} value={customerdata.pin || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement
                          label={`Anniversary`}
                          value={
                            customerdata.anniversary === "0000-00-00"
                              ? ""
                              : moment(customerdata.anniversary).format("DD-MM-YYYY")
                          }
                        />
                        <GridElement label={`LIC`} value={customerdata.lic || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <GridElement label={`Remark`} value={customerdata.remark || ""} />
                        <GridElement label={`LIC Status`} value={customerdata.licstatus || ""} />
                      </tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={4}></tr>
                      <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                        <td colSpan={2} style={{ paddingLeft: 10 }}>
                          {
                            <ul>
                              {docs.map((data) => (
                                <li key={data.UniqueID}>
                                  {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                                  <a
                                    rel="noreferrer"
                                    href={`https://Downloads.dahiyainsurance.com/assets/${data.Path}`}
                                    target="_blank"
                                    style={{ cursor: "pointer" }}
                                    /* onClick={(e) => getDocs(e, data.UniqueID)} */
                                  >
                                    {data.Name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          }
                        </td>
                        <td>
                          {customerdata.Previous && (
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                              onClick={() => navigate(`/customers/view/${customerdata.Previous}`)}
                              startIcon={<SkipPrevious />}
                            >
                              Previous
                            </Button>
                          )}
                        </td>
                        <td>
                          {customerdata.Next && (
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                              onClick={() => navigate(`/customers/view/${customerdata.Next}`)}
                              startIcon={<SkipNext />}
                            >
                              Next
                            </Button>
                          )}
                        </td>
                        <td>
                          <Button
                            disabled={
                              !(
                                auth.permissions.includes(UPDATE_CUSTOMER) ||
                                auth.user.Type === "SuperAdmin"
                              )
                            }
                            style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                            onClick={() => navigate(`/customers/edit/${ID}`)}
                            startIcon={<EditOffRounded />}
                          >
                            Edit
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            disabled={
                              !(
                                auth.permissions.includes(DELETE_CUSTOMER) ||
                                auth.user.Type === "SuperAdmin"
                              )
                            }
                            style={{ color: "white", backgroundColor: "#8B0000", margin: 10 }}
                            onClick={() => RemoveCustomer(ID)}
                            startIcon={<Delete />}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default React.memo(ViewCustomer);
