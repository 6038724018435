import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Paper from "@mui/material/Paper";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SaveIcon from "@mui/icons-material/Save";
// Overview page components
import Header from "layouts/profile/components/Header";
import { useMaterialUIController } from "context";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Button, useToaster, Notification } from "rsuite";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";
import Draggable from "react-draggable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function Profile() {
  const toaster = useToaster();
  const [controller, dispatch] = useMaterialUIController();
  const { auth, persist } = controller;
  const labels = [];
  const values = [];

  const [labels1, setlabels1] = React.useState([]);
  const [values1, setvalues1] = React.useState([]);
  const [info, setinfo] = React.useState([]);
  const [data, setData] = React.useState({});
  // Convert this form `objectKey` of the object key in to this `object key`
  const axiosPrivate = useAxiosPrivate();
  const [pass, setpass] = React.useState({});
  const [show, setShow] = React.useState(false);
  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  React.useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const response = await axiosPrivate.post("/GetProfileData.php");
        const result = response.data;
        if (result.status === 1) {
          setData(result.Data[0]);
          setinfo(result.Data[0]);
          Object.values(result?.Data[0]).forEach((el) => values.push(el));
          setvalues1(values);
          Object.keys(result?.Data[0]).forEach((el) => {
            if (el.match(/[A-Z\s]+/)) {
              const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
              const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

              labels.push(newElement);
            } else {
              labels.push(el);
            }
          });
          setlabels1(labels);
          if (result.Data[0].Picture)
            setFileInfo(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].Picture}`);
        }
        if (result.status === 0) {
          setData({});
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  /*   Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  }); */

  // Push the object values into the values array
  //  Object.values(info).forEach((el) => values.push(el));
  const handleChange = (evt) => {
    const value = evt.target.value;
    setData((prev) => ({ ...prev, [evt.target.name]: value }));
    setpass((prev) => ({ ...prev, [evt.target.name]: value }));
  };
  // Render the card info items
  const renderItems = labels1.map((label, key) => (
    <MDBox key={label} display="flex" py={2} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values1[key]}
      </MDTypography>
    </MDBox>
  ));

  async function ChangePassword() {
    if (pass.Password === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Enter your current password
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (pass.New === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Enter your new password
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (pass.New2 === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Confirm your new password
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (pass.New2 !== pass.New) {
      toaster.push(
        <Notification type="error" header="Error">
          Confirm password do not match.
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    const datatosend = {
      Password: pass.Password,
      New: pass.New,
      New2: pass.New2,
    };
    try {
      const response = await axiosPrivate.post("/ChangePassword.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        handleClose();
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result?.Error || `Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }

  async function Update() {
    if (data.FirstName === "") {
      toaster.push(
        <Notification type="error" header="Error">
          First name can not be blank.
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    if (data.LastName === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Last Name can not be blank.
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (data.Email === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Email can not be blank.
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (data.Mobile === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Mobile can not be blank.
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    const datatosend = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      Mobile: data.Mobile,
    };
    try {
      const response = await axiosPrivate.post("/EditProfile.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        handleClose();
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result?.Error || `Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {auth.user.Type === "SuperAdmin" ? (
              <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
                <Card sx={{ height: "100%", width: "100%" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                      Profile
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            value={data.FirstName || ""}
                            name="FirstName"
                            placeholder="Type first name"
                            id="FirstName"
                            autoComplete="off"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Last name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            value={data.LastName || ""}
                            name="LastName"
                            placeholder="Type last name"
                            id="LastName"
                            autoComplete="off"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Email :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            value={data.Email || ""}
                            name="Email"
                            placeholder="Type Email"
                            id="Email"
                            autoComplete="off"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Mobile :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            value={data.Mobile || ""}
                            name="Mobile"
                            placeholder="Type Mobile"
                            id="Mobile"
                            autoComplete="off"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Password :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <Button onClick={handleOpen}>Change Password</Button>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}></Grid>
                        <Grid xs={7}>
                          <Button appearance="primary" onClick={Update} startIcon={<SaveIcon />}>
                            Save Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
            ) : (
              <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
                <Card sx={{ height: "100%", width: "100%" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                      Profile
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>{renderItems}</MDBox>
                  </MDBox>
                </Card>
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
            )}
            <Grid item xs={12} xl={6}></Grid>
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
      </Header>

      {/* ========================Chane password=========================== */}

      <Dialog
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Old Password :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={pass?.Password || ""}
                    name="Password"
                    placeholder="Type current password"
                    id="Password"
                    autoComplete="off"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>New Password :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={pass.New || ""}
                    name="New"
                    placeholder="Type New Password"
                    id="new"
                    autoComplete="off"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Confirm :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={pass.New2 || ""}
                    name="New2"
                    placeholder="Type New Password"
                    id="New2"
                    autoComplete="off"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={ChangePassword}>Change</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default Profile;
