import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";

import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "rsuite";
import { DatePicker } from "rsuite";
import Select from "@mui/material/Select";
import { SelectPicker } from "rsuite";

import { useToaster, Notification } from "rsuite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { compare } from "functions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CalendarMonthSharp, Visibility } from "@mui/icons-material";
import { DELETE_CUSTOMER } from "Permissions";
import { READ_CUSTOMER } from "Permissions";
import { UPDATE_CUSTOMER } from "Permissions";
import { useMaterialUIController } from "context";

const data = ["Admin", "Staff", "DSO"].map((item) => ({ label: item, value: item }));

function ListCustomer() {
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [openfollow, setOpenFollow] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [type, setType] = React.useState("");
  const [customers, setCustomers] = React.useState([]);
  const [addCustomerData, setAddCustomerData] = React.useState({});
  const [editCustomerData, setEditCustomerData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [assignedToID, setAssignedToID] = useState({});
  const [assignedTo, setAssignedTo] = useState({});
  //const usersList = useUsersList([]);
  //console.log(usersList);
  const navigate = useNavigate();
  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const [rowData, setRowData] = React.useState(null);

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const RemoveCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this customer's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveCustomer.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handlefollowClose = () => {
    setOpenFollow(false);
  };

  const handlefollowOpen = (data) => {
    setOpenFollow(true);
    setRowData(data.row);
    console.log(data.row);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (data) => {
    setOpenEdit(true);
    setRowData(data.row);
    console.log(data.row);

    setEditCustomerData({
      address: data.row.Address,
      email: data.row.Email,
      mobile: data.row.Mobile1,
      mobile2: data.row.Mobile2,
      fullname: data.row.FullName,
    });
  };

  const AddFollowup = () => {
    const datatosend = {
      CustomerID: rowData.id,
      UserID: assignedToID,
      FollowupOn: moment(dateTime).format(),
      Notes: description,
    };
    axiosPrivate
      .post("/AddFollowup.php", datatosend)
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success">
                {`Followup updated successfully`}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
            setOpenFollow(false);
            setRowData({});
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error">
                {result.Error}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
        }
        if (response && (response?.status === 401 || response?.status === 403)) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Error occured, please try again later.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      })
      .catch((e) => {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured, please try again later.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      });
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );
  const columns = React.useMemo(
    () => [
      { field: "SrNo", type: "string", width: 200, headerName: "CustomerID" },
      { field: "FullName", type: "string", width: 200 },
      { field: "Mobile", type: "string", width: 200 },
      { field: "village", type: "String", width: 200, headerName: "Village/City" },
      { field: "RefBy", type: "String", width: 200 },
      {
        field: "DOB",
        type: "Date",
        width: 200,
        headerName: "DOB",
        renderHeader: () => (
          <strong>
            {"Birthday "}
            <span role="img" aria-label="enjoy">
              🎂
            </span>
          </strong>
        ),
      },
      { field: "anniversary", type: "String", width: 200, headerName: "Anniversary" },
      { field: "Email", type: "String", width: 200, headerName: "Email" },
      { field: "caste", type: "String", width: 200, headerName: "Caste" },
      { field: "Address", type: "string", width: 180 },
      { field: "tehsil", type: "String", width: 200, headerName: "Tehsil" },
      { field: "district", type: "String", width: 200, headerName: "District" },
      { field: "state", type: "String", width: 200, headerName: "State" },
      { field: "pin", type: "String", width: 200, headerName: "PIN" },
      { field: "work", type: "String", width: 200, headerName: "Work" },
      { field: "OfficeVisited", type: "String", width: 200, headerName: "OfficeVisited" },
      { field: "AddedBy", type: "string", width: 200 },
      { field: "AddedOn", type: "Date", width: 200 },
      { field: "Deleted", type: "String", width: 200, headerName: "Deleted" },
      {
        field: "actions",
        type: "actions",
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<CalendarMonthSharp />}
            label="Create Followup"
            onClick={() => handlefollowOpen(params)}
          />,

          <GridActionsCellItem
            disabled={
              !(auth.permissions.includes(READ_CUSTOMER) || auth.user.Type === "SuperAdmin")
            }
            key={params.id}
            icon={<Visibility />}
            label="View"
            onClick={() => navigate(`/customers/view/${params.id}`)}
          />,
        ],
      },
    ],
    [handlefollowOpen, handleEditOpen, RemoveCustomer]
  );

  return (
    <>
      <MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
                <div style={{ height: 600, maxWidth: 1200, overflow: "auto" }}>
                  <DataGrid
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    rows={customers}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns status and traderName, the other columns will remain visible
                          DOB: false,
                          anniversary: false,
                          Email: false,
                          caste: false,
                          address: false,
                          Address: false,
                          tehsil: false,
                          district: false,
                          state: false,
                          pin: false,
                          work: false,
                          OfficeVisited: false,
                          AddedBy: false,
                          AddedOn: false,
                          Deleted: false,
                        },
                      },
                    }}
                  />
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ========================Add Followup=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openfollow}
        onClose={handlefollowClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          New followups
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Followup by :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    data={usersList}
                    onSelect={(e, Item) => {
                      /* console.log(e);
                      console.log(Item); */
                      setAssignedTo(Item.label);
                      setAssignedToID(e);
                    }}
                    groupBy="role"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          return compare(a.groupTitle, b.groupTitle);
                        };
                      }

                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    style={{ flex: 1, width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.FullName || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    disabled
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.Mobile || ""}
                    disabled
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date / Time :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="yyyy-MM-dd HH:mm"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlefollowClose}>
            Cancel
          </Button>
          <Button onClick={AddFollowup}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </>
  );
}

export default ListCustomer;
