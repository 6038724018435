import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview({ data = [] }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Follow ups
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              0
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {data.map((dt) => {
          return (
            <TimelineItem
              key={dt.id}
              color={dt.color || "success"}
              icon={dt.icon || "notifications"}
              title={dt.title || "Payment collected"}
              dateTime={dt.dateTime || "22 DEC 7:20 PM"}
            />
          );
        })}
        {/* <TimelineItem
          color="success"
          icon="notifications"
          title="Payment collected"
          dateTime="22 DEC 7:20 PM"
        />
        <TimelineItem color="error" icon="inventory_2" title="New policy" dateTime="21 DEC 11 PM" />
        <TimelineItem
          color="info"
          icon="shopping_cart"
          title="Payments"
          dateTime="21 DEC 9:34 PM"
        />
        <TimelineItem
          color="warning"
          icon="payment"
          title="New cplan added"
          dateTime="20 DEC 2:20 AM"
        /> */}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
