import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as XLSX from "xlsx";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { compare } from "functions";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { useToaster, Notification } from "rsuite";
import { Drawer, ButtonToolbar, Placeholder } from "rsuite";
import { Input } from "rsuite";
import { DatePicker } from "rsuite";
import { SelectPicker } from "rsuite";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";

function Followup() {
  const [loading, setLoading] = React.useState(false);
  const toaster = useToaster();
  const [rows, setRows] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [followupsdata, setfollowupsData] = React.useState([]);
  const [openfollow, setOpenFollow] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [assignedToID, setAssignedToID] = React.useState({});
  const [assignedTo, setAssignedTo] = React.useState({});
  const [rowData, setRowData] = React.useState(null);
  const [editID, setEditID] = React.useState(null);
  const handlefollowClose = () => {
    setOpenFollow(false);
  };

  function handleFollowChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };
  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListFollowups.php");
      const result = response.data;
      if (result.status === 1) {
        setfollowupsData(result.Data);
      }
      if (result.status === 0) {
        setfollowupsData([]);
      }
    } catch (err) {
      console.error(err);

      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleRefresh();
      getUsers();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getFollowupDetails = (id) => {
    /* const datatosend = {
        CustomerID: editID,
        UserID: assignedToID,
        FollowupOn: moment(dateTime).format(),
        Notes: description,
      }; */
    axiosPrivate
      .post("/GetFollowup.php", { ID: id })
      .then((response) => {
        const result = response.data;
        if (response && response?.status === 200) {
          console.log(result.Data);
          if (result.status === 1) {
            setDescription(result.Data[0].Notes);
            setDateTime(new Date(result.Data[0].FollowupOn));
            setAssignedToID(result.Data[0].UserID);
            setRowData(result.Data[0]);
            setOpenFollow(true);
          }
          if (result.status === 0) {
            setRowData({});
          }
        }
        if (response && (response?.status === 401 || response?.status === 403)) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Error occured, please try again later.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      })
      .catch((e) => {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured, please try again later.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      });
  };

  const SaveEditFollowup = () => {
    const datatosend = {
      ID: editID,
      UserID: assignedToID,
      FollowupOn: moment(dateTime).format(),
      Notes: description,
    };
    axiosPrivate
      .post("/EditFollowup.php", datatosend)
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success">
                {`Followup updated successfully`}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
            setOpenFollow(false);
            setRowData({});
            handleRefresh();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error">
                {result.Error}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
        }
        if (response && (response?.status === 401 || response?.status === 403)) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Error occured, please try again later.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      })
      .catch((e) => {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured, please try again later.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      });
  };

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );
  const MarkAsDone = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to mark this followup as done.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post("/CompleteFollowup.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };
  const EditFollowup = (id) => {
    setEditID(id);
    getFollowupDetails(id);
  };
  function ExportFolloups() {
    setLoading(true);

    axiosPrivate
      .post("/ListFollowups.php")
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, `Followups`);
          XLSX.writeFile(wb, "Followups.xlsx");
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }
  const RemoveFollowup = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this followup.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post("/RemoveFollowup.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                    handleRefresh();
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const columns = React.useMemo(
    () => [
      { field: "Customer", type: "string", width: 200 },
      { field: "Notes", type: "string", width: 300 },
      { field: "FollowupOn", type: "string", width: 200 },
      { field: "Status", type: "string", width: 180 },
      { field: "User", type: "string", width: 200 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<SecurityIcon />}
            label="Mark as Done"
            onClick={() => MarkAsDone(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled
            key={params.id}
            icon={<LockResetIcon />}
            label="Reschedule"
            onClick={toggleAdmin(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockOpenIcon />}
            label="Edit"
            onClick={() => EditFollowup(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled
            key={params.id}
            icon={<EditIcon />}
            label="Assign To"
            onClick={() => EditFollowup(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove"
            onClick={() => RemoveFollowup(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteUser, toggleAdmin, EditFollowup]
  );
  //  const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();
  /* const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
*/

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      Followups
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        disabled
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => {}}
                        startIcon={<PersonAddIcon />}
                      >
                        New Followups
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={handleRefresh}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={ExportFolloups}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
                <DataGrid columns={columns} rows={followupsdata} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ========================Add Followup=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openfollow}
        onClose={handlefollowClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Edit followups
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Followup by :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    data={usersList}
                    defaultValue={rowData?.UserID}
                    cleanable={false}
                    onSelect={(e, Item) => {
                      /* console.log(e);
                      console.log(Item); */
                      setAssignedTo(Item.label);
                      setAssignedToID(e);
                    }}
                    style={{ flex: 1, width: "100%" }}
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date / Time :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="yyyy-MM-dd HH:mm"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlefollowClose}>
            Cancel
          </Button>
          <Button onClick={SaveEditFollowup}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default Followup;
