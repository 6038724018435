import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button } from "@mui/material";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Outlet, useNavigate } from "react-router-dom";
import { PeopleSharp } from "@mui/icons-material";

function MainUsers() {
  const navigate = useNavigate();
  /* const [customers, setCustomers] = React.useState([]);

  const axiosPrivate = useAxiosPrivate();

  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }; */

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users list
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={1}>
                    <Grid item xs={7} style={{ alignItems: "center" }}>
                      Manage Users
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("add")}
                        startIcon={<PersonAddIcon />}
                      >
                        Add User
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("list")}
                        startIcon={<PeopleSharp />}
                      >
                        List Users
                      </Button>
                      &nbsp; &nbsp;
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Outlet context={[]} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MainUsers;
