export const CREATE_CUSTOMER = 1502;
export const READ_CUSTOMER = 1503;
export const UPDATE_CUSTOMER = 1504;
export const DELETE_CUSTOMER = 1505;
export const CREATE_POLICY = 1602;
export const READ_POLICY = 1603;
export const UPDATE_POLICY = 1604;
export const DELETE_POLICY = 1605;
export const EXPORT_CUSTOMER = 1800;
export const EXPORT_BROKER = 1801;
export const EXPORT_MITRA = 1802;
export const EXPORT_POLICY = 1803;
export const EXPORT_DOB = 1804;
export const CREATE_USER = 1900;
export const READ_USER = 1901;
export const UPDATE_USER = 1902;
export const DELETE_USER = 1903;
export const CREATE_BROKER = 2000;
export const READ_BROKER = 2001;
export const UPDATE_BROKER = 2002;
export const DELETE_BROKER = 2003;
export const EVENT_CREATE = 2100;
export const EVENT_VIEW = 2101;
export const EVENT_EDIT = 2102;
export const EVENT_DELETE = 2103;
export const CREATE_MITRA = 2200;
export const VIEW_MITRA = 2201;
export const EDIT_MITRA = 2202;
export const DELETE_MITRA = 2203;
export const CREATE_VEHICLE = 2300;
export const VIEW_VEHICLE = 2301;
export const EDIT_VEHICLE = 2302;
export const DELETE_VEHICLE = 2303;
export const EXPORT_VEHICLE = 1805;
export const AC_CUSTOMER = 2401;
export const AC_BROKER = 2402;
export const AC_MITRA = 2403;
export const AC_SELF = 2404;
export const FOLLOW_CREATE = 2500;
export const FOLLOW_VIEW = 2501;
export const FOLLOW_EDIT = 2502;
export const FOLLOW_DELETE = 2503;
export const AC_PAYOUT = 2505;
