import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import MDTypography from "components/MDTypography";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import { DatePicker } from "rsuite";
import { SelectPicker } from "rsuite";
import { useToaster, Notification } from "rsuite";
import { createSvgIcon } from "@mui/material/utils";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

import Slide from "@mui/material/Slide";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { AttachEmail, AttachFile } from "@mui/icons-material";
import moment from "moment";
import { ValidateEmail } from "functions";
import { validateNumber } from "functions";

function AddAgent() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;

  const [editable, setEditable] = useState(true);
  const [userId, setuserId] = useState("");

  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [srNo, setSRNo] = useState("");

  const [type, setType] = React.useState("");
  const [addUserData, setAddUserData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();
  const [upload, setUpload] = useState(true);

  const [rawdata, setrawdata] = useState({});

  //const usersList = useUsersList([]);
  const navigate = useNavigate();

  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const [fileList, setfileList] = React.useState([]);

  function handleAddUserChange(evt) {
    const value = evt.target.value;
    setAddUserData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const refershallDropdown = () => {
    //getCasteList();
    //getVillageList();
    //getStateList();
    setuserId(null);
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refershallDropdown();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const AddUser = async () => {
    // console.log(addUserData);

    const {
      address,
      email,
      fullname,
      firmname,
      joiningdate,
      mobile,
      officeno,
      officialemail,
      remark,
      usertype,
      dob,
      anniversary,
    } = addUserData;
    if (!fullname || !mobile || !usertype) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    } else if (email && !ValidateEmail(email)) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter valid email address.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (officialemail && !ValidateEmail(officialemail)) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter valid official email address.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    if (mobile && !validateNumber(mobile)) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter valid mobile number.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        address,
        email,
        fullname,
        firmname,
        joiningdate,
        mobile,
        officeno,
        officialemail,
        remark,
        usertype,
        dob: dob ? moment(dob).format("YYYY-MM-DD") : null,
        anniversary: anniversary ? moment(anniversary).format("YYYY-MM-DD") : null,
      };
      const response = await axiosPrivate.post("/AddAgentDetails.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        setuserId(result.ID);
        setSRNo(result.SrNo);
        // console.log(result.ID);
        setrawdata({ userId: result.ID });
        setEditable(false);
        setUpload(true);
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
        // setAddUserData({});
        //setType("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                setUpload(false);
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10, alignItems: "flex-start" }}
                    >
                      {srNo && (
                        <Grid
                          xs={12}
                          container
                          style={{
                            alignItems: "center",
                            paddingLeft: 10,
                            paddingRight: 10,
                            marginBottom: 10,
                            height: 45,
                          }}
                        >
                          <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Serial ID :</InputLabel>
                          </Grid>
                          <Grid xs={7}>
                            <InputLabel style={{ fontWeight: "bold" }}>{`DIFS/${srNo}`}</InputLabel>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Joining Date :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              oneTap
                              disabled={!editable}
                              fullWidth
                              format="dd-MM-yyyy"
                              style={{ width: 150 }}
                              cleanable={false}
                              value={addUserData.joiningdate}
                              onChange={(e) =>
                                setAddUserData((a) => {
                                  return { ...a, joiningdate: e };
                                })
                              }
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.fullname || ""}
                            name="fullname"
                            placeholder="Type full name"
                            id="fullname"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Firm name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.firmname || ""}
                            name="firmname"
                            placeholder="Type firmname"
                            id="firmname"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.mobile || ""}
                            name="mobile"
                            placeholder="Type mobile"
                            id="mobile"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Office No :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.officeno || ""}
                            name="officeno"
                            placeholder="Type Office number"
                            id="officeno"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.address || ""}
                            name="address"
                            placeholder="Type address"
                            id="address"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                            multiline={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                      height: 45,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.email || ""}
                            name="email"
                            placeholder="Type email"
                            id="email"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>
                            Email ID Official :
                          </InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.officialemail || ""}
                            name="officialemail"
                            placeholder="Type official email"
                            id="officialemail"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      {/* ============================================================================ */}

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>DOB :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <DatePicker
                            oneTap
                            fullWidth
                            disabled={!editable}
                            format="dd-MM-yyyy"
                            style={{ width: 150 }}
                            cleanable={true}
                            value={addUserData.dob}
                            onChange={(e) =>
                              setAddUserData((a) => {
                                return { ...a, dob: e };
                              })
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Anniversary :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <DatePicker
                            oneTap
                            fullWidth
                            disabled={!editable}
                            format="dd-MM-yyyy"
                            style={{ width: 150 }}
                            cleanable={true}
                            value={addUserData.anniversary}
                            onChange={(e) =>
                              setAddUserData((a) => {
                                return { ...a, anniversary: e };
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                      {/* ============================================================================ */}

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.remark || ""}
                            name="remark"
                            placeholder="Type remark if any"
                            id="remark"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Type :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={addUserData?.usertype || ""}
                            onChange={(e, _) => {
                              setAddUserData((c) => {
                                return { ...c, usertype: e };
                              });
                            }}
                            data={[
                              { label: "Broker", value: "Broker" },
                              { label: "DahiyaMitra", value: "DahiyaMitra" },
                              { label: "ServiceProvider", value: "ServiceProvider" },
                            ]}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {editable && (
                          <Button
                            disabled={
                              !(auth.permissions.includes(2000) || auth.user.Type === "SuperAdmin")
                            }
                            style={{ color: "white", backgroundColor: "#2979ff" }}
                            onClick={AddUser}
                            startIcon={<SaveIcon />}
                          >
                            Save
                          </Button>
                        )}
                        &nbsp; &nbsp;
                        {userId && (
                          <Button
                            style={{ color: "white", backgroundColor: "#2979ff" }}
                            disableElevation
                            onClick={handleAttachment}
                            startIcon={<AttachFile />}
                          >
                            Done
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={!userId}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/AddProfilePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={rawdata}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Uploader
                          disabled={
                            !userId ||
                            !(auth.permissions.includes(2000) || auth.user.Type === "SuperAdmin")
                          }
                          listType="picture-text"
                          fileList={fileList}
                          multiple={true}
                          data={rawdata}
                          onChange={setfileList}
                          withCredentials={true}
                          headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                          action="https://Downloads.dahiyainsurance.com/UploadDocs.php"
                          renderFileInfo={(file, fileElement) => (
                            <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default AddAgent;
