import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import SecurityIcon from "@mui/icons-material/Security";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";

import Slide from "@mui/material/Slide";
import { useToaster, Notification } from "rsuite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const data = ["Admin", "Staff", "DSO"].map((item) => ({ label: item, value: item }));
function ListUsers() {
  const navigate = useNavigate();
  const toaster = useToaster();
  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const axiosPrivate = useAxiosPrivate();
  const [userId, setuserId] = React.useState("");
  const [pass, setpass] = React.useState("");
  const [show, setShow] = React.useState(false);
  const handleOpen = (id) => {
    setShow(true);
    setuserId(id);
  };

  const handleClose = () => {
    setShow(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.post("/ListUsers.php");
        const result = response.data;
        if (result.status === 1) {
          // console.log(result.Data);
          setUsers(result.Data);
        }
        if (result.status === 0) {
          setUsers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const DisableUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/EnableUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const ResetPassword = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/ResetPassword.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const UnlockUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/UnlockUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const EditUser = (ID) => {
    navigate(`/users/edit/${ID}`);
  };

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );

  const RemoveUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/RemoveUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  async function ChangePassword() {
    if (pass === "") {
      toaster.push(
        <Notification type="error" header="Error">
          Enter new password
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    const datatosend = {
      Password: pass,
      ID: userId,
    };
    try {
      const response = await axiosPrivate.post("/ChangePassword1.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        handleClose();
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result?.Error || `Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }

  const columns = React.useMemo(
    () => [
      { field: "Name", type: "string", width: 200 },
      { field: "Username", type: "string", width: 200 },
      { field: "Email", type: "string", width: 200 },
      { field: "Mobile", type: "string", width: 180 },
      { field: "LastLogin", type: "string", width: 200 },
      { field: "UserType", type: "string", width: 200 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<SecurityIcon />}
            label="Enable/Disable"
            onClick={() => DisableUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={params.UserType === "Admin"}
            key={params.id}
            icon={<LockResetIcon />}
            label="Change Password"
            onClick={() => handleOpen(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockOpenIcon />}
            label="Unlock User"
            onClick={() => UnlockUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => EditUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove user"
            onClick={() => RemoveUser(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteUser, toggleAdmin, duplicateUser]
  );

  return (
    <MDBox>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
              <DataGrid columns={columns} rows={users} />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      {/* ========================Change password=========================== */}

      <Dialog
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>New Password :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={pass || ""}
                    name="Password"
                    placeholder="Type New password"
                    id="Password"
                    autoComplete="off"
                    onChange={(e) => setpass(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={ChangePassword}>Change</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </MDBox>
  );
}

export default ListUsers;
