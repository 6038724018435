import Grid from "@mui/material/Grid";
import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import { confirmAlert } from "react-confirm-alert"; // Import
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import { MaskedInput, SelectPicker, Uploader, DatePicker } from "rsuite";
import moment from "moment";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { createSvgIcon } from "@mui/material/utils";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";

import Slide from "@mui/material/Slide";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useToaster, Notification } from "rsuite";
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE_POLICY } from "Permissions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  "Plus"
);

function EditPolicy() {
  const [controller, dispatch] = useMaterialUIController();
  const { auth, persist } = controller;
  const [fileList, setfileList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [brokerList, setBrokerList] = React.useState([]);
  const [customersList, setCustomersList] = React.useState([]);
  const [insuranceCoList, setInsuranceCoList] = React.useState([]);
  const [policytypeList, setPolicyTypeList] = React.useState([]);
  const [vehicletypeList, setVehicleTypeList] = React.useState([]);
  const toaster = useToaster();
  const [addCustomerData, setAddCustomerData] = React.useState({});
  const [policyData, setPolicyData] = React.useState({});
  const [villageList, setVillageList] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [policyDate, setPolicyDate] = React.useState(null);
  const [paymentDate, setPaymentDate] = React.useState(null);
  const [DSOlist, setDSOlist] = React.useState([]);
  const [customername, setcustomername] = React.useState("");
  const [docs, setDocs] = React.useState([]);
  const [type, setType] = React.useState("");
  const axiosPrivate = useAxiosPrivate();

  const [openAddCustomer, setOpenAddCustomer] = React.useState(false);
  const [casteList, setCasteList] = React.useState([]);
  const [addBrokerData, setAddBrokerData] = React.useState({});
  const [openAddBroker, setOpenAddBroker] = React.useState(false);
  const [policyID, setpolicyID] = React.useState(null);
  const [paidviaList, setPaidViaList] = React.useState([]);
  const [policyCategoryList, setPolicyCategoryList] = React.useState([]);
  const { ID } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    setpolicyID(ID);
    let isMounted = true;
    const getpolicyData = async () => {
      try {
        const response = await axiosPrivate.post("/GetPolicyData.php", { ID });
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setPolicyData(result.Data[0]);
          setPolicyDate(
            policyData?.policydate === undefined || policyData?.policydate === "0000-00-00"
              ? null
              : new Date(policyData?.policydate)
          );
          setStartDate(
            policyData?.startDate === undefined || policyData?.startDate === "0000-00-00"
              ? null
              : new Date(policyData?.startDate)
          );
          setSelectedDate(
            policyData?.policyenddate === undefined || policyData?.policyenddate === "0000-00-00"
              ? null
              : new Date(policyData?.policyenddate)
          );
          setDocs(result.Docs);
        }
        if (result.status === 0) {
          setPolicyData([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getpolicyData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleOpenAddBroker = () => {
    setOpenAddBroker(true);
  };

  const handleCloseAddBroker = () => {
    setOpenAddBroker(false);
  };

  const handleAddBrokerChange = (evt) => {
    const value = evt.target.value;
    setAddBrokerData((prev) => ({ ...prev, [evt.target.name]: value }));
  };

  const AddBroker = async () => {
    if (
      addBrokerData.fullname === "" ||
      addBrokerData.firmname === "" ||
      addBrokerData.email === "" ||
      addBrokerData.officialemail === "" ||
      addBrokerData.mobile === "" ||
      addBrokerData.officeno === "" ||
      addBrokerData.address === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname: addBrokerData.fullname,
        firmname: addBrokerData.firmname,
        email: addBrokerData.email,
        officialemail: addBrokerData.officialemail,
        mobile: addBrokerData.mobile,
        officeno: addBrokerData.officeno,
        address: addBrokerData.address,
        usertype: "Broker",
      };
      const response = await axiosPrivate.post("/AddAgentDetails.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        getUsersBroker();
        toaster.push(
          <Notification type="success" header="Success">
            {`Broker added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddBroker();
        setAddBrokerData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const [addDSOData, setAddDSOData] = React.useState({});
  const [openAddDSO, setOpenAddDSO] = React.useState(false);

  const handleOpenAddDSO = () => {
    setOpenAddDSO(true);
  };

  const handleCloseAddDSO = () => {
    setOpenAddDSO(false);
  };

  const handleAddDSOChange = (evt) => {
    const value = evt.target.value;
    setAddDSOData((prev) => ({ ...prev, [evt.target.name]: value }));
  };

  const addDSO = async () => {
    if (
      addDSOData.fullname === "" ||
      addDSOData.email === "" ||
      addDSOData.mobile === "" ||
      addDSOData.officeno === "" ||
      addDSOData.officialemail === "" ||
      addDSOData.firmname === "" ||
      addDSOData.address === "" ||
      addDSOData.remark === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname: addDSOData.fullname,
        firmname: addDSOData.firmname,

        email: addDSOData.email,
        officialemail: addDSOData.officialemail,
        mobile: addDSOData.mobile,
        officeno: addDSOData.officeno,
        address: addDSOData.address,
        usertype: "DahiyaMitra",
      };
      const response = await axiosPrivate.post("/AddAgentDetails.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        getUsersByType();
        toaster.push(
          <Notification type="success" header="Success">
            {`Dahiya Mitra added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddDSO();
        setAddDSOData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleOpenAddCustomer = () => {
    setOpenAddCustomer(true);
  };

  const handleCloseAddCustomer = () => {
    setOpenAddCustomer(false);
  };
  const [openAddInsuranceCo, setOpenAddInsuranceCo] = React.useState(false);

  const handleOpenAddInsuranceCo = () => {
    setOpenAddInsuranceCo(true);
  };

  const handleCloseAddInsuranceCo = () => {
    setOpenAddInsuranceCo(false);
  };

  const [openAddPolicyType, setOpenAddPolicyType] = React.useState(false);

  const handleOpenAddPolicyType = () => {
    setOpenAddPolicyType(true);
  };

  const handleCloseAddPolicyType = () => {
    setOpenAddPolicyType(false);
  };

  const [openAddVehicleType, setOpenAddVehicleType] = React.useState(false);

  const handleOpenAddVehicleType = () => {
    setOpenAddVehicleType(true);
  };

  const handleCloseAddVehicleType = () => {
    setOpenAddVehicleType(false);
  };

  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  function handleAddpolicydata(evt) {
    const value = evt.target.value;
    setPolicyData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  /*   const AddUser = async () => {
    if (
      addUserData.firstname === "" ||
      addUserData.lastname === "" ||
      addUserData.username === "" ||
      addUserData.email === "" ||
      addUserData.mobile === "" ||
      usertype === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        firstname: addUserData.firstname,
        lastname: addUserData.lastname,
        username: addUserData.username,
        email: addUserData.email,
        mobile: addUserData.mobile,
        usertype: usertype,
      };
      const response = await axiosPrivate.post("/AddUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`User created successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
        setAddUserData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleAddUserChange = (event) => {
    setUsertype(event.target.value);
  }; */

  const AddCustomer = async () => {
    const { fullname, address, mobile2, refby } = addCustomerData;
    if (fullname === "" || address === "" || mobile2 === "" || refby === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname,
        address,
        mobile2,
        refby,
      };
      const response = await axiosPrivate.post("/AddCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setOpenAddCustomer(false);
        setAddCustomerData({});
        setType("");
        getCustomers();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const getCasteList = () => {
    axiosPrivate
      .post("/LoadCasteList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setCasteList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCasteList([]);
        }
      })
      .catch((e) => {
        setCasteList([]);
      });
  };
  React.useEffect(() => {
    const { commpernet, commperod, commpertp, netpremium, odpremium, tppremium } = policyData;
    let isSubscribed = true;
    if (isSubscribed) {
      if (
        !isNaN(netpremium) &&
        !isNaN(odpremium) &&
        !isNaN(tppremium) &&
        !isNaN(commpernet) &&
        !isNaN(commperod) &&
        !isNaN(commpertp)
      ) {
        setPolicyData((a) => {
          return {
            ...a,
            commnetamount: (netpremium * commpernet) / 100,
            commod: (odpremium * commperod) / 100,
            commtp: (tppremium * commpertp) / 100,
            totalcomm:
              (netpremium * commpernet) / 100 +
              (odpremium * commperod) / 100 +
              (tppremium * commpertp) / 100,
          };
        });
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    policyData.netpremium,
    policyData.odpremium,
    policyData.tppremium,
    policyData.commpernet,
    policyData.commperod,
    policyData.commpertp,
  ]);

  React.useEffect(() => {
    let { grosspremium, CR1, CR2 } = policyData;
    console.log("grosspremium=>" + grosspremium);
    console.log("paymentmade=>" + CR1);
    console.log("discount=>" + CR2);
    if (CR1 === undefined || CR1 === null || isNaN(CR1)) {
      CR1 = 0;
    }
    if (CR2 === undefined || CR2 === null || isNaN(CR2)) {
      CR2 = 0;
    }
    let isSubscribed = true;
    if (isSubscribed) {
      if (!isNaN(grosspremium) && !isNaN(CR1) && !isNaN(CR2)) {
        setPolicyData((a) => {
          return {
            ...a,
            Balance: grosspremium - CR1 - CR2,
          };
        });
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [policyData.grosspremium, policyData.CR1, policyData.CR2]);

  const AddInsuranceCo = async () => {
    console.log(addCustomerData);
    const { insuranceco } = addCustomerData;
    if (!insuranceco) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        insuranceco,
      };
      const response = await axiosPrivate.post("/AddInsuranceCo.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Insurance Co added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddInsuranceCo(false);
        setAddCustomerData({});
        getInsuranceCo();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddPolicyType = async () => {
    const { policytype } = addCustomerData;
    if (policytype === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        policytype,
      };
      const response = await axiosPrivate.post("/AddPolicyType.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Policy Type added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddPolicyType(false);
        setAddCustomerData({});
        getPolicyType();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddVehicleType = async () => {
    const { vehicleType } = addCustomerData;
    if (vehicleType === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        vehicleType,
      };
      const response = await axiosPrivate.post("/AddVehicleType.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Vehicle Type added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddVehicleType(false);
        setAddCustomerData({});
        getVehicleType();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const getPaidVia = () => {
    axiosPrivate
      .post("/LoadPaidViaList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPaidViaList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPaidViaList([]);
        }
      })
      .catch((e) => {
        setPaidViaList([]);
      });
  };
  const getVillageList = () => {
    axiosPrivate
      .post("/LoadVillageList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVillageList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVillageList([]);
        }
      })
      .catch((e) => {
        setVillageList([]);
      });
  };
  const getPolicyCategoryList = () => {
    axiosPrivate
      .post("/LoadPolicyCategoryList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPolicyCategoryList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPolicyCategoryList([]);
        }
      })
      .catch((e) => {
        setPolicyCategoryList([]);
      });
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPaidVia();
      getCasteList();
      getVillageList();
      getPolicyCategoryList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const EditPolicyData = async () => {
    const {
      commnetamount,
      commod,
      commtp,
      commpernet,
      commperod,
      commpertp,
      grosspremium,
      insurancecompany,
      netpremium,
      odpremium,
      tppremium,
      policyno,
      policytype,
      totalcomm,
      vehiclemodel,
      vehicleregno,

      modelyear,
    } = policyData;

    if (
      commnetamount === "" ||
      commod === "" ||
      commtp === "" ||
      commpernet === "" ||
      commperod === "" ||
      commpertp === "" ||
      grosspremium === "" ||
      insurancecompany === "" ||
      netpremium === "" ||
      odpremium === "" ||
      policyno === "" ||
      policytype === "" ||
      totalcomm === "" ||
      tppremium === "" ||
      vehiclemodel === "" ||
      vehicleregno === "" ||
      modelyear === "" ||
      modelyear === undefined ||
      commnetamount === undefined ||
      commod === undefined ||
      commtp === undefined ||
      commpernet === undefined ||
      commperod === undefined ||
      commpertp === undefined ||
      grosspremium === undefined ||
      insurancecompany === undefined ||
      netpremium === undefined ||
      odpremium === undefined ||
      policyno === undefined ||
      policytype === undefined ||
      totalcomm === undefined ||
      tppremium === undefined ||
      vehiclemodel === undefined ||
      vehicleregno === undefined
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
    }
    if (policyID === null || policyID === undefined) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Error occured -`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        ...policyData,
        paymentDate: moment(policyData?.startDate).format("YYYY-MM-DD"),
        startDate: moment(policyData?.startDate).format("YYYY-MM-DD"),
        policyDate: moment(policyData?.policyDate).format("YYYY-MM-DD"),
        policyenddate: moment(policyData.policyenddate).format("YYYY-MM-DD"),
        ID: policyID,
      };
      //console.log(datatosend);

      const response = await axiosPrivate.post("/EditPolicy.php", datatosend);
      const result = response.data;
      //console.log(response);
      setpolicyID(result.PolicyID);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Policy updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        navigate("/insurance");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const getCustomers = () => {
    axiosPrivate
      .post("/LoadCustomersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: `${item.Fullname} (${item.SrNo})`,
              value: item.UniqueID,
            }));
            // console.log(data);
            setCustomersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomersList([]);
        }
      })
      .catch((e) => {
        setCustomersList([]);
      });
  };

  const getInsuranceCo = () => {
    axiosPrivate
      .post("/LoadInsuranceCompanyList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setInsuranceCoList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setInsuranceCoList([]);
        }
      })
      .catch((e) => {
        setInsuranceCoList([]);
      });
  };

  const getPolicyType = () => {
    axiosPrivate
      .post("/LoadPolicyTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPolicyTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPolicyTypeList([]);
        }
      })
      .catch((e) => {
        setPolicyTypeList([]);
      });
  };

  const getUsersByType = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "DahiyaMitra" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            const a = [{ label: "Self", value: "Self" }].concat(data);
            // console.log(data);
            setUsersList(a);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };
  const getUsersBroker = () => {
    axiosPrivate
      .post("/LoadAgentList.php", { Type: "Broker" })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
            }));
            // console.log(data);
            setBrokerList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setBrokerList([]);
        }
      })
      .catch((e) => {
        setBrokerList([]);
      });
  };

  const getVehicleType = () => {
    axiosPrivate
      .post("/LoadVehicleTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVehicleTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVehicleTypeList([]);
        }
      })
      .catch((e) => {
        setVehicleTypeList([]);
      });
  };
  const refershallDropdown = () => {
    getCustomers();
    getUsersBroker();
    getInsuranceCo();
    getPolicyType();
    getVehicleType();
    getUsersByType();
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refershallDropdown();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this policy's document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post("/RemovePolicyDoc.php", datatosend);
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID != ID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  function checkIfMobileNoExists() {
    if (!addCustomerData?.mobile2 || addCustomerData?.mobile2 === "") {
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExistForCustomer.php", { Mobile: addCustomerData?.mobile2 })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                /* toaster.push(
                  <Notification type="error" header="Error">
                    {`This Mobile no already resgistered with ${result.App.length} customer(s) - ${result.Data[0].SrNo}.`}
                  </Notification>,
                  { placement: "topEnd" },
                  { duration: 1000 }
                ); */
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already resgistered with ${result.App.length} customer(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            setAddCustomerData({});
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white">
                  Modify Policy
                </MDTypography>
                <div style={{ flex: 1 }}></div>
                <div>
                  <Button
                    style={{ color: "white", backgroundColor: "#2979ff" }}
                    onClick={EditPolicyData}
                    startIcon={<SaveIcon />}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </MDBox>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }} pt={3}>
              <Grid item xs={12} md={6} lg={4} pt={2}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Broker :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      block={true}
                      appearance="default"
                      name="broker"
                      placeholder={policyData?.brokername || ""}
                      value={policyData?.brokername || ""}
                      onSelect={(e, Item) => {
                        setPolicyData((a) => {
                          return { ...a, broker: e, brokername: Item.label };
                        });
                      }}
                      data={brokerList}
                      cleanable={false}
                    />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddBroker}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4} pt={2}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Dahiya Mitra :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      cleanable={false}
                      block={true}
                      appearance="default"
                      placeholder={policyData?.DSOName || ""}
                      value={policyData?.DSO || ""}
                      onSelect={(e, Item) => {
                        setPolicyData((a) => {
                          return { ...a, DSO: e, DSOName: Item.label };
                        });
                      }}
                      data={usersList}
                    />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddDSO}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {policyData.DSOName === "Self" && (
                <Grid item xs={12} md={6} lg={4} pt={2}>
                  <Grid
                    xs={12}
                    container
                    style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Grid xs={5} style={{ justifyContent: "center" }}>
                      <InputLabel style={{ fontWeight: "bold" }}>Customer Name :</InputLabel>
                    </Grid>
                    <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                      <SelectPicker
                        name="customername"
                        appearance="default"
                        placeholder={policyData?.customername || ""}
                        value={policyData?.customerid === undefined ? "" : policyData?.customerid}
                        onChange={(e, item) => {
                          setPolicyData((a) => {
                            return { ...a, customername: e, customerid: e };
                          });
                        }}
                        onSelect={(e, item) => {
                          setPolicyData((a) => {
                            return { ...a, customername: e, customerid: e };
                          });
                        }}
                        data={customersList}
                        cleanable={false}
                      />
                      <IconButton color="primary" aria-label="add " onClick={handleOpenAddCustomer}>
                        <PlusIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy No :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.policyno || ""}
                      name="policyno"
                      placeholder="Type policy no"
                      id="policyno"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy Date :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      editable={true}
                      format="dd-MM-yyyy"
                      value={
                        policyData?.policydate === undefined ||
                        policyData?.policydate === "0000-00-00"
                          ? null
                          : new Date(policyData?.policydate)
                      }
                      onChange={(e) => {
                        // setPolicyDate(e);
                        setPolicyData((a) => {
                          return { ...a, policydate: e };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy Start Date :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="dd-MM-yyyy"
                      value={
                        policyData?.startDate === undefined ||
                        policyData?.startDate === "0000-00-00"
                          ? null
                          : new Date(policyData?.startDate)
                      }
                      onChange={(e) => {
                        // setStartDate(e);
                        setPolicyData((a) => {
                          return { ...a, startDate: e };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy End Date :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="dd-MM-yyyy"
                      value={
                        policyData?.policyenddate === undefined ||
                        policyData?.policyenddate === "0000-00-00"
                          ? null
                          : new Date(policyData?.policyenddate)
                      }
                      onChange={(e) => {
                        // setSelectedDate(e);
                        setPolicyData((a) => {
                          return { ...a, policyenddate: e };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4} pt={2}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Insured Name :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.insuredname || ""}
                      name="insuredname"
                      placeholder="Type Insured name"
                      id="insuredname"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>{" "}
              {/* <Grid item xs={12} md={6} lg={4} pt={2}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Amount :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                     <TextField
                      fullWidth
                      startAdornment={<InputAdornment position="start">Rs. </InputAdornment>}
                    /> 
                    <FormControl>
                      <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                      <OutlinedInput
                        
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                        value={policyData?.amount || ""}
                        name="amount"
                        placeholder="Enter amount"
                        autoComplete="off"
                        onChange={handleAddpolicydata}
                        fullWidth
                        label="Amount"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Insurance Company :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      block={true}
                      appearance="default"
                      value={policyData.insurancecompany}
                      onChange={(e, item) => {
                        setPolicyData((c) => {
                          return { ...c, insurancecompany: e };
                        });
                      }}
                      data={insuranceCoList}
                      cleanable={false}
                      fullWidth
                    />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddInsuranceCo}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy Type :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      block={true}
                      appearance="default"
                      value={policyData.policytype}
                      onChange={(e, item) => {
                        setPolicyData((c) => ({ ...c, policytype: e }));
                      }}
                      data={policytypeList}
                      cleanable={false}
                    />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddPolicyType}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Vehicle Make/Model :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      block={true}
                      appearance="default"
                      value={policyData.vehiclemodel}
                      onChange={(e, item) => {
                        setPolicyData((prevState) => {
                          return { ...prevState, vehiclemodel: e };
                        });
                      }}
                      data={vehicletypeList}
                      cleanable={false}
                    />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddVehicleType}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Model Year :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <MaskedInput
                      value={policyData?.modelyear}
                      mask={[/\d/, /\d/, /\d/, /\d/]}
                      guide={true}
                      keepCharPositions={true}
                      placeholderChar="_"
                      placeholder="Type Model Year"
                      fullWidth
                      onChange={(e) => {
                        setPolicyData((dt) => {
                          return { ...dt, modelyear: e };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Vehicle Reg.No. :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    {/* <MaskedInput
                      value={policyData?.vehicleregno}
                      mask={[
                        /[A-Z]/,
                        /[A-Z]/,
                        "-",
                        /\d/,
                        /\d/,
                        "-",
                        /[A-Z]/,
                        /[A-Z]/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={true}
                      keepCharPositions={true}
                      placeholderChar="_"
                      placeholder="Type vehicle registration no"
                      fullWidth
                      onChange={(e) => {
                        setPolicyData((dt) => {
                          return { ...dt, vehicleregno: e };
                        });
                      }}
                    /> */}
                    <TextField
                      value={policyData?.vehicleregno || ""}
                      name="vehicleregno"
                      placeholder="Type Vehicle Reg No"
                      id="vehicleregno"
                      style={{ textTransform: "uppercase" }}
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>OD Premium (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.odpremium || ""}
                      name="odpremium"
                      placeholder="Type OD premium Amnt"
                      id="odpremium"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>TP Premium (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.tppremium || ""}
                      name="tppremium"
                      placeholder="Type TP Premium amnt"
                      id="tppremium"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Net Premium (₹):</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.netpremium || ""}
                      name="netpremium"
                      placeholder="Type Net premium"
                      id="newpremium"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Gross Premium (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.grosspremium || ""}
                      name="grosspremium"
                      placeholder="Enter gross premium amnt"
                      id="grosspremium"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm Net (%) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.commpernet || ""}
                      name="commpernet"
                      placeholder="Enter Commission % net"
                      id="commpernet"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm OD (%) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.commperod || ""}
                      name="commperod"
                      placeholder="Enter Commission % OD"
                      id="commperod"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm TP (%) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.commpertp || ""}
                      name="commpertp"
                      placeholder="Enter Commission % TP"
                      id="commpertp"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm. Net Amount (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      disabled
                      value={policyData?.commnetamount || ""}
                      name="commnetamount"
                      placeholder="Enter commission net amount"
                      id="commnetamount"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm. OD (₹):</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      disabled
                      value={policyData?.commod || ""}
                      name="commod"
                      placeholder="Enter Commission OD"
                      id="commod"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Comm. TP (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      disabled
                      value={policyData?.commtp || ""}
                      name="commtp"
                      placeholder="Enter Commission TP"
                      id="commtp"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Total Comm (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      disabled
                      value={policyData?.totalcomm || ""}
                      name="totalcomm"
                      placeholder="Enter total commission amount"
                      id="totalcomm"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>DR (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      /*  */
                      disabled
                      value={policyData.grosspremium}
                      name="payableamount"
                      id="payableamount"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>CR I (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.CR1 || 0}
                      name="CR1"
                      placeholder="Enter payment made"
                      id="CR1"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>CR II (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={policyData?.CR2 || 0}
                      name="CR2"
                      placeholder="Enter payment CR2"
                      id="CR2"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Balance (₹) :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      disabled
                      value={policyData?.Balance || 0}
                      name="balance"
                      placeholder="Enter payment balance"
                      id="balance"
                      autoComplete="off"
                      onChange={handleAddpolicydata}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Paid Via :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      block={true}
                      appearance="default"
                      placeholder={policyData.paidvia}
                      value={policyData.paidvia}
                      onChange={(e, item) => {
                        setPolicyData((prevState) => {
                          return { ...prevState, paidvia: e };
                        });
                      }}
                      data={paidviaList}
                      cleanable={false}
                    />
                    {/*  <IconButton
                      disabled={policyID}
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddPaidVia}
                    >
                      <PlusIcon />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid xs={5} style={{ justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy Category :</InputLabel>
                  </Grid>
                  <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                    <SelectPicker
                      placeholder={policyData.policycategory}
                      block={true}
                      appearance="default"
                      searchable={false}
                      value={policyData.policycategory}
                      onChange={(e, _) => {
                        setPolicyData((prevState) => {
                          return { ...prevState, policycategory: e };
                        });
                      }}
                      data={policyCategoryList}
                      cleanable={true}
                    />
                    {/* <IconButton
                      disabled={policyID}
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleOpenAddPaidVia}
                    >
                      <PlusIcon />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://Downloads.dahiyainsurance.com/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data.Name}
                          </a>
                          {(auth.permissions.includes(DELETE_POLICY) ||
                            auth.user.Type === "SuperAdmin") && (
                            <a
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveDoc(data.UniqueID)}
                            >
                              X
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid
                  xs={12}
                  container
                  style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                >
                  <Uploader
                    disabled={!policyID}
                    removable={false}
                    onRemove={(file) => {
                      console.log(file.name);
                    }}
                    listType="picture-text"
                    fileList={fileList}
                    multiple={true}
                    data={{ ID: policyID }}
                    onChange={setfileList}
                    withCredentials={true}
                    headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                    action="https://Downloads.dahiyainsurance.com/UploadPolicyDocs.php"
                    renderFileInfo={(file, fileElement) => (
                      <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      {/* ===========================Add Customer=============================== */}

      <Dialog
        open={openAddCustomer}
        onClose={handleCloseAddCustomer}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    onBlur={checkIfMobileNoExists}
                    value={addCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Caste :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  {/*                   <TextField
                    value={addCustomerData?.caste || ""}
                    name="caste"
                    placeholder="Type caste"
                    id="caste"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                    multiline={true}
                  /> */}
                  <SelectPicker
                    block={true}
                    appearance="default"
                    value={addCustomerData.caste}
                    onChange={(e, item) => {
                      setAddCustomerData((c) => {
                        return { ...c, caste: e };
                      });
                    }}
                    data={casteList}
                    style={{ flex: 1 }}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Village :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    block={true}
                    appearance="default"
                    value={addCustomerData.village}
                    onChange={(e, item) => {
                      setAddCustomerData((c) => {
                        return { ...c, village: e };
                      });
                    }}
                    data={villageList}
                    style={{ flex: 1 }}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddCustomer}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Insurance CO============================== */}

      <Dialog
        open={openAddInsuranceCo}
        onClose={handleCloseAddInsuranceCo}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Insurance Company
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ width: 400 }}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                pt={3}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid
                  xs={12}
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Insurance Company :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={addCustomerData?.insuranceco || ""}
                      name="insuranceco"
                      placeholder="Type Insurance Co"
                      id="insuranceco"
                      autoComplete="off"
                      onChange={handleAddCustomerChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddInsuranceCo}>
            Cancel
          </Button>
          <Button onClick={AddInsuranceCo}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* =============================Policy Type============================== */}

      <Dialog
        open={openAddPolicyType}
        onClose={handleCloseAddPolicyType}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Policy Type
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ width: 400 }}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                pt={3}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid
                  xs={12}
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Policy Type :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={addCustomerData?.policytype || ""}
                      name="policytype"
                      placeholder="Enter policy type"
                      id="policytype"
                      autoComplete="off"
                      onChange={handleAddCustomerChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddPolicyType}>
            Cancel
          </Button>
          <Button onClick={AddPolicyType}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* =============================Vehicle Type============================= */}

      <Dialog
        open={openAddVehicleType}
        onClose={handleCloseAddVehicleType}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Vehicle Type
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ width: 400 }}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                pt={3}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid
                  xs={12}
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Vehicle Type :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={addCustomerData?.vehicleType || ""}
                      name="vehicleType"
                      placeholder="Enter Vehicle Type"
                      id="vehicleType"
                      autoComplete="off"
                      onChange={handleAddCustomerChange}
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddVehicleType}>
            Cancel
          </Button>
          <Button onClick={AddVehicleType}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add DSO=========================== */}

      <Dialog
        open={openAddDSO}
        onClose={handleCloseAddDSO}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Dahiya Mitra
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.fullname || ""}
                    name="fullname"
                    placeholder="Type Full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Firm Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.firmname || ""}
                    name="firmname"
                    placeholder="Type Firm name"
                    id="firmname"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.address || ""}
                    name="address"
                    placeholder="Type Address"
                    id="address"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.email || ""}
                    name="email"
                    placeholder="Type email address"
                    id="email"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Official Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.officialemail || ""}
                    name="officialemail"
                    placeholder="Type official email address"
                    id="officialemail"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile number"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Office No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addDSOData?.officeno || ""}
                    name="officeno"
                    placeholder="Type Office no"
                    id="officeno"
                    autoComplete="off"
                    onChange={handleAddDSOChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                    disabled
                    defaultValue="DahiyaMitra"
                  >
                    <MenuItem value="">
                      <em>Select User type</em>
                    </MenuItem>
                    <MenuItem selected value="DahiyaMitra">
                      DahiyaMitra
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddDSO}>
            Cancel
          </Button>
          <Button onClick={addDSO}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Add Broker=========================== */}

      <Dialog
        open={openAddBroker}
        onClose={handleCloseAddBroker}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Broker
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Firm Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.firmname || ""}
                    name="firmname"
                    placeholder="Type firm name"
                    id="firmname"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.email || ""}
                    name="email"
                    placeholder="Type email address"
                    id="email"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Official Email :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.officialemail || ""}
                    name="officialemail"
                    placeholder="Type Official email"
                    id="officialemail"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile number"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Office No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.officeno || ""}
                    name="officeno"
                    placeholder="Type Office number"
                    id="officeno"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addBrokerData?.address || ""}
                    name="address"
                    placeholder="Type Address"
                    id="address"
                    autoComplete="off"
                    onChange={handleAddBrokerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    value="Broker"
                    name="brokertype"
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                    defaultValue="Broker"
                    disabled
                  >
                    <MenuItem value="">
                      <em>Select User type</em>
                    </MenuItem>
                    <MenuItem selected value="Broker">
                      Broker
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddBroker}>
            Cancel
          </Button>
          <Button onClick={AddBroker}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default EditPolicy;
