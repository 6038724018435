import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { useToaster, Notification } from "rsuite";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { GridToolbar } from "@mui/x-data-grid";

function ListMitra() {
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.post("/ListMitra.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setUsers(result.Data);
        }
        if (result.status === 0) {
          setUsers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );
  const navigate = useNavigate();
  const EditUser = (ID) => {
    navigate(`/agent/edit/${ID}`);
  };

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );

  const RemoveUser = async (id) => {
    try {
      const datatosend = {
        id,
      };
      const response = await axiosPrivate.post("/RemoveUser.php", datatosend);
      const result = response.data;
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {result.Success}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const columns = React.useMemo(
    () => [
      { field: "fullname", type: "string", width: 200, headerName: "Full Name" },
      { field: "firmname", type: "string", width: 200, headerName: "Firm Name" },
      { field: "Mobile", type: "string", width: 180, headerName: "Mobile No" },
      { field: "officeno", type: "string", width: 180, headerName: "Office No" },
      { field: "Email", type: "string", width: 180, headerName: "Email" },
      { field: "officialemail", type: "string", width: 180, headerName: "Official Email" },
      { field: "address", type: "string", width: 200, headerName: "Address" },
      { field: "UserType", type: "string", width: 100, headerName: "Type" },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2202) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => EditUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2202) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<RemoveRedEyeOutlined />}
            label="View"
            onClick={() => navigate(`/agent/view/${params.id}`)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2203) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove"
            onClick={() => RemoveUser(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteUser, toggleAdmin, duplicateUser]
  );

  return (
    <MDBox>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
              <div style={{ height: 600, width: "100%" }}>
                <DataGrid columns={columns} rows={users} slots={{ toolbar: GridToolbar }} />
              </div>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ListMitra;
