import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { Input } from "rsuite";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { randomCreatedDate, randomUpdatedDate } from "@mui/x-data-grid-generator";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { useToaster, Notification } from "rsuite";
import { Drawer, ButtonToolbar, Placeholder } from "rsuite";
import { DatePicker } from "rsuite";
import { SelectPicker } from "rsuite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useMaterialUIController } from "context";
const initialRows = [
  {
    id: 1,
    Username: "Damien",
    Name: "Damien",
    Email: "Damien@gmail.com",
    Mobile: "9898989898",
    lastLogin: randomUpdatedDate(),
    UserType: "Admin",
  },
  {
    id: 2,
    Username: "Nicolas",
    Name: "Nicolas",
    Email: "Nicolas@gmail.com",
    Mobile: "9898563232",
    lastLogin: randomUpdatedDate(),
    UserType: "User",
  },
];
const data = ["Admin", "Staff", "DO"].map((item) => ({ label: item, value: item }));
function AllFollowups() {
  const [openWithHeader, setOpenWithHeader] = React.useState(false);
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [usertype, setUsertype] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setUsertype(event.target.value);
  };

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );
  /*   const RemoveFollowup = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this followup.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveFollowup.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }; */

  const RemoveFollowup = React.useCallback(
    (id) => () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{`Are you sure?`}</h1>
              <p>{`You want to delete this followup.?`}</p>
              <button onClick={onClose}>No</button>
              <button
                onClick={async () => {
                  try {
                    const datatosend = {
                      id,
                    };
                    const response = await axiosPrivate.post("/RemoveFollowup.php", datatosend);
                    const result = response.data;
                    if (result.status === 1) {
                      toaster.push(
                        <Notification type="success" header="Success">
                          {result.Success}
                        </Notification>,
                        { placement: "topEnd" },
                        { duration: 1000 }
                      );
                      onClose();
                    }

                    if (result.status === 0) {
                      toaster.push(
                        <Notification type="error" header="Error">
                          {`Error occured`}
                        </Notification>,
                        { placement: "topEnd" },
                        { duration: 1000 }
                      );
                    }
                  } catch (err) {
                    console.error(err);
                    //navigate("/login", { state: { from: location }, replace: true });
                  }
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          );
        },
      });
    },
    []
  );
  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );
  const EditFollowup = React.useCallback(
    (id) => () => {
      setOpenWithHeader(true);
      setEditID(id);
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );
  const MarkAsDone = React.useCallback(
    (id) => () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{`Are you sure?`}</h1>
              <p>{`You want to mark this followup as done.?`}</p>
              <button onClick={onClose}>No</button>
              <button
                onClick={async () => {
                  try {
                    const datatosend = {
                      id,
                    };
                    const response = await axiosPrivate.post("/CompleteFollowup.php", datatosend);
                    const result = response.data;
                    if (result.status === 1) {
                      toaster.push(
                        <Notification type="success" header="Success">
                          {result.Success}
                        </Notification>,
                        { placement: "topEnd" },
                        { duration: 1000 }
                      );
                      onClose();
                    }

                    if (result.status === 0) {
                      toaster.push(
                        <Notification type="error" header="Error">
                          {`Error occured`}
                        </Notification>,
                        { placement: "topEnd" },
                        { duration: 1000 }
                      );
                    }
                  } catch (err) {
                    console.error(err);
                    //navigate("/login", { state: { from: location }, replace: true });
                  }
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          );
        },
      });
    },
    []
  );

  const columns = React.useMemo(
    () => [
      { field: "Customer", type: "string" },
      { field: "Notes", type: "string", width: 200 },
      { field: "FollowupOn", type: "string", width: 200 },
      { field: "Status", type: "string", width: 180 },
      { field: "User", type: "string", width: 200 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2502) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<SecurityIcon />}
            label="Mark as Done"
            onClick={() => MarkAsDone(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled
            key={params.id}
            icon={<LockResetIcon />}
            label="Reschedule"
            onClick={toggleAdmin(params.id)}
            showInMenu={false}
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2502) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<LockOpenIcon />}
            label="Edit"
            onClick={() => EditFollowup(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled
            key={params.id}
            icon={<EditIcon />}
            label="Assign To"
            onClick={toggleAdmin(params.id)}
            showInMenu={false}
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2503) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove"
            onClick={() => RemoveFollowup(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [RemoveFollowup, toggleAdmin, duplicateUser, MarkAsDone]
  );
  //  const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();
  /* const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
*/
  React.useEffect(() => {
    setTimeout(() => {
      setRows(initialRows);
    }, 5000);
    return () => {};
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users list
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      New Followups
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        disabled={
                          !(auth.permissions.includes(2501) || auth.user.Type === "SuperAdmin")
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={handleOpen}
                        startIcon={<PersonAddIcon />}
                      >
                        New Followups
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                      {/*                       &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button> */}
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
                <DataGrid columns={columns} rows={rows} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* ========================Add Followup=========================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Last Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Username :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    value={usertype}
                    onChange={handleChange}
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select User type</em>
                    </MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Staff">Staff</MenuItem>
                    <MenuItem value="DSO">DSO</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
        <Drawer.Header>
          <Drawer.Title>Edit Followup</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
            <Button onClick={() => setOpenWithHeader(false)} appearance="primary">
              Confirm
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Followup by :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    data={usersList}
                    onSelect={(e, Item) => {
                      /* console.log(e);
                      console.log(Item); */
                      setAssignedTo(Item.label);
                      setAssignedToID(e);
                    }}
                    groupBy="role"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          return compare(a.groupTitle, b.groupTitle);
                        };
                      }

                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    style={{ flex: 1, width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.FullName || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    disabled
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.Mobile1 || ""}
                    disabled
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date / Time :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="yyyy-MM-dd HH:mm"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Drawer.Body>
      </Drawer>
    </DashboardLayout>
  );
}

export default AllFollowups;
