import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { randomCreatedDate, randomUpdatedDate } from "@mui/x-data-grid-generator";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const initialRows = [
  {
    id: 1,
    Username: "Damien",
    Name: "Damien",
    Email: "Damien@gmail.com",
    Mobile: "9898989898",
    lastLogin: randomUpdatedDate(),
    UserType: "Admin",
  },
  {
    id: 2,
    Username: "Nicolas",
    Name: "Nicolas",
    Email: "Nicolas@gmail.com",
    Mobile: "9898563232",
    lastLogin: randomUpdatedDate(),
    UserType: "User",
  },
];
const data = ["Admin", "Staff", "DO"].map((item) => ({ label: item, value: item }));
function NewFollowups() {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [usertype, setUsertype] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setUsertype(event.target.value);
  };

  const deleteUser = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );

  const columns = React.useMemo(
    () => [
      { field: "Username", type: "string" },
      { field: "Name", type: "string", width: 200 },
      { field: "Email", type: "string", width: 200 },
      { field: "Mobile", type: "string", width: 180 },
      { field: "lastLogin", type: "dateTime", width: 200 },
      { field: "UserType", type: "string", width: 100 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<SecurityIcon />}
            label="Disable"
            onClick={toggleAdmin(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockResetIcon />}
            label="Reset Password"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<LockOpenIcon />}
            label="Unlock User"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<PersonRemoveIcon />}
            label="Remove user"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [deleteUser, toggleAdmin, duplicateUser]
  );
  //  const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();
  /* const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
*/
  React.useEffect(() => {
    setTimeout(() => {
      setRows(initialRows);
    }, 5000);
    return () => {};
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users list
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      New Followups
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={handleOpen}
                        startIcon={<PersonAddIcon />}
                      >
                        New Followups
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
                <DataGrid columns={columns} rows={rows} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* ========================Add Followup=========================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Last Name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Username :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField fullWidth />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    value={usertype}
                    onChange={handleChange}
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select User type</em>
                    </MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Staff">Staff</MenuItem>
                    <MenuItem value="DSO">DSO</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </DashboardLayout>
  );
}

export default NewFollowups;
