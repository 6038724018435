import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import { DatePicker, Input } from "rsuite";
import { useToaster, Notification } from "rsuite";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectPicker } from "rsuite";
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Draggable from "react-draggable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { createSvgIcon } from "@mui/material/utils";
import { useMaterialUIController } from "context";
import { CREATE_VEHICLE } from "Permissions";
import { CREATE_CUSTOMER } from "Permissions";
const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  "Plus"
);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
function AddVehicle() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;
  const [open, setOpen] = React.useState(false);
  const [editable, setEditable] = useState(true);
  const [Id, setID] = useState("");
  const [fileList, setfileList] = React.useState([]);
  const toaster = useToaster();
  const [vehicletypeList, setVehicleTypeList] = React.useState([]);
  const [data, setData] = React.useState({});
  const [addCustomerData, setAddCustomerData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();
  const [upload, setUpload] = useState(true);
  const [rawdata, setrawdata] = useState({});

  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const [villageList, setVillageList] = React.useState([]);
  const [casteList, setCasteList] = React.useState([]);
  const [customersList, setCustomersList] = React.useState([]);

  const getVehicleData = async () => {
    try {
      const response = await axiosPrivate.post("/GetVehicleDate.php", { VehicleRegNo: data.RegNo });
      const result = response.data;
      if (result.status === 1) {
        setData((a) => {
          return {
            ...a,
            Insurance:
              result.Data[0]?.policyenddate === "0000-00-00"
                ? null
                : new Date(result.Data[0]?.policyenddate),
            Customer: result.Data[0]?.customername,
            Name: result.Data[0]?.vehiclemodel,
          };
        });
      }
      if (result.status === 0) {
        setData((a) => {
          return {
            ...a,
            Insurance: null,
            Customer: null,
            Name: null,
          };
        });
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const getCustomers = () => {
    axiosPrivate
      .post("/LoadCustomersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: `${item.Fullname} (${item.SrNo})`,
              value: item.UniqueID,
            }));
            // console.log(data);
            setCustomersList(data);
            // console.log(data);
            setCustomersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomersList([]);
        }
      })
      .catch((e) => {
        setCustomersList([]);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getCustomers();
      getVillageList();
      getCasteList();
      getVehicleType();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  const getVehicleType = () => {
    axiosPrivate
      .post("/LoadVehicleTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVehicleTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVehicleTypeList([]);
        }
      })
      .catch((e) => {
        setVehicleTypeList([]);
      });
  };
  const AddVehicle = async () => {
    const { Customer, CustomerName, RegNo } = data;
    if (!Customer || !CustomerName || !RegNo) {
      console.log(data);

      if (!Customer || !CustomerName) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Customer name is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!RegNo) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Registration no is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      return;
    }
    try {
      const response = await axiosPrivate.post("/AddVehicle.php", data);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        setID(result.ID);
        setrawdata({ ID: result.ID });
        setEditable(false);
        setUpload(true);
        toaster.push(
          <Notification type="success" header="Success">
            {`Vehicle added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  /*   const AddCustomer = async () => {
    const { fullname, address, mobile2, refby } = addCustomerData;
    if (fullname === "" || address === "" || mobile2 === "" || refby === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname,
        address,
        mobile2,
        refby,
      };
      const response = await axiosPrivate.post("/AddCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setOpenAddCustomer(false);
        setAddCustomerData({});
        setType("");
        getCustomers();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }; */
  const AddCustomer = async () => {
    const { fullname, address, mobile2, refby, village, caste } = addCustomerData;
    if (fullname === "" || village === "" || mobile2 === "" || refby === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname,
        address,
        mobile2,
        refby,
        village,
        caste,
      };
      const response = await axiosPrivate.post("/AddCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setOpenAddCustomer(false);
        setAddCustomerData({});
        setType("");
        getCustomers();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const getVillageList = () => {
    axiosPrivate
      .post("/LoadVillageList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVillageList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVillageList([]);
        }
      })
      .catch((e) => {
        setVillageList([]);
      });
  };

  const getCasteList = () => {
    axiosPrivate
      .post("/LoadCasteList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setCasteList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCasteList([]);
        }
      })
      .catch((e) => {
        setCasteList([]);
      });
  };
  function checkIfMobileNoExists() {
    if (!addCustomerData?.mobile2 || addCustomerData?.mobile2 === "") {
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExistForCustomer.php", { Mobile: addCustomerData?.mobile2 })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                /* toaster.push(
                  <Notification type="error" header="Error">
                    {`This Mobile no already resgistered with ${result.App.length} customer(s) - ${result.Data[0].SrNo}.`}
                  </Notification>,
                  { placement: "topEnd" },
                  { duration: 1000 }
                ); */
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already resgistered with ${result.App.length} customer(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            setAddCustomerData({});
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }
  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={1}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Reg No* :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <Input
                              value={data?.RegNo}
                              onBlur={getVehicleData}
                              fullWidth
                              disabled={Id}
                              name="RegNo"
                              placeholder="Vehicle registration no"
                              style={{
                                textTransform: "uppercase",
                                outlineWidth: 0.5,
                                outlineColor: "#333",
                                outlineStyle: "solid",
                              }}
                              id="vehicleregno"
                              onChange={(e) => {
                                setData((dt) => {
                                  return { ...dt, RegNo: e };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Customer * :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <SelectPicker
                              defaultValue={data?.Customer}
                              disabled={Id}
                              block={true}
                              appearance="default"
                              placeholder="Select customer"
                              value={data?.Customer}
                              onSelect={(e, item) =>
                                setData((a) => {
                                  return { ...a, Customer: e, CustomerName: item.label };
                                })
                              }
                              data={customersList}
                              cleanable={false}
                            />
                            {(auth.permissions.includes(CREATE_CUSTOMER) ||
                              auth.user.Type === "SuperAdmin") && (
                              <IconButton color="primary" aria-label="add " onClick={handleOpen}>
                                <PlusIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>
                              Vehicle Make/Model :
                            </InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <SelectPicker
                              disabled={Id}
                              value={data?.Name}
                              block={true}
                              appearance="default"
                              onChange={(e, item) => {
                                setData((dt) => {
                                  return { ...dt, Name: e };
                                });
                              }}
                              data={vehicletypeList}
                              cleanable={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>RC Renewal :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={Id}
                              format="dd-MM-yyyy"
                              placeholder="DD-MM-YYYY"
                              oneTap
                              value={data.RCRenewal}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, RCRenewal: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Insurance :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              disabled={Id}
                              format="dd-MM-yyyy"
                              placeholder="DD-MM-YYYY"
                              oneTap
                              value={data.Insurance}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Insurance: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>PUC :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              disabled={Id}
                              cleanable={true}
                              format="dd-MM-yyyy"
                              placeholder="DD-MM-YYYY"
                              oneTap
                              value={data.PUC}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, PUC: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Fitness :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={Id}
                              format="dd-MM-yyyy"
                              placeholder="DD-MM-YYYY"
                              oneTap
                              value={data?.Fitness}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Fitness: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel style={{ fontWeight: "bold" }}>Permit :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={Id}
                              format="dd-MM-yyyy"
                              placeholder="DD-MM-YYYY"
                              oneTap
                              value={data?.Permit}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Permit: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {editable && (
                          <>
                            <Button
                              disabled={
                                !(
                                  auth.permissions.includes(CREATE_VEHICLE) ||
                                  auth.user.Type === "SuperAdmin"
                                )
                              }
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={AddVehicle}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              disableElevation
                              onClick={() => {
                                setData({});
                                setID(null);
                                handleRefresh();
                              }}
                              startIcon={<RestartAltIcon />}
                            >
                              Reset
                            </Button>
                          </>
                        )}
                        &nbsp; &nbsp;
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={!Id}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/AddVehiclePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={rawdata}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Uploader
                          removable={upload}
                          disabled={!Id}
                          listType="picture-text"
                          fileList={fileList}
                          multiple={true}
                          data={rawdata}
                          onChange={setfileList}
                          withCredentials={true}
                          headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                          action="https://Downloads.dahiyainsurance.com/UploadVehicleDocs.php"
                          renderFileInfo={(file, fileElement) => (
                            <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* ===========================Add Customer=============================== */}

      {/*       <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>HN/Mohalla :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.address || ""}
                    name="address"
                    placeholder="Type address"
                    id="address"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Add New</Button>
        </DialogActions>
      </Dialog> */}
      {/* ====================================================================== */}
      {/* ===========================Add Customer=============================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    onBlur={checkIfMobileNoExists}
                    value={addCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Caste :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  {/*                   <TextField
                    value={addCustomerData?.caste || ""}
                    name="caste"
                    placeholder="Type caste"
                    id="caste"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                    multiline={true}
                  /> */}
                  <SelectPicker
                    block={true}
                    appearance="default"
                    value={addCustomerData.caste}
                    onChange={(e, item) => {
                      setAddCustomerData((c) => {
                        return { ...c, caste: e };
                      });
                    }}
                    data={casteList}
                    style={{ flex: 1 }}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Village :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    block={true}
                    appearance="default"
                    value={addCustomerData.village}
                    onChange={(e, item) => {
                      setAddCustomerData((c) => {
                        return { ...c, village: e };
                      });
                    }}
                    data={villageList}
                    style={{ flex: 1 }}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </>
  );
}

export default AddVehicle;
