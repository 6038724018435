// react-router components
import Main from "Main";
import Login from "layouts/login";
import { useState } from "react";
import { Routes, Route, Navigate, useLocation, Router } from "react-router-dom";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import "rsuite/dist/rsuite.min.css";
import Dashboard from "layouts/dashboard";
import PersistLogin from "components/PersistLogin";

import Insurance from "layouts/Insurance";
import NewEntry from "layouts/NewEntry";
import ListCustomer from "layouts/customers/ListCustomers";
import EditNewCustomer from "layouts/customers/EditCustomer";
import MainCustomer from "layouts/customers/Main";
import AddCustomer from "layouts/customers/AddCustomers";
import ViewCustomer from "layouts/customers/ViewCustomer";
import Followup from "layouts/Followup";
import Profile from "layouts/profile";
import ViewPolicy from "layouts/ViewPolicy";
import EditPolicy from "layouts/EditPolicy";
import Report from "layouts/Reports";
import Accounting from "layouts/accounting";
import MainAgents from "layouts/Agents/mainagents";
import Events from "layouts/Events";

import MainUsers from "layouts/users/main";
import AddUser from "layouts/users/adduser";
import ListUsers from "layouts/users/listusers";
import EditUser from "layouts/users/edituser";

import ListAgents from "layouts/Agents/listagents";
import AddAgent from "layouts/Agents/AddAgent";
import EditAgent from "layouts/Agents/EditAgent";
import ViewAgent from "layouts/Agents/ViewAgent";
import Vehicle from "layouts/vehicle";
import ListVehicle from "layouts/vehicle/listvehicle";
import AddVehicle from "layouts/vehicle/addvehicle";
import EditVehicle from "layouts/vehicle/editvehicle";
import ListMitra from "layouts/Agents/listmitra";
import MyExpenses from "layouts/MyExpenses";
import ListPayee from "layouts/KhataBook/ListPayee";
import Transactions from "layouts/KhataBook/Transactions";
import Inputs from "layouts/Inputs";

import UpdateInsurance from "layouts/Insurance/UpdateInsurance";
import DeletedInsurance from "layouts/Insurance/DeletedInsurance";
import DeletedCustomer from "layouts/customers/DeletedCustomer";
import DeletedVehicle from "layouts/vehicle/DeletedVehicle";
const ROLES = {
  STAFF: 1984,
  ADMIN: 5150,
};

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path="/" element={<Main />}>
              <Route exact path="dashboard" element={<Dashboard />} key="dashboard" />;
              <Route exact path="expenses" element={<MyExpenses />} key="mmanageexpenses" />;
              <Route exact path="listpayee" element={<ListPayee />} key="listpayee" />;
              <Route
                exact
                path="Transactions/:ID/:Name"
                element={<Transactions />}
                key="Transactions"
              />
              ;
              <Route exact path="users" element={<MainUsers />} key="users">
                <Route exact path="list" element={<ListUsers />} key="listusers" />;
                <Route exact path="add" element={<AddUser />} key="adduser" />;
                <Route exact path="edit/:ID" element={<EditUser />} key="edituser" />;
                <Route exact path="view/:ID" element={<ViewCustomer />} key="viewuser" />;
              </Route>
              <Route exact path="vehicle" element={<Vehicle />} key="vehicle">
                <Route exact path="list" element={<ListVehicle />} key="listvehicle" />;
                <Route
                  exact
                  path="deletedvehicle"
                  element={<DeletedVehicle />}
                  key="deletedvehicle"
                />
                ;
                <Route exact path="add" element={<AddVehicle />} key="addvehicle" />;
                <Route exact path="edit/:ID" element={<EditVehicle />} key="editvehicle" />;
              </Route>
              <Route exact path="agent" element={<MainAgents />} key="agent">
                <Route exact path="list" element={<ListAgents />} key="listagents" />;
                <Route exact path="mitra" element={<ListMitra />} key="listmitra" />;
                <Route exact path="add" element={<AddAgent />} key="addagent" />;
                <Route exact path="edit/:ID" element={<EditAgent />} key="editagent" />;
                <Route exact path="view/:ID" element={<ViewAgent />} key="viewagent" />;
              </Route>
              <Route exact path="addpolicy" element={<NewEntry />} key="addpolicy" />;
              <Route exact path="inputs" element={<Inputs />} key="inputs" />;
              <Route exact path="policy/:ID" element={<ViewPolicy />} key="viewpolicy" />;
              <Route exact path="policy/:ID/:Type" element={<ViewPolicy />} key="viewpolicy" />;
              <Route exact path="editpolicy/:ID" element={<EditPolicy />} key="editpolicy" />;
              <Route exact path="customers" element={<MainCustomer />} key="customers">
                <Route exact path="list" element={<ListCustomer />} key="listcustomer" />;
                <Route
                  exact
                  path="DeletedCustomer"
                  element={<DeletedCustomer />}
                  key="DeletedCustomer"
                />
                ;
                <Route exact path="add" element={<AddCustomer />} key="addcustomer" />;
                <Route exact path="edit/:ID" element={<EditNewCustomer />} key="editcustomer" />;
                <Route exact path="view/:ID" element={<ViewCustomer />} key="viewcustomer" />;
              </Route>
              <Route
                exact
                path="UpdateInsurance"
                element={<UpdateInsurance />}
                key="UpdateInsurance"
              />
              ;
              <Route
                exact
                path="DeletedInsurance"
                element={<DeletedInsurance />}
                key="DeletedInsurance"
              />
              ;
              <Route exact path="insurance" element={<Insurance />} key="insurance">
                <Route exact path="listpolicy" element={<NewEntry />} key="listpolicy" />;
                <Route exact path="addpolicy" element={<NewEntry />} key="addpolicy" />;
                <Route exact path="edit/:ID" element={<NewEntry />} key="editpolicy" />;
                <Route exact path="view/:ID" element={<NewEntry />} key="viewpolicy" />;
              </Route>
              <Route exact path="followup" element={<Followup />} key="insurance" />
              <Route exact path="report" element={<Report />} key="report" />
              <Route exact path="events" element={<Events />} key="events" />
              <Route exact path="accounting" element={<Accounting />} key="accounting" />
              <Route exact path="profile" element={<Profile />} key="profile" />
            </Route>
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}
