import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Paper from "@mui/material/Paper";

import { DatePicker } from "rsuite";
import { useToaster, Notification } from "rsuite";
import { createSvgIcon } from "@mui/material/utils";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

import Slide from "@mui/material/Slide";
import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import { MaskedInput, SelectPicker } from "rsuite";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  "Plus"
);

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { useMaterialUIController } from "context";
import { Link, useParams } from "react-router-dom";
import { Cancel, Edit } from "@mui/icons-material";
import { READ_CUSTOMER } from "Permissions";

function EditVehicle() {
  const { ID } = useParams();
  const [controller] = useMaterialUIController();
  const { auth } = controller;
  const [addCustomerData, setAddCustomerData] = React.useState({});
  const [editable, setEditable] = React.useState(true);
  const [Id, setID] = useState("");
  const [fileList, setfileList] = React.useState([]);
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const [data, setData] = React.useState({});

  const axiosPrivate = useAxiosPrivate();
  const [upload, setUpload] = useState(true);
  const [rawdata, setrawdata] = useState({});
  const [vehicletypeList, setVehicleTypeList] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );

  const [openAddCustomer, setOpenAddCustomer] = React.useState(false);
  const [customersList, setCustomersList] = React.useState([]);

  function handleChange(evt) {
    const value = evt.target.value;
    setData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  React.useEffect(() => {
    getVehicleType();
    let isMounted = true;
    const getVehicleData = async () => {
      setID(ID);
      try {
        const response = await axiosPrivate.post("/GetVehicleData.php", { ID });
        const result = response.data;
        if (result.status === 1) {
          setData({
            ...result.Data[0],

            Insurance:
              result.Data[0]?.Insurance === "0000-00-00"
                ? null
                : new Date(result.Data[0]?.Insurance),
            PUC: result.Data[0]?.PUC === "0000-00-00" ? null : new Date(result.Data[0]?.PUC),
            Permit:
              result.Data[0]?.Permit === "0000-00-00" ? null : new Date(result.Data[0]?.Permit),
            Fitness:
              result.Data[0]?.Fitness === "0000-00-00" ? null : new Date(result.Data[0]?.Fitness),
            RCRenewal:
              result.Data[0]?.RCRenewal === "0000-00-00"
                ? null
                : new Date(result.Data[0]?.RCRenewal),
          });
          console.log(data);
          setDocs(result.Docs);
          console.log(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].Picture}`);
          if (result.Data[0].Picture)
            setFileInfo(`https://Downloads.dahiyainsurance.com/profile/${result.Data[0].Picture}`);
        }
        if (result.status === 0) {
          setData([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getVehicleData();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const getVehicleType = () => {
    axiosPrivate
      .post("/LoadVehicleTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVehicleTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVehicleTypeList([]);
        }
      })
      .catch((e) => {
        setVehicleTypeList([]);
      });
  };
  const AddCustomer = async () => {
    const { fullname, address, mobile2, refby } = addCustomerData;
    if (fullname === "" || address === "" || mobile2 === "" || refby === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname,
        address,
        mobile2,
        refby,
      };
      const response = await axiosPrivate.post("/AddCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setOpenAddCustomer(false);
        setAddCustomerData({});
        setType("");
        getCustomers();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const getCustomers = () => {
    axiosPrivate
      .post("/LoadCustomersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: `${item.Fullname} (${item.SrNo})`,
              value: item.UniqueID,
            }));
            // console.log(data);
            setCustomersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCustomersList([]);
        }
      })
      .catch((e) => {
        setCustomersList([]);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) getCustomers();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const EditVehicle = async () => {
    const { Customer, CustomerName, RegNo } = data;
    if (!Customer || !CustomerName || !RegNo) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const response = await axiosPrivate.post("/EditVehicle.php", { ...data, UniqueID: Id });
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        setEditable(true);

        toaster.push(
          <Notification type="success" header="Success">
            {`Vehicle added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this policy's document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post("/RemovePolicyDoc.php", datatosend);
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID != ID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={1}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Customer :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <SelectPicker
                              disabled={editable}
                              block={true}
                              appearance="default"
                              value={data.Customer}
                              onSelect={(e, item) =>
                                setData((a) => {
                                  return { Customer: e, CustomerName: item.label };
                                })
                              }
                              data={customersList}
                              cleanable={false}
                            />
                            {/* <IconButton
                              disabled={editable}
                              color="primary"
                              aria-label="add "
                              onClick={handleOpen}
                            >
                              <PlusIcon />
                            </IconButton> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Reg No :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <TextField
                              aria-readonly
                              value={data?.RegNo}
                              disabled={editable}
                              fullWidth
                              name="RegNo"
                              placeholder="vehicle registration no"
                              style={{ textTransform: "uppercase" }}
                              id="vehicleregno"
                              onChange={(e) => {
                                setData((dt) => {
                                  return { ...dt, RegNo: e.target.value };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Vehicle Make/Model :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            {/* <TextField
                              disabled={editable}
                              value={data?.Name || ""}
                              name="Name"
                              placeholder="Type Vehicle name"
                              id="Name"
                              autoComplete="off"
                              onChange={handleChange}
                              fullWidth
                            /> */}
                            <SelectPicker
                              disabled={editable}
                              value={data?.Name}
                              block={true}
                              placeholder="Select Vehicle name"
                              name="Name"
                              appearance="default"
                              onChange={(e, item) => {
                                setData((dt) => {
                                  return { ...dt, Name: e };
                                });
                              }}
                              data={vehicletypeList}
                              cleanable={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>RC Renewal :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={editable}
                              format="dd-MM-yyyy"
                              oneTap
                              value={data.RCRenewal}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, RCRenewal: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Insurance :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              disabled={editable}
                              format="dd-MM-yyyy"
                              oneTap
                              value={data.Insurance}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Insurance: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>PUC :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={editable}
                              format="dd-MM-yyyy"
                              oneTap
                              value={data.PUC}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, PUC: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Fitness :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={editable}
                              format="dd-MM-yyyy"
                              oneTap
                              value={data?.Fitness}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Fitness: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          xs={12}
                          container
                          style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Grid xs={5} style={{ justifyContent: "center" }}>
                            <InputLabel>Permit :</InputLabel>
                          </Grid>
                          <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                            <DatePicker
                              cleanable={true}
                              disabled={editable}
                              format="dd-MM-yyyy"
                              oneTap
                              value={data?.Permit}
                              onChange={(d) => {
                                setData((a) => {
                                  return { ...a, Permit: d };
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {(auth.permissions.includes(READ_CUSTOMER) ||
                          auth.user.Type === "SuperAdmin") && (
                          <Link to={`/customers/view/${data.Customer}`}>{`View Customer`}</Link>
                        )}
                        &nbsp; &nbsp; &nbsp;
                        {editable ? (
                          <Button
                            disabled={
                              !(auth.permissions.includes(2302) || auth.user.Type === "SuperAdmin")
                            }
                            style={{ color: "white", backgroundColor: "#2979ff" }}
                            disableElevation
                            onClick={() => setEditable(false)}
                            startIcon={<Edit />}
                          >
                            Edit
                          </Button>
                        ) : (
                          <>
                            <Button
                              disabled={
                                !(
                                  auth.permissions.includes(2302) || auth.user.Type === "SuperAdmin"
                                )
                              }
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={EditVehicle}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={() => setEditable(true)}
                              startIcon={<Cancel />}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={editable}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/EditVehiclePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={{ ID: Id }}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Uploader
                          removable={upload}
                          listType="picture-text"
                          fileList={fileList}
                          multiple={true}
                          data={{ ID: Id }}
                          onChange={setfileList}
                          withCredentials={true}
                          headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                          action="https://Downloads.dahiyainsurance.com/UploadVehicleDocs.php"
                          renderFileInfo={(file, fileElement) => (
                            <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                          )}
                        />
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        {
                          <ul>
                            {docs.map((data) => (
                              <li key={data.UniqueID}>
                                {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                                <a
                                  rel="noreferrer"
                                  href={`https://Downloads.dahiyainsurance.com/assets/${data.Path}`}
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                  /* onClick={(e) => getDocs(e, data.UniqueID)} */
                                >
                                  {data.Name}
                                </a>
                                {!editable && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    className={editable ? "" : ""}
                                    onClick={(e) => {
                                      if (editable) {
                                        e.preventDefault();
                                      } else {
                                        handleRemoveDoc(data.UniqueID);
                                      }
                                    }}
                                  >
                                    X
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ===========================Add Customer=============================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>HN/Mohalla :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.address || ""}
                    name="address"
                    placeholder="Type address"
                    id="address"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </>
  );
}

export default EditVehicle;
