import React from "react";
import { createRoot } from "react-dom/client";
import App from "App";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// Material Dashboard 2 React Context Provider
import { ControllerProvider } from "context";

import { BrowserRouter, Route, Routes } from "react-router-dom";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ControllerProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </ControllerProvider>
  </BrowserRouter>
);
