import Grid from "@mui/material/Grid";
import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import InputLabel from "@mui/material/InputLabel";
import { confirmAlert } from "react-confirm-alert"; // Import
import SaveIcon from "@mui/icons-material/Save";

import Button from "@mui/material/Button";
import { Tooltip, Uploader, Whisper, useToaster } from "rsuite";

import moment from "moment";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Notification } from "rsuite";
import { useMaterialUIController } from "context";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { toAmnt } from "functions";
import { toPercent } from "functions";
import { DELETE_POLICY } from "Permissions";
import { AC_PAYOUT } from "Permissions";

function ViewPolicy() {
  const [controller, dispatch] = useMaterialUIController();
  const { auth, persist } = controller;
  const [fileList, setfileList] = React.useState([]);
  const [docs, setDocs] = useState([]);
  const [policyData, setPolicyData] = useState({});
  const { ID, Type } = useParams();
  //console.log(ID);
  const toaster = useToaster();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [policyID, setpolicyID] = React.useState(null);
  const [viewType, setviewType] = React.useState(null);
  React.useEffect(() => {
    setpolicyID(ID);
    setviewType(Type);
    let isMounted = true;
    const getCustomersData = async () => {
      try {
        const response = await axiosPrivate.post("/GetPolicyData.php", { ID });
        const result = response.data;
        if (result.status === 1) {
          //console.log(result.Data);
          setPolicyData(result.Data[0]);
          setDocs(result.Docs);
        }
        if (result.status === 0) {
          setPolicyData([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomersData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const myStyle = {
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    height: 45,
    border: 1,
    borderStyle: "dashed",
    margin: 5,
    borderColor: "#ddd",
  };

  const GridElement = ({
    label,
    value,
    type,
    link = "",
    ID = "",
    style = {},
    isAmount = false,
  }) => {
    return (
      /*       <Grid xs={12} container style={myStyle} spacing={3}>
        <Grid xs={5} style={{ display: "flex", justifyContent: "flex-start" }}>
          <InputLabel style={{ fontWeight: "bold" }}>{`${label}`}</InputLabel>
        </Grid>
        <Grid xs={7}>
          {type === "BrokerAccount" ? (
            auth.permissions.includes(2402) || auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel style={{ fontSize: 15 }}>{`xxx`}</InputLabel>
            )
          ) : type === "DahiyaAccount" ? (
            auth.permissions.includes(2403) || auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel style={{ fontSize: 15 }}>{`xxx`}</InputLabel>
            )
          ) : (
            <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
              <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
            </Whisper>
          )}
        </Grid>
      </Grid> */
      <>
        <td width={150} style={{ paddingLeft: 15, backgroundColor: "#e1f1ff" }}>
          <InputLabel style={{ fontWeight: "bold", color: "#000" }}>{`${label}`}</InputLabel>
        </td>
        <td
          width={250}
          style={{
            backgroundColor: "#f3faff",
            paddingLeft: 10,
            wordWrap: "break-word",
            textTransform: "capitalize",
          }}
        >
          {type === "BrokerAccount" || isAmount ? (
            auth.permissions.includes(2402) ||
            auth.permissions.includes(AC_PAYOUT) ||
            auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel
                  style={{ fontSize: 15, fontFamily: "Poppins", color: "#111" }}
                >{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel style={{ fontSize: 15 }}>{`xxx`}</InputLabel>
            )
          ) : type === "DahiyaAccount" || isAmount ? (
            auth.permissions.includes(2403) ||
            auth.permissions.includes(AC_PAYOUT) ||
            auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel
                  style={{ fontSize: 15, fontFamily: "Poppins", color: "#111" }}
                >{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel
                style={{ fontSize: 15, fontFamily: "Poppins", color: "#111" }}
              >{`xxx`}</InputLabel>
            )
          ) : (
            <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
              {link ? (
                <Link to={`/customers/view/${ID}`}>{`${value}`}</Link>
              ) : (
                <InputLabel
                  style={{ fontSize: 15, fontFamily: "Poppins", color: "#111", ...style }}
                >{`${value}`}</InputLabel>
              )}
            </Whisper>
          )}
        </td>
      </>
    );
  };

  /* const GridElement = ({ label, value, type }) => {
    return (
      <Grid xs={12} container style={myStyle} spacing={3}>
        <Grid xs={5} style={{ display: "flex", justifyContent: "flex-start" }}>
          <InputLabel style={{ fontWeight: "bold" }}>{`${label}`}</InputLabel>
        </Grid>
        <Grid xs={7}>
          {type === "BrokerAccount" ? (
            auth.permissions.includes(2402) || auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel style={{ fontSize: 15 }}>{`xxx`}</InputLabel>
            )
          ) : type === "DahiyaAccount" ? (
            auth.permissions.includes(2403) || auth.user.Type === "SuperAdmin" ? (
              <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
                <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
              </Whisper>
            ) : (
              <InputLabel style={{ fontSize: 15 }}>{`xxx`}</InputLabel>
            )
          ) : (
            <Whisper followCursor speaker={<Tooltip>{`${value}`}</Tooltip>}>
              <InputLabel style={{ fontSize: 15 }}>{`${value}`}</InputLabel>
            </Whisper>
          )}
        </Grid>
      </Grid>
    );
  }; */
  const RemovePolicyData = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this policy details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post("/RemovePolicy.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this policy's document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post("/RemovePolicyDoc.php", datatosend);
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID != ID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white">
                  View Policy
                </MDTypography>
                <div style={{ flex: 1 }}></div>
                <div>
                  <Button
                    disabled={!(auth.permissions.includes(1604) || auth.user.Type === "SuperAdmin")}
                    style={{ color: "white", backgroundColor: "#2979ff" }}
                    onClick={() => navigate(`/editpolicy/${ID}`)}
                    startIcon={<SaveIcon />}
                  >
                    Edit
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    disabled={!(auth.permissions.includes(1605) || auth.user.Type === "SuperAdmin")}
                    style={{ color: "white", backgroundColor: "#2979ff" }}
                    onClick={RemovePolicyData}
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                  &nbsp; &nbsp;
                </div>
              </div>
            </MDBox>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
              pt={5}
              pl={5}
              style={{ paddingRight: 10 }}
            >
              <table
                style={{
                  padding: 10,
                  border: "1px solid",
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <thead height={50}>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                  <th style={{ borderBottom: "1px solid #ddd", backgroundColor: "#f3faff" }}></th>
                </thead>
                <tbody>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement label={`Broker : `} value={policyData.brokername || ""} />
                    <GridElement
                      label={`OD Premium : `}
                      type={viewType}
                      value={toAmnt(policyData.odpremium) || ""}
                    />
                    <GridElement
                      label={`Comm. TP Amnt : `}
                      type={viewType}
                      value={toAmnt(policyData.commtp) || ""}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement
                      label={`Dahiya Mitra / Self : `}
                      value={policyData.DSOName || ""}
                    />
                    <GridElement
                      label={`TP Premium : `}
                      type={viewType}
                      value={toAmnt(policyData.tppremium) || ""}
                    />
                    <GridElement
                      label={`Total Comm Amnt : `}
                      value={toAmnt(policyData.totalcomm) || ""}
                      type={viewType}
                      isAmount={true}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement label={`Policy No : `} value={policyData.policyno || ""} />
                    <GridElement
                      label={`Net Premium : `}
                      type={viewType}
                      value={toAmnt(policyData.netpremium) || ""}
                    />
                    <GridElement
                      label={`Start Date : `}
                      value={
                        policyData.startDate === "0000-00-00"
                          ? ""
                          : moment(policyData.startDate).format("DD-MM-YYYY")
                      }
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement label={`Insured Name : `} value={policyData.insuredname || ""} />
                    <GridElement
                      label={`Gross Premium : `}
                      value={toAmnt(policyData.grosspremium) || ""}
                      type={viewType}
                    />
                    <GridElement
                      label={`Policy Date : `}
                      value={
                        policyData.paymentDate === "0000-00-00"
                          ? ""
                          : moment(policyData.policydate).format("DD-MM-YYYY")
                      }
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    {/*                   {policyData.DSOName === "Self" && (
                    <GridElement label={`Customer Name : `} value={policyData.customername || ""} />
                  )} */}

                    <GridElement
                      label={`Customer Name : `}
                      value={policyData.customername || ""}
                      link="customer"
                      ID={policyData.customerid}
                    />
                    <GridElement
                      label={`Comm Net : `}
                      type={viewType}
                      value={toPercent(policyData.commpernet) || ""}
                    />
                    <GridElement
                      label={`Policy End Date : `}
                      value={
                        policyData.policyenddate === "0000-00-00"
                          ? ""
                          : moment(policyData.policyenddate).format("DD-MM-YYYY")
                      }
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement
                      label={`Insurance Co. : `}
                      value={policyData.insurancecompany || ""}
                    />
                    <GridElement
                      label={`Comm OD : `}
                      type={viewType}
                      value={toPercent(policyData.commperod) || ""}
                      isAmount={true}
                    />
                    <GridElement
                      label={`DR : `}
                      type={viewType}
                      value={toAmnt(policyData.DR) || ""}
                      isAmount={true}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement label={`Policy Type : `} value={policyData.policytype || ""} />
                    <GridElement
                      label={`Comm TP : `}
                      type={viewType}
                      value={toPercent(policyData.commpertp) || ""}
                      isAmount={true}
                    />
                    <GridElement
                      label={`CR I : `}
                      type={viewType}
                      value={toAmnt(policyData.CR1) || ""}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement label={`Vehicle Model : `} value={policyData.vehiclemodel || ""} />
                    <GridElement
                      label={`Comm. Net Amount : `}
                      value={toAmnt(policyData.commnetamount) || ""}
                      type={viewType}
                      isAmount={true}
                    />
                    <GridElement
                      label={`CR II : `}
                      type={viewType}
                      value={toAmnt(policyData.CR2) || ""}
                      isAmount={true}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement
                      label={`Vehicle Reg No : `}
                      value={policyData.vehicleregno || ""}
                    />
                    <GridElement
                      label={`Comm. OD Amnt : `}
                      type={viewType}
                      value={toAmnt(policyData.commod) || ""}
                      isAmount={true}
                    />
                    <GridElement
                      label={`Balance : `}
                      type={viewType}
                      value={toAmnt(policyData.Balance) || ""}
                      isAmount={true}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd" }} height={50}>
                    <GridElement
                      label={`Paid Via : `}
                      type={viewType}
                      value={policyData?.paidvia || ""}
                    />
                    <GridElement
                      label={`Policy Category : `}
                      type={viewType}
                      value={policyData?.policycategory || ""}
                    />
                    <GridElement
                      label={`Added By / On`}
                      type={viewType}
                      value={
                        `${policyData.AddedBy}/${moment(policyData.AddedOn).format(
                          "DD-MM-YY HH:mm"
                        )}` || ""
                      }
                      style={{ fontSize: 12 }}
                    />
                  </tr>
                  <tr style={{ borderBottom: "1px solid #ddd", paddingLeft: 10 }} height={50}>
                    <td style={{ backgroundColor: "#d6ecff", paddingLeft: 10 }} colSpan={4}>
                      {(auth.permissions.includes(1803) || auth.user.Type === "SuperAdmin") && (
                        <ul>
                          {docs.map((data) => (
                            <li key={data.UniqueID}>
                              {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                              <a
                                rel="noreferrer"
                                href={`https://Downloads.dahiyainsurance.com/assets/${data.Path}`}
                                target="_blank"
                                style={{ cursor: "pointer" }}
                                /* onClick={(e) => getDocs(e, data.UniqueID)} */
                              >
                                {data.Name}
                              </a>
                              {(auth.permissions.includes(DELETE_POLICY) ||
                                auth.user.Type === "SuperAdmin") && (
                                <a
                                  style={{
                                    paddingLeft: 5,
                                    textDecoration: "none",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRemoveDoc(data.UniqueID)}
                                >
                                  X
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                    <td style={{ backgroundColor: "#d6ecff" }} colSpan={2}>
                      <Uploader
                        removable={false}
                        listType="picture-text"
                        fileList={fileList}
                        multiple={true}
                        data={{ ID: policyID }}
                        onChange={setfileList}
                        withCredentials={true}
                        headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                        action="https://Downloads.dahiyainsurance.com/UploadPolicyDocs.php"
                        renderFileInfo={(file, fileElement) => (
                          <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*               <Grid
                xs={12}
                sm={6}
                md={4}
                lg={4}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                ></Grid>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={4}
                lg={4}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                ></Grid>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={4}
                lg={4}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                >
                  <Grid
                    xs={12}
                    container
                    style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                  >

                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Grid
                      xs={12}
                      container
                      style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                    >
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ViewPolicy;
