import Dashboard from "layouts/dashboard";
import Users from "layouts/users";
import Agents from "layouts/Agents";
import Customers from "layouts/customers";
import Insurance from "layouts/Insurance";
import Followups from "layouts/Followup";
import Icon from "@mui/material/Icon";

import Report from "layouts/Reports";
import Accounting from "layouts/accounting";
import Events from "layouts/Events";
import NewEntry from "layouts/NewEntry";
import Inputs from "layouts/Inputs";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "dashboard",
    component: <Dashboard />,
    access: ["SuperAdmin"],
    permissions: [0],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "users/list",
    component: <Users />,
    access: ["SuperAdmin", "Admin"],
    permissions: [1901],
  },
  {
    type: "collapse",
    name: "Brokers",
    key: "agent",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "agent/list",
    component: <Agents />,
    access: ["SuperAdmin", "Staff"],
    permissions: [2001],
  },
  {
    type: "collapse",
    name: "Dahiya Mitra",
    key: "mitra",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "agent/mitra",
    component: <Agents />,
    access: ["SuperAdmin", "Staff"],
    permissions: [2201],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "customers/list",
    component: <Customers />,
    access: ["SuperAdmin", "Staff"],
    permissions: [1502],
  },
  {
    type: "collapse",
    name: "Insurance",
    key: "insurance",
    icon: <Icon fontSize="small">diamond</Icon>,
    route: "insurance",
    component: <Insurance />,
    access: ["SuperAdmin", "Staff"],
    permissions: [1602],
  },
  {
    type: "collapse",
    name: "Followups",
    key: "followup",
    icon: <Icon fontSize="small">calendar_today</Icon>,
    route: "followup",
    component: <Followups />,
    access: ["SuperAdmin", "Staff"],
    permissions: [2501],
  },
  {
    type: "collapse",
    name: "Accounting",
    key: "accounting",
    icon: <Icon fontSize="small">money</Icon>,
    route: "accounting",
    component: <Accounting />,
    access: ["SuperAdmin"],
    permissions: [2401, 2402, 2403, 2404],
  },
  {
    type: "collapse",
    name: "Vehicle",
    key: "vehicle",
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "vehicle/list",
    component: <Accounting />,
    access: ["SuperAdmin", "Staff"],
    permissions: [2301],
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    icon: <Icon fontSize="small">download</Icon>,
    route: "report",
    component: <Report />,
    access: ["SuperAdmin"],
    permissions: [1800, 1801, 1802, 1803, 1804, 2301],
  },

  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">calendar_today</Icon>,
    route: "events",
    component: <Events />,
    access: ["SuperAdmin", "Staff"],
    permissions: [2101],
  },

  {
    type: "collapse",
    name: "Inputs",
    key: "inputs",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "inputs",
    component: <Inputs />,
    access: ["SuperAdmin"],
    permissions: [],
  },
  /* ,
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "profile",
    component: <Profile />,
    access: ["SuperAdmin", "Staff"],
    permissions: [0],
  }, */
];

export default routes;
