import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

import { useToaster, Notification } from "rsuite";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { useNavigate } from "react-router-dom";
import { DELETE_CUSTOMER } from "Permissions";

import { useMaterialUIController } from "context";
import { RestoreFromTrash } from "@mui/icons-material";

function DeletedCustomer() {
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;
  const toaster = useToaster();

  const axiosPrivate = useAxiosPrivate();
  const [customers, setCustomers] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListDeletedCustomer.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const RestoreCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this customer's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RestoreCustomer.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };
  const DeleteCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this customer's data permanently.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post(
                    "/DeleteCustomerPermanently.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  const columns = React.useMemo(
    () => [
      { field: "SrNo", type: "string", width: 200, headerName: "CustomerID" },
      { field: "FullName", type: "string", width: 200 },
      { field: "Mobile", type: "string", width: 200 },
      { field: "village", type: "String", width: 200, headerName: "Village/City" },
      { field: "RefBy", type: "String", width: 200 },
      {
        field: "DOB",
        type: "Date",
        width: 200,
        headerName: "DOB",
        renderHeader: () => (
          <strong>
            {"Birthday "}
            <span role="img" aria-label="enjoy">
              🎂
            </span>
          </strong>
        ),
      },
      { field: "anniversary", type: "String", width: 200, headerName: "Anniversary" },
      { field: "Email", type: "String", width: 200, headerName: "Email" },
      { field: "caste", type: "String", width: 200, headerName: "Caste" },
      { field: "Address", type: "string", width: 180 },
      { field: "tehsil", type: "String", width: 200, headerName: "Tehsil" },
      { field: "district", type: "String", width: 200, headerName: "District" },
      { field: "state", type: "String", width: 200, headerName: "State" },
      { field: "pin", type: "String", width: 200, headerName: "PIN" },
      { field: "work", type: "String", width: 200, headerName: "Work" },
      { field: "OfficeVisited", type: "String", width: 200, headerName: "OfficeVisited" },
      { field: "Deleted", type: "String", width: 200, headerName: "Deleted" },
      {
        field: "actions",
        type: "actions",
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={
              !(auth.permissions.includes(DELETE_CUSTOMER) || auth.user.Type === "SuperAdmin")
            }
            key={params.id}
            icon={<RestoreFromTrash />}
            label="Restore"
            onClick={() => RestoreCustomer(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => DeleteCustomer(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [RestoreCustomer]
  );

  return (
    <>
      <MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <div style={{ height: 600, width: 1200, overflow: "auto" }}>
                  <DataGrid
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    rows={customers}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns status and traderName, the other columns will remain visible
                          DOB: false,
                          anniversary: false,
                          Email: false,
                          caste: false,
                          address: false,
                          Address: false,
                          tehsil: false,
                          district: false,
                          state: false,
                          pin: false,
                          work: false,
                          OfficeVisited: false,
                          Deleted: false,
                        },
                      },
                    }}
                  />
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default DeletedCustomer;
