import React from "react";
import { useRef, useState, useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import "./vendor/bootstrap/css/bootstrap.min.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./vendor/animate/animate.css";
import "./vendor/css-hamburgers/hamburgers.min.css";
import "./vendor/animsition/css/animsition.min.css";
import "./vendor/select2/select2.min.css";
import "./css/util.css";
import "./css/main.css";
import axios from "api/axios";
import { useToaster, Notification, Button, Message } from "rsuite";
import { setAuth, setPersist } from "context";
import { useMaterialUIController } from "context";
import { currentBrowser } from "functions";
import { OS } from "functions";

const LOGIN_URL = "/Login.php";
const OTP_URL = "/VerifyOTP.php";
export default function Login() {
  const toaster = useToaster();
  const [passVerified, setPassVerified] = React.useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { auth, persist } = controller;
  /* const handleLogin = (e) => {
    e.preventDefault();
    axios.post("Login.php", {
      
    })
    setPassVerified(true);
  }; */
  //console.log(useAuth());
  //const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [otp, setOTP] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [ip, setIP] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let ismounted = true;
    if (ismounted) userRef.current.focus();
    axios
      .get("https://ip.nf/me.json")
      .then((a) => setIP(ip?.ip))
      .catch((e) => {
        console.log(e);
      });
    return () => {
      ismounted = false;
    };
  }, []);
  useEffect(() => {
    sessionStorage.setItem("persist", true);
  }, [persist]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleOTP = async () => {};

  const handleLogin = async (e) => {
    // console.log(auth);
    e.preventDefault();
    toaster.push(<Message type="success">Processing</Message>);
    const from = location.state?.from?.pathname || "/dashboard";
    //const from = location.state?.from?.pathname || "/dashboard";
    if (user && pwd && passVerified && otp) {
      try {
        const response = await axios.post(
          OTP_URL,
          JSON.stringify({
            Email: user,
            Password: pwd,
            OTP: otp,
            Browser: currentBrowser,
            OS: OS,
            IP: ip,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          setAuth(dispatch, {
            user: result.Data,
            roles: [5150, 1984, 2001],
            permissions: result?.Permissions || [201],
            accessToken: result.Data.SessionID,
          });
          setUser("");
          setPwd("");
          setPersist(dispatch, true);
          navigate("/dashboard", { replace: true });
          console.log("Enter OTP");
          setErrMsg("Incorrect OTP");
        }
        if (result.status === 4) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Incorrect OTP, please verify again.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      } catch (e) {}
    } else {
      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({ Email: user, Password: pwd }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const result = response.data;
        if (response && (response["status"] === 201 || response["status"] === 200)) {
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success">
                Authenticated successfully
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );

            setPassVerified(true);
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error">
                {result.Error}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
          if (result.status === 2) {
            toaster.push(
              <Notification type="error" header="Error">
                No access, please contact admin.
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
          if (result.status === 3) {
            toaster.push(
              <Notification type="error" header="Error">
                Account locked, please contact admin.
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
          // console.log(JSON.stringify(response?.data));
          //console.log(JSON.stringify(response));

          //navigate("/", { replace: true });
          //console.log(response);
        }
      } catch (err) {
        console.log(err);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      }
    }
  };
  const togglePersist = () => {
    setPersist(dispatch, true);
  };

  return (
    <div className="limiter">
      <div className="container-login100" style={{ backgroundImage: "" }}>
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form className="login100-form validate-form" onSubmit={handleLogin}>
            <span className="login100-form-title p-b-49">Login</span>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is required"
            >
              <span className="label-input100">Username</span>
              <input
                disabled={passVerified}
                className="input100"
                ref={userRef}
                type="text"
                name="username"
                placeholder="Type your username"
                id="username"
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>
            <div className="wrap-input100 validate-input" data-validate="Password is required">
              <span className="label-input100">Password</span>
              <input
                disabled={passVerified}
                className="input100"
                type="password"
                name="pass"
                placeholder="Type your password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>
            &nbsp;
            {passVerified && (
              <div className="wrap-input100 validate-input m-b-30" data-validate="OTP is required">
                <span className="label-input100">Security code</span>
                <input
                  className="input100"
                  type="text"
                  name="OTP"
                  placeholder="Type your security code"
                  onChange={(e) => setOTP(e.target.value)}
                  value={otp}
                  required
                />
                <span className="focus-input100" data-symbol="&#xf190;"></span>
              </div>
            )}
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            {/* <div className="persistCheck">
              <input type="checkbox" id="persist" onChange={togglePersist} checked={persist} />
              <label htmlFor="persist">Trust This Device</label>
            </div> */}
            <div className="text-right p-t-8 p-b-31">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toaster.push(<Message type="error"> Please contact admin.</Message>);
                }}
              >
                Forgot password?
              </a>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button className="login100-form-btn">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
