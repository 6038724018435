import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";

import { useToaster, Message } from "rsuite";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router-dom";
import { Delete, Visibility } from "@mui/icons-material";
import { useMaterialUIController } from "context";

function ListVehicle() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;
  const navigate = useNavigate();
  const toaster = useToaster();
  const [rows, setRows] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();
  async function handleRefresh() {
    try {
      const response = await axiosPrivate.post("/ListVehicles.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setUsers(result.Data);
      }
      if (result.status === 0) {
        setUsers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }
  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.post("/ListVehicles.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setUsers(result.Data);
        }
        if (result.status === 0) {
          setUsers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const EditVehicle = (ID) => {
    navigate(`/vehicle/edit/${ID}`);
  };

  const toggleAdmin = React.useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? { ...row, isAdmin: !row.isAdmin } : row))
      );
    },
    []
  );

  const RemoveVehicle = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this vehicle's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveVehicle.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(<Message type="success">Delete successfully.</Message>);
                    handleRefresh();
                  }

                  if (result.status === 0) {
                    toaster.push(<Message type="error">{`Error occured`}</Message>);
                  }
                  onclose();
                } catch (err) {
                  console.error(err);
                  toaster.push(<Message type="error">{`Error occured`}</Message>);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const columns = React.useMemo(
    () => [
      { field: "CustomerName", type: "string", width: 300 },
      { field: "CustMobile", type: "string", width: 150, headerName: "Mobile" },
      { field: "CustID", type: "string", width: 150, headerName: "Cust ID" },
      { field: "CustVillage", type: "string", width: 150, headerName: "Village" },
      { field: "RegNo", type: "string", width: 150 },
      { field: "Name", type: "string", width: 250, headerName: "VehicleMakeModel" },
      { field: "PUC", type: "string", width: 150 },
      { field: "Insurance", type: "string", width: 150 },
      { field: "Permit", type: "string", width: 150 },
      { field: "RCRenewal", type: "string", width: 150 },
      { field: "Fitness", type: "string", width: 150 },
      { field: "Deleted", type: "string", width: 150 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<Visibility />}
            label="View"
            onClick={() => EditVehicle(params.id)}
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2302) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<Delete />}
            label="Remove"
            onClick={() => RemoveVehicle(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [toggleAdmin]
  );

  return (
    <MDBox>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={1}>
              <div
                style={{
                  height: 600,
                  width: 1100,

                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                  display: "flex",
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={users}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        CustMobile: false,
                        CustID: false,
                        CustVillage: false,
                        Deleted: false,
                        RCRenewal: false,
                      },
                    },
                  }}
                />
              </div>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
/* filter: {
  filterModel: {
    items: [{ field: "Deleted", operator: "contains", value: "No" }],
  },
}, */
export default ListVehicle;
