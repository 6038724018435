import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { confirmAlert } from "react-confirm-alert"; // Import

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import { Input, Stack } from "rsuite";
import { DatePicker } from "rsuite";
import Select from "@mui/material/Select";
import { SelectPicker } from "rsuite";
import IconButton from "@mui/material/IconButton";
import { useToaster, Notification } from "rsuite";
import { createSvgIcon } from "@mui/material/utils";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import moment from "moment";

import Slide from "@mui/material/Slide";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import useAxiosPrivate from "hooks/useAxiosPrivate";

import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import { AttachFile, Cancel } from "@mui/icons-material";
import { validateNumber } from "functions";
const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  "Plus"
);

function EditCustomer() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;
  /*   const [joiningdate, setJoiningDate] = useState(new Date());
  const [dob, setDOB] = useState(new Date()); */
  const [casteList, setCasteList] = React.useState([]);
  const [villageList, setVillageList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [tehsilList, setTehsilList] = React.useState([]);
  const [editable, setEditable] = useState(true);
  const [customerId, setCustomerId] = useState("");
  const [officeList, setOfficeList] = React.useState([]);
  const [professionList, setProfessionList] = React.useState([]);
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [type, setType] = React.useState("");
  const [customers, setCustomers] = React.useState([]);
  const [editCustomerData, setEditCustomerData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();

  const [upload, setUpload] = useState(true);
  const [caste, setCaste] = React.useState();
  const [village, setVillage] = React.useState();

  //const usersList = useUsersList([]);
  const navigate = useNavigate();

  const handleOpenAddCaste = () => {
    setOpenAddCaste(true);
  };

  const handleCloseAddCaste = () => {
    setOpenAddCaste(false);
  };
  const handleOpenAddVillage = () => {
    setOpenAddVillage(true);
  };

  const handleCloseAddVillage = () => {
    setOpenAddVillage(false);
  };
  const [openAddCaste, setOpenAddCaste] = React.useState(false);
  const [openAddVillage, setOpenAddVillage] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const { ID } = useParams();
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  const [fileList, setfileList] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    const getCustomersData = async () => {
      try {
        const response = await axiosPrivate.post("/GetCustomerData.php", { ID });
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setEditCustomerData({
            ...result.Data[0],
            refby: result.Data[0].RefBy,
            fullname: result.Data[0].FullName,
            email: result.Data[0].Email,
            address: result.Data[0].Address,
            mobile: result.Data[0].Mobile1,
            whatsapp: result.Data[0].Mobile2,
            office: result.Data[0].officevisited,
            joiningdate:
              result.Data[0]?.joiningdate === "0000-00-00"
                ? null
                : new Date(result.Data[0]?.joiningdate),
            dob: result.Data[0]?.dob === "0000-00-00" ? null : new Date(result.Data[0]?.dob),
            anniversary:
              result.Data[0]?.anniversary === "0000-00-00"
                ? null
                : new Date(result.Data[0]?.anniversary),
          });
          // console.log(editCustomerData);
          setDocs(result.Docs);
          if (result.Data[0].ProfilePic)
            setFileInfo(
              `https://Downloads.dahiyainsurance.com/profile/${result.Data[0].ProfilePic}`
            );
        }
        if (result.status === 0) {
          setEditCustomerData([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomersData();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const getOfficeList = () => {
    axiosPrivate
      .post("/LoadOfficeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Office,
              value: item.Code,
            }));
            // console.log(data);
            setOfficeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setOfficeList([]);
        }
      })
      .catch((e) => {
        setOfficeList([]);
      });
  };
  const getProfessionList = () => {
    axiosPrivate
      .post("/LoadProfessionList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setProfessionList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setProfessionList([]);
        }
      })
      .catch((e) => {
        setProfessionList([]);
      });
  };
  async function getDocs(e, docID) {
    e.preventDefault();
    console.log(docID);
    try {
      const response = await axiosPrivate.post("/GetDocument.php", { ID: docID });
      const result = response.data;
      if (result.status === 1) {
      }
      if (result.status === 0) {
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  }
  const myStyle = {
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    height: 45,
  };
  function handleEditCustomerChange(evt) {
    const value = evt.target.value;
    setEditCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  const getCasteList = () => {
    axiosPrivate
      .post("/LoadCasteList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setCasteList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCasteList([]);
        }
      })
      .catch((e) => {
        setCasteList([]);
      });
  };

  const getVillageList = () => {
    axiosPrivate
      .post("/LoadVillageList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVillageList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVillageList([]);
        }
      })
      .catch((e) => {
        setVillageList([]);
      });
  };

  const getStateList = () => {
    axiosPrivate
      .post("/LoadStateList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setStateList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setStateList([]);
        }
      })
      .catch((e) => {
        setStateList([]);
      });
  };

  const getTehsilList = () => {
    axiosPrivate
      .post("/LoadTehsilList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setTehsilList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setTehsilList([]);
        }
      })
      .catch((e) => {
        setTehsilList([]);
      });
  };
  const getDistrictList = () => {
    axiosPrivate
      .post("/LoadDistrictList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setDistrictList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setDistrictList([]);
        }
      })
      .catch((e) => {
        setDistrictList([]);
      });
  };
  /* 
  const getTalukaList = () => {
    axiosPrivate
      .post("/LoadTalukaList.php", {
        state: editCustomerData.state,
        district: editCustomerData.district,
      })
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setTalukaList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setTalukaList([]);
        }
      })
      .catch((e) => {
        setTalukaList([]);
      });
  }; */

  const addnewCaste = async () => {
    if (!caste) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        caste,
      };
      const response = await axiosPrivate.post("/AddCaste.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddCaste(false);

        getCasteList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewVillage = async () => {
    if (!village) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const { tehsil, district, state } = editCustomerData;

      const datatosend = {
        village,
        tehsil,
        district,
        state,
      };
      const response = await axiosPrivate.post("/AddVillage.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleCloseAddVillage(false);

        getVillageList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const refershallDropdown = () => {
    getCasteList();
    getVillageList();
    getStateList();
    getTehsilList();
    setCustomerId(null);
    getOfficeList();
    getDistrictList();
    getProfessionList();
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refershallDropdown();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          // setCustomers(result.Data);
        }
        if (result.status === 0) {
          //setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      // getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const [rowData, setRowData] = React.useState(null);

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddCustomer = async () => {
    // console.log(editCustomerData);

    const {
      address,
      caste,
      district,
      email,
      fullname,
      lic,
      licstatus,
      mobile,
      pin,
      refby,
      remark,
      state,
      tehsil,
      village,
      whatsapp,
      work,
      dob,
      joiningdate,
      anniversary,
      office,
    } = editCustomerData;
    if (!fullname || !refby || !village || !whatsapp || !joiningdate || !office) {
      if (!fullname) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Full name is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!refby) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Ref By Fields is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!village) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Village is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!whatsapp) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Mobile No is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!joiningdate) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Joining Date is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      if (!office) {
        toaster.push(
          <Notification type="error" header="Error">
            {`Visited Office is required.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        return;
      }
      return;
    }

    if (whatsapp && !validateNumber(whatsapp)) {
      toaster.push(
        <Notification type="error" header="Error">
          {`Please enter valid mobile number.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        address,
        caste,
        district,
        email,
        fullname,
        lic,
        licstatus,
        mobile,
        pin,
        refby,
        remark,
        state,
        tehsil,
        village,
        whatsapp,
        work,
        dob: dob && moment(dob).format("YYYY-MM-DD"),
        joiningdate: moment(joiningdate).format("YYYY-MM-DD"),
        anniversary: anniversary && moment(anniversary).format("YYYY-MM-DD"),
        ID,
        office,
      };
      const response = await axiosPrivate.post("/EditCustomerDetails.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        setEditable(false);
        setUpload(true);
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer updated successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
        // setEditCustomerData({});
        //setType("");
        navigate(`/customers/view/${ID}`);
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const EditCustomer = async () => {
    const { fullname, email, address, mobile, mobile2, refby } = editCustomerData;
    if (fullname === "" || email === "" || address === "" || mobile === "" || mobile2 === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        fullname,
        email,
        address,
        mobile,
        mobile2,
        ID: rowData.id,
      };
      const response = await axiosPrivate.post("/EditCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer update successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleRefresh();
        handleClose();
        handleEditClose();
        setEditCustomerData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                setUpload(false);
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (data) => {
    setOpenEdit(true);
    setRowData(data.row);
    console.log(data.row);

    setEditCustomerData({
      address: data.row.Address,
      email: data.row.Email,
      mobile: data.row.Mobile1,
      mobile2: data.row.Mobile2,
      fullname: data.row.FullName,
    });
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>
                            First Visit Date * :
                          </InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              oneTap
                              disabled={true}
                              fullWidth
                              format="dd-MM-yyyy"
                              style={{ width: 150 }}
                              cleanable={false}
                              value={editCustomerData.joiningdate}
                              onChange={(e) =>
                                setEditCustomerData((a) => {
                                  return { ...a, joiningdate: e };
                                })
                              }
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Ref By* :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editCustomerData?.refby || ""}
                            name="refby"
                            placeholder="Type refby"
                            id="refby"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Date of Birth :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <DatePicker
                            oneTap
                            fullWidth
                            disabled={!editable}
                            format="dd-MM-yyyy"
                            style={{ width: 150 }}
                            cleanable={true}
                            value={editCustomerData?.dob}
                            onChange={(e) =>
                              setEditCustomerData((a) => {
                                return { ...a, dob: e };
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Full name* :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editCustomerData?.fullname || ""}
                            name="fullname"
                            placeholder="Type full name"
                            id="fullname"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Caste :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editCustomerData.caste}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, caste: e };
                              });
                            }}
                            data={casteList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                          <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            onClick={handleOpenAddCaste}
                          >
                            <PlusIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Whats App no* :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            value={editCustomerData?.whatsapp || ""}
                            disabled={!editable}
                            name="whatsapp"
                            placeholder="Type whats app number"
                            id="whatsapp"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Profession :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          {/* <TextField
                            disabled={!editable}
                            value={editCustomerData?.work || ""}
                            name="work"
                            placeholder="Type work / profession"
                            id="work"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          /> */}
                          <SelectPicker
                            placeholder="Select work / profession"
                            disabled={!editable}
                            value={editCustomerData?.work || ""}
                            block={true}
                            appearance="default"
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, work: e };
                              });
                            }}
                            data={professionList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Remark :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editCustomerData?.remark || ""}
                            name="remark"
                            placeholder="Type remark if any"
                            id="remark"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                            multiline={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}></Grid>
                        <Grid xs={7}></Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <InputLabel style={{ fontWeight: "bold" }}>Party :</InputLabel> */}
                        </Grid>
                        <Grid xs={7}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                      height: 45,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={editCustomerData?.address || ""}
                            name="address"
                            placeholder="Type address"
                            id="address"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                            multiline={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Village/City :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editCustomerData.village}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, village: e };
                              });
                            }}
                            data={villageList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                          <IconButton
                            color="primary"
                            aria-label="add to shopping cart"
                            onClick={handleOpenAddVillage}
                          >
                            <PlusIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Tehsil :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          {/*  <TextField
                            disabled={!editable}
                            value={editCustomerData?.tehsil || ""}
                            name="tehsil"
                            placeholder="Type tehsil"
                            id="tehsil"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          /> */}
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            placeholder={editCustomerData?.tehsil}
                            value={editCustomerData?.tehsil}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, tehsil: e };
                              });
                            }}
                            data={tehsilList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>District :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          {/*                           <TextField
                            disabled={!editable}
                            value={editCustomerData?.district || ""}
                            name="district"
                            placeholder="Type district"
                            id="district"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          /> */}

                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            placeholder={editCustomerData?.district}
                            value={editCustomerData?.district}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, district: e };
                              });
                            }}
                            data={districtList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>State :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editCustomerData.state}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, state: e };
                              });
                            }}
                            data={stateList}
                            style={{ flex: 1 }}
                            cleanable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>PIN Code :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            format="######"
                            value={editCustomerData?.pin || ""}
                            name="pin"
                            placeholder="Type pin code"
                            id="pin"
                            autoComplete="off"
                            onChange={handleEditCustomerChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Anniversary :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <DatePicker
                            oneTap
                            disabled={!editable}
                            fullWidth
                            format="dd-MM-yyyy"
                            style={{ width: 150 }}
                            cleanable={true}
                            value={editCustomerData?.anniversary}
                            onChange={(e) =>
                              setEditCustomerData((a) => {
                                return { ...a, anniversary: e };
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>LIC Policy? :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <Stack spacing={2}>
                            <SelectPicker
                              disabled={!editable}
                              block={true}
                              appearance="default"
                              value={editCustomerData?.lic || ""}
                              onSelect={(e, item) => {
                                setEditCustomerData((c) => {
                                  return { ...c, lic: e };
                                });
                              }}
                              data={[
                                { label: "Yes", value: "Yes" },
                                { label: "No", value: "No" },
                                { label: "NA", value: "NA" },
                              ]}
                              cleanable={false}
                              searchable={false}
                            />
                            {editCustomerData?.lic === "Yes" && (
                              <SelectPicker
                                disabled={!editable}
                                cleanable={false}
                                searchable={false}
                                block={true}
                                appearance="default"
                                value={editCustomerData?.licstatus || ""}
                                onSelect={(e, item) => {
                                  setEditCustomerData((c) => {
                                    return { ...c, licstatus: e };
                                  });
                                }}
                                data={[
                                  { label: "Inforce", value: "Inforce" },
                                  { label: "Lapsed", value: "Lapsed" },
                                  { label: "NA", value: "NA" },
                                ]}
                              />
                            )}
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Office Visited * :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <SelectPicker
                            disabled={!editable}
                            block={true}
                            appearance="default"
                            value={editCustomerData.office}
                            onSelect={(e, item) => {
                              setEditCustomerData((c) => {
                                return { ...c, office: e };
                              });
                            }}
                            data={officeList}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {editable && (
                          <>
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={AddCustomer}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={() => navigate(-1)}
                              startIcon={<Cancel />}
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                        &nbsp; &nbsp;
                        {customerId && (
                          <Button
                            style={{ color: "white", backgroundColor: "#2979ff" }}
                            disableElevation
                            onClick={handleAttachment}
                            startIcon={<AttachFile />}
                          >
                            Done
                          </Button>
                        )}
                      </Grid>

                      {/* <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                           <InputLabel style={{ fontWeight: "bold" }}>Party :</InputLabel> 
                        </Grid>
                        <Grid xs={7}></Grid>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={!ID}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/AddProfilePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={{ CustomerID: ID }}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                            onRemove={(file) => {
                              console.log(file);
                              //   const newFileList = [...fileList];
                              //   for (let i in newFileList) {
                              //     if (!newFileList[i].url && !newFileList[i].preview) {
                              //       delete newFileList[i]
                              //     } else {
                              //}
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Uploader
                          disabled={!ID}
                          listType="picture-text"
                          fileList={fileList}
                          multiple={true}
                          data={{ CustomerID: ID }}
                          onChange={setfileList}
                          withCredentials={true}
                          headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                          action="https://Downloads.dahiyainsurance.com/UploadDocs.php"
                          renderFileInfo={(file, fileElement) => (
                            <span style={{ width: "100%", fontSize: 14 }}>Name: {file.name}</span>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ========================Add Customer=========================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No 2 :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type other mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.email || ""}
                    name="email"
                    placeholder="Type email"
                    id="email"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.address || ""}
                    name="address"
                    placeholder="Type address"
                    id="address"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Party :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    value={type}
                    onChange={handleChange}
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select type</em>
                    </MenuItem>
                    <MenuItem value="1st Party">1st Party</MenuItem>
                    <MenuItem value="3rd Party">3rd Party</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* =============================================================== */}

      {/* =============================Caste ============================= */}

      <Dialog
        open={openAddCaste}
        onClose={handleCloseAddCaste}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Caste
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ width: 400 }}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                pt={3}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid
                  xs={12}
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Caste :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={caste || ""}
                      name="caste"
                      placeholder="Enter Caste"
                      id="caste"
                      autoComplete="off"
                      onChange={(e) => setCaste(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddCaste}>
            Cancel
          </Button>
          <Button onClick={addnewCaste}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ============================= Village ============================= */}

      <Dialog
        open={openAddVillage}
        onClose={handleCloseAddCaste}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Village
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ width: 400 }}>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                pt={3}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid
                  xs={12}
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                    <InputLabel style={{ fontWeight: "bold" }}>Village :</InputLabel>
                  </Grid>
                  <Grid xs={7}>
                    <TextField
                      value={village || ""}
                      name="village"
                      placeholder="Enter Village name"
                      id="village"
                      autoComplete="off"
                      onChange={(e) => setVillage(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAddVillage}>
            Cancel
          </Button>
          <Button onClick={addnewVillage}>Add New</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}
    </>
  );
}

export default React.memo(EditCustomer);
