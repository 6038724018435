import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Paper from "@mui/material/Paper";

import { SelectPicker } from "rsuite";
import { useToaster, Notification } from "rsuite";
import { createSvgIcon } from "@mui/material/utils";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { useMaterialUIController } from "context";

function AddUser() {
  const [controller] = useMaterialUIController();
  const { auth } = controller;

  const [editable, setEditable] = useState(true);
  const [userId, setuserId] = useState("");

  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);

  const [customers, setCustomers] = React.useState([]);
  const [addUserData, setAddUserData] = React.useState({});

  const axiosPrivate = useAxiosPrivate();
  const [upload, setUpload] = useState(true);
  const [rawdata, setrawdata] = useState({});

  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );

  function handleAddUserChange(evt) {
    const value = evt.target.value;
    setAddUserData((prev) => ({ ...prev, [evt.target.name]: value }));
  }

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddUser = async () => {
    const { email, firstname, lastname, mobile, usertype, login, userid } = addUserData;
    if (!email || !firstname || !lastname || !mobile || !usertype || !userid) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        email,
        firstname,
        lastname,
        mobile,
        usertype,
        login,
        userid,
      };
      const response = await axiosPrivate.post("/AddUserDetails.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        setuserId(result.ID);
        setrawdata({ userId: result.ID });
        setEditable(false);
        setUpload(true);
        toaster.push(
          <Notification type="success" header="Success">
            {`User added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
                  pt={3}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "center",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>First name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.firstname || ""}
                            name="firstname"
                            placeholder="Type first name"
                            id="firstname"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Last name :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.lastname || ""}
                            name="lastname"
                            placeholder="Type last name"
                            id="lastname"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            style={{ fontWeight: "bold" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>UserID :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.userid || ""}
                            name="userid"
                            placeholder="Enter user ID"
                            id="userid"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.mobile || ""}
                            name="mobile"
                            placeholder="Type mobile"
                            id="mobile"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                        </Grid>
                        <Grid xs={7}>
                          <TextField
                            disabled={!editable}
                            value={addUserData?.email || ""}
                            name="email"
                            placeholder="Type email"
                            id="email"
                            autoComplete="off"
                            onChange={handleAddUserChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>Login Allowed? :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            appearance="default"
                            value={addUserData?.login || ""}
                            onChange={(e, item) => {
                              setAddUserData((c) => {
                                return { ...c, login: e };
                              });
                            }}
                            data={[
                              { label: "Yes", value: "1" },
                              { label: "No", value: "0" },
                            ]}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          height: 45,
                        }}
                      >
                        <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                          <InputLabel style={{ fontWeight: "bold" }}>User Type :</InputLabel>
                        </Grid>
                        <Grid xs={7} style={{ display: "flex", flexDirection: "row" }}>
                          <SelectPicker
                            disabled={!editable}
                            appearance="default"
                            value={addUserData?.usertype || ""}
                            onChange={(e, item) => {
                              setAddUserData((c) => {
                                return { ...c, usertype: e };
                              });
                            }}
                            data={[
                              { label: "Admin", value: "Admin" },
                              { label: "Chief Manager", value: "Chief Manager" },
                              { label: "Head Cashier", value: "Head Cashier" },
                              { label: "Field Manager", value: "Field Manager" },
                              { label: "Sales Manager", value: "Sales Manager" },
                              { label: "Account Manager", value: "Account Manager" },
                            ]}
                            cleanable={false}
                            searchable={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                          marginTop: 10,
                          height: 60,
                          justifyContent: "center",
                        }}
                      >
                        {editable && (
                          <>
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              onClick={AddUser}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ color: "white", backgroundColor: "#2979ff" }}
                              disableElevation
                              onClick={() => {
                                setAddUserData({});
                                setuserId(null);
                                handleRefresh();
                              }}
                              startIcon={<RestartAltIcon />}
                            >
                              Reset
                            </Button>
                          </>
                        )}
                        &nbsp; &nbsp;
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    container
                    style={{
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                      pt={3}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                          {/* <img src="https://source.unsplash.com/random/200x200?sig=1" /> */}
                          <Uploader
                            fileListVisible={false}
                            disabled={!userId}
                            multiple={false}
                            listType="picture"
                            action="https://Downloads.dahiyainsurance.com/AddProfilePic.php"
                            withCredentials={true}
                            headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                            data={rawdata}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(<Message type="success">Uploaded successfully</Message>);
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(<Message type="error">Upload failed</Message>);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default AddUser;
