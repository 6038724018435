import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmAlert } from "react-confirm-alert"; // Import
import * as XLSX from "xlsx";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PlusIcon from "@rsuite/icons/Plus";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import {
  Button,
  Input,
  InputGroup,
  List,
  Notification,
  Panel,
  Message,
  Tooltip,
  Whisper,
  useToaster,
} from "rsuite";

import { ellipsify } from "functions";
import { useMaterialUIController } from "context";
import { DeleteForever } from "@mui/icons-material";

function Inputs() {
  const toaster = useToaster();
  const axiosPrivate = useAxiosPrivate();
  const [insuranceCoList, setInsuranceCoList] = React.useState([]);
  const [policytypeList, setPolicyTypeList] = useState([]);
  const [vehicletypeList, setVehicleTypeList] = useState([]);
  const [paidviaList, setPaidViaList] = useState([]);
  const [casteList, setCasteList] = React.useState([]);
  const [villageList, setVillageList] = React.useState([]);
  const [tehsilList, setTehsilList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [professionList, setProfessionList] = React.useState([]);
  const [branchList, setBranchList] = React.useState([]);
  const [policyCategoryList, setPolicyCategoryList] = React.useState([]);
  const [insuranceco, setInsuranceco] = useState("");
  const [policytype, setPolicytype] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [paidvia, setPaidvia] = useState("");
  const [caste, setCaste] = useState("");
  const [village, setVillage] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [branch, setBranch] = useState("");
  const [policycategory, setPolicyCategory] = useState("");
  const [profession, setProfession] = useState("");
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;

  const getVehicleType = () => {
    axiosPrivate
      .post("/LoadVehicleTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVehicleTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVehicleTypeList([]);
        }
      })
      .catch((e) => {
        setVehicleTypeList([]);
      });
  };

  const getPaidVia = () => {
    axiosPrivate
      .post("/LoadPaidViaList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPaidViaList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPaidViaList([]);
        }
      })
      .catch((e) => {
        setPaidViaList([]);
      });
  };

  const getInsuranceCo = () => {
    axiosPrivate
      .post("/LoadInsuranceCompanyList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setInsuranceCoList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setInsuranceCoList([]);
        }
      })
      .catch((e) => {
        setInsuranceCoList([]);
      });
  };

  const getPolicyType = () => {
    axiosPrivate
      .post("/LoadPolicyTypeList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPolicyTypeList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPolicyTypeList([]);
        }
      })
      .catch((e) => {
        setPolicyTypeList([]);
      });
  };
  const getCasteList = () => {
    axiosPrivate
      .post("/LoadCasteList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setCasteList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setCasteList([]);
        }
      })
      .catch((e) => {
        setCasteList([]);
      });
  };

  const getVillageList = () => {
    axiosPrivate
      .post("/LoadVillageList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVillageList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setVillageList([]);
        }
      })
      .catch((e) => {
        setVillageList([]);
      });
  };

  const getTehsilList = () => {
    axiosPrivate
      .post("/LoadTehsilList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setTehsilList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setTehsilList([]);
        }
      })
      .catch((e) => {
        setTehsilList([]);
      });
  };
  const getDistrictList = () => {
    axiosPrivate
      .post("/LoadDistrictList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setDistrictList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setDistrictList([]);
        }
      })
      .catch((e) => {
        setDistrictList([]);
      });
  };

  const getStateList = () => {
    axiosPrivate
      .post("/LoadStateList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setStateList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setStateList([]);
        }
      })
      .catch((e) => {
        setStateList([]);
      });
  };

  const getBranchList = () => {
    axiosPrivate
      .post("/LoadBranchList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setBranchList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setBranchList([]);
        }
      })
      .catch((e) => {
        setBranchList([]);
      });
  };

  const getPolicyCategoryList = () => {
    axiosPrivate
      .post("/LoadPolicyCategoryList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setPolicyCategoryList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setPolicyCategoryList([]);
        }
      })
      .catch((e) => {
        setPolicyCategoryList([]);
      });
  };
  const getProfessionList = () => {
    axiosPrivate
      .post("/LoadProfessionList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setProfessionList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setProfessionList([]);
        }
      })
      .catch((e) => {
        setProfessionList([]);
      });
  };

  const AddInsuranceCo = async () => {
    if (!insuranceco) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        insuranceco,
      };
      const response = await axiosPrivate.post("/AddInsuranceCo.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Insurance Co added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setInsuranceco("");
        getInsuranceCo();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddPolicyType = async () => {
    //const { policytype } = addCustomerData;
    if (policytype === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        policytype,
      };
      const response = await axiosPrivate.post("/AddPolicyType.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Policy Type added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPolicytype("");
        getPolicyType();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddVehicleType = async () => {
    //const { vehicleType } = addCustomerData;
    if (vehicleType === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        vehicleType,
      };
      const response = await axiosPrivate.post("/AddVehicleType.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Vehicle Type added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setVehicleType("");
        getVehicleType();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const AddPaidVia = async () => {
    //const { paidvia } = addCustomerData;
    if (paidvia === "") {
      toaster.push(<Message type="error">{`This Fields is required.`}</Message>);
      return;
    }
    try {
      const datatosend = {
        paidvia,
      };
      const response = await axiosPrivate.post("/AddPaidVia.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPaidvia("");
        getPaidVia();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewCaste = async () => {
    if (!caste) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        caste,
      };
      const response = await axiosPrivate.post("/AddCaste.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setCaste("");

        getCasteList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewVillage = async () => {
    if (!village) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        village,
      };
      const response = await axiosPrivate.post("/AddVillage.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setVillage("");
        getVillageList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewTehsil = async () => {
    if (!tehsil) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        tehsil,
      };
      const response = await axiosPrivate.post("/AddTehsil.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setTehsil("");
        getTehsilList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewDistrict = async () => {
    if (!district) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        district,
      };
      const response = await axiosPrivate.post("/AddDistrict.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setDistrict("");
        getDistrictList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewState = async () => {
    if (!state) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        state,
      };
      const response = await axiosPrivate.post("/AddState.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setState("");
        getStateList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewPolicyCategory = async () => {
    if (!policycategory) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        policycategory,
      };
      const response = await axiosPrivate.post("/AddPolicyCategory.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setPolicyCategory("");
        getPolicyCategoryList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewProfession = async () => {
    if (!profession) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        profession,
      };
      const response = await axiosPrivate.post("/AddProfession.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setProfession("");
        refershallDropdown();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewBranch = async () => {
    if (!branch) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        Branch: branch,
      };
      const response = await axiosPrivate.post("/AddBranchOffice.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setBranch("");
        getBranchList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const refershallDropdown = () => {
    getCasteList();
    getVillageList();
    getInsuranceCo();
    getPolicyType();
    getVehicleType();
    getPaidVia();
    getTehsilList();
    getDistrictList();
    getStateList();
    getPolicyCategoryList();
    getBranchList();
    getProfessionList();
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refershallDropdown();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const deleteitem = (id, value, source) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                    value,
                    source,
                  };
                  const response = await axiosPrivate.post("/RemoveItem.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    refershallDropdown();
                    toaster.push(<Message type="success">{result.Success}</Message>);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                } finally {
                  onClose();
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const deleteBranch = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post("/RemoveBranch.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    refershallDropdown();
                    toaster.push(<Message type="success">{result.Success}</Message>);
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      Manage Inputs
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                  pt={3}
                  pl={3}
                >
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={insuranceco} onChange={setInsuranceco} />
                        <InputGroup.Button
                          onClick={AddInsuranceCo}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Insurance Company" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {insuranceCoList.map((item, index) => (
                              <List.Item
                                onClick={() => setInsuranceco(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "InsuranceCo")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={policytype} onChange={setPolicytype} />
                        <InputGroup.Button
                          onClick={AddPolicyType}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Policy Type" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {policytypeList.map((item, index) => (
                              <List.Item
                                onClick={() => setPolicytype(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "PolicyType")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={vehicleType} onChange={setVehicleType} />
                        <InputGroup.Button
                          onClick={AddVehicleType}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Vehicle Make/Model" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {vehicletypeList.map((item, index) => (
                              <List.Item
                                onClick={() => setVehicleType(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "VehicleType")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={paidvia} onChange={setPaidvia} />
                        <InputGroup.Button
                          onClick={AddPaidVia}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Paid Via" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {paidviaList.map((item, index) => (
                              <List.Item
                                onClick={() => setPaidvia(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "PaidVia")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={caste} onChange={setCaste} />
                        <InputGroup.Button
                          onClick={addnewCaste}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Caste" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {casteList.map((item, index) => (
                              <List.Item
                                onClick={() => setCaste(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "Caste")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={village} onChange={setVillage} />
                        <InputGroup.Button
                          onClick={addnewVillage}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Village" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {villageList.map((item, index) => (
                              <List.Item
                                onClick={() => setVillage(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "Villages")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={tehsil} onChange={setTehsil} />
                        <InputGroup.Button
                          onClick={addnewTehsil}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Tehsil" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {tehsilList.map((item, index) => (
                              <List.Item
                                onClick={() => setTehsil(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "Tehsil")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={district} onChange={setDistrict} />
                        <InputGroup.Button
                          onClick={addnewDistrict}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="District" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {districtList.map((item, index) => (
                              <List.Item
                                onClick={() => setDistrict(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "District")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={state} onChange={setState} />
                        <InputGroup.Button
                          onClick={addnewState}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="State" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {stateList.map((item, index) => (
                              <List.Item
                                onClick={() => setState(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "State")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={branch} onChange={setBranch} />
                        <InputGroup.Button
                          onClick={addnewBranch}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Branch" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {branchList.map((item, index) => (
                              <List.Item
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteBranch(item.id)}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={policycategory} onChange={setPolicyCategory} />
                        <InputGroup.Button
                          onClick={addnewPolicyCategory}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="PolicyCategory" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {policyCategoryList.map((item, index) => (
                              <List.Item
                                onClick={() => setPolicyCategory(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "PolicyCategory")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} pt={2} pl={5} pr={5}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={profession} onChange={setProfession} />
                        <InputGroup.Button
                          onClick={addnewProfession}
                          disabled={!(auth.user.Type === "SuperAdmin")}
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Profession List" bordered>
                          <List size="sm" style={{ maxHeight: 180, overflow: "auto" }}>
                            {professionList.map((item, index) => (
                              <List.Item
                                onClick={() => setProfession(item.value)}
                                key={item.id}
                                index={index}
                                style={{ fontSize: "1rem", display: "flex", flexDirection: "row" }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {auth.user.Type === "SuperAdmin" && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteitem(item.id, item.value, "Profession")}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Inputs;
