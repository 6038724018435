import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";

/* import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "rsuite";
import { DatePicker } from "rsuite";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { SelectPicker } from "rsuite";
 */
import { useToaster, Notification } from "rsuite";
/* const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
} */
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";
/*imp ort { compare } from "functions"; */
/* import moment from "moment"; */
import { Outlet, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";

/* const data = ["Admin", "Staff", "DSO"].map((item) => ({ label: item, value: item })); */

function MainAgents() {
  const toaster = useToaster();
  /*  const [open, setOpen] = React.useState(false); */
  const [openfollow, setOpenFollow] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [type, setType] = React.useState("");
  const [customers, setCustomers] = React.useState([]);
  /* const [addCustomerData, setAddCustomerData] = React.useState({}); */
  const [editCustomerData, setEditCustomerData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();
  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;

  /* const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [assignedToID, setAssignedToID] = useState({});
  const [assignedTo, setAssignedTo] = useState({});
  */ const navigate = useNavigate();
  //const usersList = useUsersList([]);
  //console.log(usersList);
  /* 
  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  function handleEditCustomerChange(evt) {
    const value = evt.target.value;
    setEditCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  } */
  const [usersList, setUsersList] = React.useState([]);

  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const [rowData, setRowData] = React.useState(null);

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const RemoveCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this customer's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveCustomer.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  /*   const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handlefollowClose = () => {
    setOpenFollow(false);
  }; */

  const handlefollowOpen = (data) => {
    setOpenFollow(true);
    setRowData(data.row);
    console.log(data.row);
  };

  /*   const handleEditClose = () => {
    setOpenEdit(false);
  }; */

  const handleEditOpen = (data) => {
    setOpenEdit(true);
    setRowData(data.row);
    console.log(data.row);

    setEditCustomerData({
      address: data.row.Address,
      email: data.row.Email,
      mobile: data.row.Mobile1,
      mobile2: data.row.Mobile2,
      fullname: data.row.FullName,
    });
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );
  const columns = React.useMemo(
    () => [
      { field: "FullName", type: "string", width: 200 },
      { field: "Mobile1", type: "string", width: 200 },
      { field: "Mobile2", type: "string", width: 180 },
      { field: "Address", type: "string", width: 180 },
      { field: "Email", type: "string", width: 180 },
      { field: "RefBy", type: "String", width: 200 },
      { field: "Party", type: "string", width: 100 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            icon={<SecurityIcon />}
            label="Create Followup"
            onClick={() => handlefollowOpen(params)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            disabled
            icon={<LockResetIcon />}
            label="View Followups"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditOpen(params)}
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<DeleteIcon />}
            label="Remove"
            onClick={() => RemoveCustomer(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [handlefollowOpen, handleEditOpen, RemoveCustomer]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={1}>
                    <Grid item xs={3} style={{ alignItems: "center" }}>
                      Manage
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "end" }}>
                      <Button
                        disabled={
                          !(auth.permissions.includes(2000) || auth.user.Type === "SuperAdmin")
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("add")}
                        startIcon={<PersonAddIcon />}
                      >
                        Add
                      </Button>
                      &nbsp; &nbsp;
                      {/*  <Button
                        disabled={
                          !(auth.permissions.includes(2001) || auth.user.Type === "SuperAdmin")
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("list")}
                        startIcon={<PersonAddIcon />}
                      >
                        List Agents
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={handleRefresh}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                                            &nbsp; &nbsp;
                      <Button
                        disabled
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button> */}
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Outlet />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MainAgents;
