import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";

import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "rsuite";
import { DatePicker } from "rsuite";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { SelectPicker } from "rsuite";

import { useToaster, Notification } from "rsuite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { compare } from "functions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { DeleteForever } from "@mui/icons-material";
import { DELETE_CUSTOMER } from "Permissions";
import { CREATE_CUSTOMER } from "Permissions";

const data = ["Admin", "Staff", "DSO"].map((item) => ({ label: item, value: item }));

function Customer() {
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [openfollow, setOpenFollow] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [type, setType] = React.useState("");
  const [customers, setCustomers] = React.useState([]);
  const [addCustomerData, setAddCustomerData] = React.useState({});
  const [editCustomerData, setEditCustomerData] = React.useState({});
  const axiosPrivate = useAxiosPrivate();
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [assignedToID, setAssignedToID] = useState({});
  const [assignedTo, setAssignedTo] = useState({});
  const navigate = useNavigate();

  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;
  //const usersList = useUsersList([]);
  //console.log(usersList);

  function handleAddCustomerChange(evt) {
    const value = evt.target.value;
    setAddCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  function handleEditCustomerChange(evt) {
    const value = evt.target.value;
    setEditCustomerData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const [usersList, setUsersList] = React.useState([]);
  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.post("/ListCustomers.php");
        const result = response.data;
        if (result.status === 1) {
          console.log(result.Data);
          setCustomers(result.Data);
        }
        if (result.status === 0) {
          setCustomers([]);
        }
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    };
    if (isMounted) {
      getCustomers();
      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const [rowData, setRowData] = React.useState(null);

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/ListCustomers.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setCustomers(result.Data);
      }
      if (result.status === 0) {
        setCustomers([]);
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const AddCustomer = async () => {
    const { fullname, email, address, mobile, mobile2, refby } = addCustomerData;
    if (
      fullname === "" ||
      email === "" ||
      address === "" ||
      mobile === "" ||
      mobile2 === "" ||
      refby === "" ||
      type === ""
    ) {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        fullname,
        email,
        address,
        mobile,
        mobile2,
        refby,
        type,
      };
      const response = await axiosPrivate.post("/AddCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleClose();
        setAddCustomerData({});
        setType("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const EditCustomer = async () => {
    const { fullname, email, address, mobile, mobile2, refby } = editCustomerData;
    if (fullname === "" || email === "" || address === "" || mobile === "" || mobile2 === "") {
      toaster.push(
        <Notification type="error" header="Error">
          {`All Fields are required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }

    try {
      const datatosend = {
        fullname,
        email,
        address,
        mobile,
        mobile2,
        ID: rowData.id,
      };
      const response = await axiosPrivate.post("/EditCustomer.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Customer update successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        handleRefresh();
        handleClose();
        handleEditClose();
        setEditCustomerData({});
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const RemoveCustomer = async (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this customer's data.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                  };
                  const response = await axiosPrivate.post("/RemoveCustomer.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handlefollowClose = () => {
    setOpenFollow(false);
  };

  const handlefollowOpen = (data) => {
    setOpenFollow(true);
    setRowData(data.row);
    console.log(data.row);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (data) => {
    setOpenEdit(true);
    setRowData(data.row);
    console.log(data.row);

    setEditCustomerData({
      address: data.row.Address,
      email: data.row.Email,
      mobile: data.row.Mobile1,
      mobile2: data.row.Mobile2,
      fullname: data.row.FullName,
    });
  };

  const AddFollowup = () => {
    const datatosend = {
      CustomerID: rowData.id,
      UserID: assignedToID,
      FollowupOn: moment(dateTime),
      Notes: description,
    };
    axiosPrivate
      .post("/AddFollowup.php", datatosend)
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success">
                {`Followup updated successfully`}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
            setOpenFollow(false);
            setRowData({});
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error">
                {result.Error}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Error occured, please try again later.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      })
      .catch((e) => {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured, please try again later.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      });
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const duplicateUser = React.useCallback(
    (id) => () => {
      setRows((prevRows) => {
        const rowToDuplicate = prevRows.find((row) => row.id === id);
        return [...prevRows, { ...rowToDuplicate, id: Date.now() }];
      });
    },
    []
  );
  const columns = React.useMemo(
    () => [
      { field: "FullName", type: "string", width: 200 },
      { field: "Mobile1", type: "string", width: 200 },
      { field: "Mobile2", type: "string", width: 180 },
      { field: "Address", type: "string", width: 180 },
      { field: "Email", type: "string", width: 180 },
      { field: "RefBy", type: "String", width: 200 },
      { field: "Party", type: "string", width: 100 },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2100) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<SecurityIcon />}
            label="Create Followup"
            onClick={() => handlefollowOpen(params)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(2102) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<LockResetIcon />}
            label="View Followups"
            onClick={duplicateUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(1504) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditOpen(params)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={!(auth.permissions.includes(1505) || auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<DeleteIcon />}
            label="Remove"
            onClick={() => RemoveCustomer(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [handlefollowOpen, handleEditOpen, RemoveCustomer]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users list
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      Manage Customers
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        disabled={
                          !(
                            auth.permissions.includes(DELETE_CUSTOMER) ||
                            auth.user.Type === "SuperAdmin"
                          )
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("DeletedCustomer")}
                        startIcon={<DeleteForever />}
                      >
                        Deleted Customer
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        disabled={
                          !(
                            auth.permissions.includes(CREATE_CUSTOMER) ||
                            auth.user.Type === "SuperAdmin"
                          )
                        }
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        onClick={() => navigate("add")}
                        startIcon={<PersonAddIcon />}
                      >
                        Add
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={handleRefresh}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={() => {
                          alert("clicked");
                        }}
                        startIcon={<FileDownloadIcon />}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                /> */}
                <DataGrid columns={columns} rows={customers} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* ========================Add Customer=========================== */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Add Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No 2 :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type other mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.email || ""}
                    name="email"
                    placeholder="Type email"
                    id="email"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.address || ""}
                    name="address"
                    placeholder="Type address"
                    id="address"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Party :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Select
                    id="demo-simple-select-helper"
                    value={type}
                    onChange={handleChange}
                    style={{ flex: 1, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select type</em>
                    </MenuItem>
                    <MenuItem value="1st Party">1st Party</MenuItem>
                    <MenuItem value="3rd Party">3rd Party</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={AddCustomer}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* =============================================================== */}

      {/* ========================Add Followup=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openfollow}
        onClose={handlefollowClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          New followups
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Followup by :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    data={usersList}
                    onSelect={(e, Item) => {
                      /* console.log(e);
                      console.log(Item); */
                      setAssignedTo(Item.label);
                      setAssignedToID(e);
                    }}
                    groupBy="role"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          return compare(a.groupTitle, b.groupTitle);
                        };
                      }

                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    style={{ flex: 1, width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.FullName || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    disabled
                    onChange={handleAddCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.Mobile1 || ""}
                    disabled
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleAddCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date / Time :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="yyyy-MM-dd HH:mm"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlefollowClose}>
            Cancel
          </Button>
          <Button onClick={AddFollowup}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      {/* ========================Edit Customer=========================== */}

      <Dialog
        open={openEdit}
        onClose={handleEditClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Edit Customer
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.fullname || ""}
                    name="fullname"
                    placeholder="Type full name"
                    id="fullname"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.mobile || ""}
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No 2 :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.mobile2 || ""}
                    name="mobile2"
                    placeholder="Type other mobile number"
                    id="mobile2"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Email ID :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.email || ""}
                    name="email"
                    placeholder="Type email"
                    id="email"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Address :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={editCustomerData?.address || ""}
                    name="address"
                    placeholder="Type address"
                    id="address"
                    autoComplete="off"
                    onChange={handleEditCustomerChange}
                    fullWidth
                    multiline={true}
                  />
                </Grid>
              </Grid>
              {/* <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Ref By :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={addCustomerData?.refby || ""}
                    name="refby"
                    placeholder="Type refby"
                    id="refby"
                    autoComplete="off"
                    onChange={handleEditCustomerChange }
                    fullWidth
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleEditClose}>
            Cancel
          </Button>
          <Button onClick={EditCustomer}>Update</Button>
        </DialogActions>
      </Dialog>
      {/* =============================================================== */}
    </DashboardLayout>
  );
}

export default Customer;
