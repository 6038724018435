import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button } from "@mui/material";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";

import { useToaster, Notification } from "rsuite";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { Delete, RemoveRedEyeRounded, RestoreFromTrash } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { READ_POLICY } from "Permissions";
import { UPDATE_POLICY } from "Permissions";
import { DELETE_POLICY } from "Permissions";

function DeletedInsurance() {
  const toaster = useToaster();
  const navigate = useNavigate();
  const [policyData, setPolicyData] = React.useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [controller, dispatch] = useMaterialUIController();

  const { auth } = controller;

  const handleRefresh = async () => {
    try {
      const response = await axiosPrivate.post("/LoadDeletedPolicyData.php");
      const result = response.data;
      if (result.status === 1) {
        console.log(result.Data);
        setPolicyData(result.Data);
      }
      if (result.status === 0) {
        setPolicyData([]);
      }
    } catch (err) {
      console.error(err);

      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleRefresh();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const RestorePolicyData = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this policy details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post("/RestorePolicy.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();
                  handleRefresh();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };
  const DeletePolicyData = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this policy details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post("/DeletePolicy.php", datatosend);
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification type="success" header="Success">
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();
                  handleRefresh();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  const columns = React.useMemo(
    () => [
      { field: "policyno", type: "string", width: 200, headerName: "PolicyNo" },
      {
        field: "policydate",
        type: "Date",
        width: 120,

        headerName: "PolicyDate",
      },
      { field: "Custname", type: "string", width: 120, headerName: "Customer" },
      { field: "insuredname", type: "string", width: 120, headerName: "Insured" },
      { field: "policytype", type: "string", width: 180, headerName: "PolicyType" },
      { field: "vehiclemodel", type: "string", width: 200, headerName: "VehicleModel" },
      { field: "vehicleregno", type: "string", width: 200, headerName: "VehicleRegNo" },

      { field: "DahiyaMitra", type: "string", width: 200, headerName: "DahiyaMitra/Self" },
      { field: "Broker", type: "string", width: 200 },
      {
        field: "insurancecompany",
        type: "string",
        width: 120,
        headerName: "InsuranceCompany",
      },
      { field: "startDate", type: "Date", width: 120, headerName: "StartDate" },
      { field: "modelyear", type: "string", width: 200, headerName: "ModelYear" },
      { field: "policyenddate", type: "Date", width: 120, headerName: "PolicyEndDate" },
      { field: "paidvia", type: "string", width: 200, headerName: "PaidVia" },
      {
        field: "policycategory",
        type: "string",
        width: 200,

        headerName: "PolicyCategory",
      },
      { field: "AddedBy", type: "string", width: 200 },
      { field: "AddedOn", type: "Date", width: 200 },
      { field: "tppremium", type: "string", width: 200 },
      { field: "odpremium", type: "string", width: 200 },
      { field: "netpremium", type: "string", width: 200 },
      { field: "commpertp", type: "string", width: 200 },
      { field: "commperod", type: "string", width: 200 },
      { field: "commpernet", type: "string", width: 200 },
      { field: "commtp", type: "string", width: 200 },
      { field: "commod", type: "string", width: 200 },
      { field: "commnetamount", type: "string", width: 200 },
      { field: "totalcomm", type: "string", width: 200 },
      { field: "grosspremium", type: "string", width: 200 },
      { field: "CR1", type: "string", width: 200 },
      { field: "CR2", type: "string", width: 200 },
      { field: "Balance", type: "string", width: 200 },
      { field: "Deleted", type: "string", width: 200, flex: 1 },
      {
        field: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={
              !(auth.permissions.includes(DELETE_POLICY) || auth.user.Type === "SuperAdmin")
            }
            key={params.id}
            icon={<RestoreFromTrash />}
            label="Restore"
            onClick={() => RestorePolicyData(params.id)}
          />,
          <GridActionsCellItem
            disabled={!(auth.user.Type === "SuperAdmin")}
            key={params.id}
            icon={<Delete />}
            label="Restore"
            onClick={() => DeletePolicyData(params.id)}
          />,
        ],
      },
    ],
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      All pending Policies
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Button
                        style={{ color: "white", backgroundColor: "#2979ff", margin: 10 }}
                        disableElevation
                        onClick={handleRefresh}
                        startIcon={<RestartAltIcon />}
                      >
                        Refresh
                      </Button>
                    </Grid>
                  </Grid>
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    columns={columns}
                    rows={policyData}
                    slots={{ toolbar: GridToolbar }}
                    autosizeOnMount={true}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns status and traderName, the other columns will remain visible
                          insurancecompany: false,
                          policytype: false,
                          startDate: false,
                          modelyear: false,
                          policyenddate: false,
                          paidvia: false,
                          policycategory: false,
                          AddedBy: false,
                          AddedOn: false,
                          Deleted: false,
                        },
                      },
                      autosizeOptions: {
                        columns: ["Custname", "insurancecompany"],
                        includeOutliers: true,
                        includeHeaders: true,
                      },

                      sx: {
                        fontStyle: "arial",
                        boxShadow: 2,
                        border: 2,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      },
                    }}
                  />
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DeletedInsurance;
