import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as XLSX from "xlsx";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { v4 as uuidv4 } from "uuid";

import { useToaster, Notification, TagPicker, SelectPicker, Input, DatePicker } from "rsuite";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import { Uploader, Message, Loader } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Radio, RadioGroup, Form, Button } from "rsuite";

import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import Slide from "@mui/material/Slide";
import { compare } from "functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const predefinedBottomRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Last month",
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
  },
  {
    label: "Tomorrow",
    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
  },
  {
    label: "Next week",
    value: [startOfWeek(addDays(new Date(), 6)), endOfWeek(addDays(new Date(), 6))],
  },
  {
    label: "Next 7 days",
    value: [new Date(), addDays(new Date(), 6)],
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 29)],
  },
  {
    label: "Next month",
    value: [startOfMonth(addMonths(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))],
  },
];
import { Drawer, Placeholder } from "rsuite";
import { useMaterialUIController } from "context";

function Transactions() {
  const [fileList, setFileList] = React.useState([]);
  const { ID, Name } = useParams();

  const uploader = React.useRef();
  const [backdrop, setBackdrop] = React.useState("static");
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState([new Date(), new Date()]);
  const toaster = useToaster();
  const [payeeID, setPayeeID] = React.useState();
  const [transID, setTransID] = React.useState();
  const [payeeNameID, setPayeeNameID] = React.useState();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = React.useState(false);
  const [eventList, setEventList] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [total, setTotal] = useState({});
  const [description, setDescription] = React.useState("");
  const [dateTime, setDateTime] = React.useState(new Date());
  const [assignedToID, setAssignedToID] = useState("");
  const [openfollow, setOpenFollow] = React.useState(false);
  const [autoHeight, setAutoHeight] = React.useState(true);
  const [usersList, setUsersList] = React.useState([]);
  const [followupData, setFollowupData] = useState({});
  const [rowData, setRowData] = React.useState(null);
  const [assignedTo, setAssignedTo] = useState("");
  const [data, setData] = useState({});
  function handleFollowupChange(evt) {
    const value = evt.target.value;
    setFollowupData((prev) => ({ ...prev, [evt.target.name]: value }));
  }
  const [controller] = useMaterialUIController();
  const { auth } = controller;

  const getUsers = () => {
    axiosPrivate
      .post("/LoadUsersList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              label: item.Name,
              value: item.UniqueID,
              role: item.UserType,
            }));
            // console.log(data);
            setUsersList(data);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          //console.log(result.Data);
          setUsersList([]);
        }
      })
      .catch((e) => {
        setUsersList([]);
      });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setData((prev) => ({ ...prev, [evt.target.name]: value }));
  };

  let balance = 0;
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(
    "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png"
  );
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      //getUsers();
      setPayeeID(ID);
    setPayeeNameID(Name);
    return () => {
      isSubscribed = false;
    };
  }, []);
  const handlefollowClose = () => {
    setOpenFollow(false);
  };

  const handlefollowOpen = (data, type = "Reminder") => {
    setOpenFollow(true);
    setRowData(data);
    //console.log(data);
    /*     let dt = type === "Birthday" ? data.DOB : data.Anniversary;
    let newdt = new Date(dt);
    let newdt1 = new Date(new Date().getFullYear, newdt.getMonth, newdt.getDay);
    console.log(newdt1);
    setDateTime(newdt1); */
    setDescription(`${type} // `);
  };
  const getData = () => {
    setLoading(true);
    axiosPrivate
      .post("/LoadEventsList.php", {
        start: moment(value[0]).format("MM-DD"),
        end: moment(value[1]).format("MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        setLoading(false);
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            setEventList(result.Data);
          }
          if (result.status === 0) {
            setEventList([]);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          setEventList([]);
        }
      })
      .catch((e) => {
        setEventList([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      // GetAllData();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  const GetAllData = () => {
    setLoading(true);
    axiosPrivate
      .post("/GetPayeeAccount.php", {
        Payee: payeeID,
        Start: moment(value[0]).format("YYYY-MM-DD"),
        End: moment(value[1]).format("YYYY-MM-DD"),
      })
      .then((response) => {
        const result = response.data;
        setLoading(false);
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const newarr = result.AllData.forEach((element) => {
              return { ...element, Balance: 0 };
            });
            setTransactions(result.AllData);
            setTotal(result.Total);
          } else {
            setTransactions([]);
          }
        }
        if (response && (response["status"] === 401 || response["status"] === 403)) {
          setTransactions([]);
        }
      })
      .catch((e) => {
        setEventList([]);
        setLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      console.log(uuidv4());
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  function handleExport() {
    setLoading(true);

    axiosPrivate
      .post("/ExportEventsList.php", {
        start: moment(value[0]).format("MM-DD"),
        end: moment(value[1]).format("MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Events");
          XLSX.writeFile(wb, "AllEventList.xlsx");
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error">
              No Records
            </Notification>,
            { placement: "topEnd", duration: 1000 }
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const AddFollowup = () => {
    console.log(rowData);

    const datatosend = {
      CustomerID: rowData.UniqueID,
      UserID: assignedToID,
      FollowupOn: moment(dateTime).format(),
      Notes: rowData.Type + " // " + description,
    };
    console.log(datatosend);

    axiosPrivate
      .post("/AddFollowup.php", datatosend)
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success">
                {`Followup updated successfully`}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
            setOpenFollow(false);
            setRowData({});
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error">
                {result.Error}
              </Notification>,
              { placement: "topEnd" },
              { duration: 1000 }
            );
          }
        }
        if (response && (response?.status === 401 || response?.status === 403)) {
          toaster.push(
            <Notification type="error" header="Error">
              {`Error occured, please try again later.`}
            </Notification>,
            { placement: "topEnd" },
            { duration: 1000 }
          );
        }
      })
      .catch((e) => {
        toaster.push(
          <Notification type="error" header="Error">
            {`Error occured, please try again later.`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      });
  };

  const AddExpense = () => {
    // console.log(rowData);

    const datatosend = {
      ID: payeeID,
      NameID: payeeNameID,
      Amount: data.Amount,
      Type: data.Type,
      Date: moment(data.Date).format("YYYY-MM-DD"),
      Method: data.Method,
      Note: data.note,
    };
    console.log(datatosend);

    axiosPrivate
      .post("/AddTransaction.php", datatosend)
      .then((response) => {
        const result = response.data;
        if (response && response?.status === 200) {
          console.log(result);
          if (result.status === 1) {
            toaster.push(<Message type="success">{`Added successfully`}</Message>);
            setTransID(() => {
              return result.ID;
            });
            console.log(uploader.current);
            uploader.current.data = { ID: result.ID };
            uploader.current.start();

            // setRowData({});
            GetAllData();
          }
          if (result.status === 0) {
            toaster.push(
              <Message type="error" header="Error">
                {result.Error}
              </Message>
            );
          }
        }
        if (response && (response?.status === 401 || response?.status === 403)) {
          toaster.push(<Message type="error">{`Error occured, please try again later.`}</Message>);
        }
      })
      .catch((e) => {
        toaster.push(<Message type="error">{`Error occured, please try again later.`}</Message>);
        console.log(e);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={1} mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={1} mt={1} py={1} px={1} borderRadius="lg" coloredShadow="secondary">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={8}>
                      <div style={{ display: "flex", flexDirection: "row", paddingLeft: 10 }}>
                        <DateRangePicker
                          cleanable={false}
                          ranges={predefinedBottomRanges}
                          placeholder="Select date range"
                          style={{ width: 300 }}
                          value={value}
                          onChange={setValue}
                          showMeridian
                          format="dd-MM-yyyy"
                          defaultCalendarValue={[new Date(), new Date()]}
                        />
                        &nbsp;&nbsp;{" "}
                        <Button onClick={GetAllData} appearance="primary">
                          Load
                        </Button>{" "}
                        &nbsp;&nbsp;{" "}
                        <Button onClick={() => setOpen(true)} appearance="primary">
                          Add New
                        </Button>
                        &nbsp;&nbsp;{" "}
                        <Button onClick={handleExport} appearance="primary">
                          Export
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={1} px={1}>
                <div style={{ height: autoHeight ? "auto" : 500, marginTop: 10 }}>
                  <div
                    style={{
                      height: 500,
                      flex: 1,
                      width: "auto",
                      overflowX: "scroll",
                      overflowY: "scroll",
                    }}
                  >
                    <table style={{ flex: 1 }} id="tabledata">
                      <thead
                        style={{
                          borderBottomStyle: "dotted",
                          borderBottomWidth: 1,
                          borderBottomColor: "grey",
                          padding: 5,
                        }}
                      >
                        <th width={200} style={{ fontSize: 17 }}>
                          Date
                        </th>
                        <th width={200} style={{ fontSize: 17 }}>
                          PaidIn
                        </th>

                        <th width={200} style={{ fontSize: 17 }}>
                          Credit
                        </th>
                        <th width={200} style={{ fontSize: 17 }}>
                          Debit
                        </th>
                        <th width={200} style={{ fontSize: 17 }}>
                          Balance
                        </th>
                        <th width={200} style={{ fontSize: 17 }}>
                          Paid/Received By
                        </th>
                        <th width={200} style={{ fontSize: 17 }}>
                          Note
                        </th>
                      </thead>

                      <tbody>
                        {transactions.map((data) => (
                          <tr
                            key={data.SrNo}
                            style={{
                              marginTop: 10,
                              borderBottom: "dotted",
                              borderWidth: 1,
                              fontSize: 13,
                            }}
                          >
                            <td width={200} style={{ fontSize: 16 }}>
                              {data.Date}
                            </td>
                            <td width={200} style={{ fontSize: 16 }}>
                              {data.PaidBy}
                            </td>
                            <td width={200} style={{ fontSize: 16 }}>
                              {data.Credit}
                            </td>
                            <td width={200} style={{ fontSize: 16 }}>
                              {data.Debit}
                            </td>
                            <td width={200} style={{ fontSize: 16 }}>
                              {balance + data.Credit - data.Debit}
                            </td>
                            <td width={200} style={{ fontSize: 16 }}>
                              {data.AddedBy}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* ========================Add Followup=========================== */}

      <Dialog
        style={{ zIndex: 100 }}
        open={openfollow}
        onClose={handlefollowClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          New Task / Reminder
        </DialogTitle>
        <DialogContent>
          <div style={{ width: 400 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
              pt={3}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <Grid xs={5} style={{ justifyContent: "center", display: "flex" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Followup by :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <SelectPicker
                    cleanable={false}
                    data={usersList}
                    value={assignedToID}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      /* console.log(e);
                      console.log(Item); */
                      setAssignedTo(Item.label);
                      setAssignedToID(e);
                    }}
                    groupBy="role"
                    sort={(isGroup) => {
                      if (isGroup) {
                        return (a, b) => {
                          return compare(a.groupTitle, b.groupTitle);
                        };
                      }

                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    style={{ flex: 1, width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Full name :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.Name || ""}
                    name="Name"
                    placeholder="Type full name"
                    id="Name"
                    autoComplete="off"
                    disabled
                    onChange={handleFollowupChange}
                    style={{ fontWeight: "bold" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Mobile No :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <TextField
                    value={rowData?.Mobile || ""}
                    disabled
                    name="mobile"
                    placeholder="Type mobile"
                    id="mobile"
                    autoComplete="off"
                    onChange={handleFollowupChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Date / Time :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <DatePicker
                    cleanable={false}
                    style={{ zIndex: 1000000 }}
                    format="yyyy-MM-dd HH:mm"
                    value={dateTime}
                    onChange={setDateTime}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <Grid xs={5} style={{ display: "flex", justifyContent: "center" }}>
                  <InputLabel style={{ fontWeight: "bold" }}>Additional :</InputLabel>
                </Grid>
                <Grid xs={7}>
                  <Input
                    value={description}
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    as="textarea"
                    rows={5}
                    style={{ resize: "none" }}
                    placeholder="Enter description"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlefollowClose}>
            Cancel
          </Button>
          <Button onClick={AddFollowup}>Create</Button>
        </DialogActions>
      </Dialog>
      {/* ====================================================================== */}

      <Drawer backdrop={backdrop} open={open} onClose={() => setOpen(false)} size="xs">
        <Drawer.Header>
          <Drawer.Title>New Transaction</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                /* setOpen(false) */
                AddExpense();
              }}
              appearance="primary"
            >
              Add
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Amount:</InputLabel>
                </Grid>
                <Grid item xs={8} style={{ justifyContent: "center" }}>
                  <TextField
                    value={data?.Amount || ""}
                    name="Amount"
                    placeholder="Type Amount"
                    id="Amount"
                    autoComplete="off"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Date:</InputLabel>
                </Grid>
                <Grid item xs={8} style={{ justifyContent: "center" }}>
                  <DatePicker
                    oneTap
                    cleanable={false}
                    format="dd-MM-yyyy"
                    value={data.Date}
                    onChange={(e) => {
                      setData((a) => {
                        return {
                          ...a,
                          Date: e,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Type:</InputLabel>
                </Grid>
                <Grid item xs={8} style={{ justifyContent: "center" }}>
                  <SelectPicker
                    searchable={false}
                    block={true}
                    appearance="default"
                    value={data.Type}
                    onSelect={(e, item) =>
                      setData((a) => {
                        return {
                          ...a,
                          Type: e,
                        };
                      })
                    }
                    data={[
                      { label: "Credit", value: "Credit" },
                      { label: "Debit", value: "Debit" },
                    ]}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Method:</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <SelectPicker
                    searchable={false}
                    appearance="default"
                    value={data.Method}
                    block={true}
                    onSelect={(e, item) =>
                      setData((a) => {
                        return {
                          ...a,
                          Method: e,
                        };
                      })
                    }
                    data={[
                      { label: "Cash", value: "Cash" },
                      { label: "Bank", value: "Bank" },
                    ]}
                    cleanable={false}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Note:</InputLabel>
                </Grid>
                <Grid item xs={8} style={{ justifyContent: "center" }}>
                  <TextField
                    value={data?.note || ""}
                    name="note"
                    placeholder="Type note"
                    id="note"
                    autoComplete="off"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "center", paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
              >
                <Grid item xs={4} style={{ justifyContent: "center", alignItems: "center" }}>
                  <InputLabel>Document:</InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <Uploader
                    disabled={fileList.length >= 1}
                    fileList={fileList}
                    autoUpload={false}
                    onChange={setFileList}
                    ref={uploader}
                    listType="picture"
                    action="https://Downloads.dahiyainsurance.com/AddTransactionPic.php"
                    withCredentials={true}
                    headers={{ Authorization: `Bearer ${auth?.accessToken}` }}
                    accept=".jpg,.jpeg,.png"
                    data={{ ID: transID }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer.Body>
      </Drawer>
    </DashboardLayout>
  );
}

export default Transactions;
