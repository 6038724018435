import axios from "../api/axios";
import { setAuth, useMaterialUIController, setLogout } from "context";
const useRefreshToken = () => {
  const [dispatch] = useMaterialUIController();
  const refresh = async () => {
    const response = await axios.post(
      "/Refresh.php",
      {},
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log(response["status"]);
    const result = response.data;
    if (response && response["status"] === 201) {
      if (result.status === 1) {
        setAuth(dispatch, {
          user: result.Data,
          roles: [5150, 1984, 2001],
          permissions: result?.Permissions || [201],
          accessToken: result.Data.SessionID,
        });
        //sessionStorage.setItem("access", result.Data.SessionID);
      }
    }

    if (response && (response["status"] === 401 || response["status"] === 403)) {
      if (result.status === 1) {
        setLogout(dispatch, {});
      }
    }

    return response.data.SessionID;
  };
  return refresh;
};

export default useRefreshToken;
